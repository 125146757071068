import { WorkRequestAvgRating } from "./WorkRequestAvgRating";
import WorkRequestDayTrendTurnaroundTime from "./WorkRequestDayTrendTurnaroundTime";
import { WorkRequestHistogram } from "./WorkRequestHistogram";
import WorkRequestKpiAvgTurnAroundTime from "./WorkRequestKpiAvgTurnAroundTime";
import { WorkRequestStatusBreakdownPieChart } from "./WorkRequestStatusBreakdownPieChart";
import WorkRequestStatusMonthlyBreakdown from "./WorkRequestStatusMonthlyBreakdown";
import WorkRequestTrend from "./WorkRequestTrend";
import { DateFilter } from "./DateFilter";
import SelectableChart from "../../../../components/SelectableChart";
import { useIsSelectionMode } from "../../../../stores/dashboardStore/dashboardStore";

export function WorkRequestAnalytics() {

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between my-4">
        <DateFilter />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <SelectableChart chartId="work-request-trend">
          <WorkRequestTrend />
        </SelectableChart>
        <SelectableChart chartId="work-request-status-breakdown">
          <WorkRequestStatusBreakdownPieChart />
        </SelectableChart>
        <div className="col-span-2">
          <SelectableChart chartId="work-request-status-monthly">
            <WorkRequestStatusMonthlyBreakdown />
          </SelectableChart>
        </div>
        <SelectableChart chartId="work-request-kpi-turnaround">
          <WorkRequestKpiAvgTurnAroundTime />
        </SelectableChart>
        <SelectableChart chartId="work-request-day-trend">
          <WorkRequestDayTrendTurnaroundTime />
        </SelectableChart>
        <SelectableChart chartId="work-request-avg-rating">
          <WorkRequestAvgRating />
        </SelectableChart>
        <SelectableChart chartId="work-request-histogram">
          <WorkRequestHistogram />
        </SelectableChart>
      </div>
    </div>
  );
}
