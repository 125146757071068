// Feature toggle hook to determine if a feature is enabled or not

import { trpc } from "../lib/trpc";
import { useAppSelector } from "../redux/store";
import { FeatureType, FeatureTypeEnum } from "@cerev-cmms/zod-types";

interface useFeatureProps {
  projectId?: string;
}

// Core feature toggle hook
export function useFeatures({ projectId }: useFeatureProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const queryProjectId =
    !projectId && activeProj && activeProj.id
      ? activeProj.id.toString()
      : projectId;

  const { data: featureList, isLoading } = trpc.featureToggle.getFeatures.useQuery(
    { projectId: queryProjectId ?? "" },
    {
      enabled: !!queryProjectId,
    }
  );

  const features = FeatureTypeEnum.options.reduce((acc, featureType) => {
    acc[featureType] = featureList?.data?.find(f => f.featureType === featureType)?.isEnabled ?? false;
    return acc;
  }, {} as Record<FeatureType, boolean>);

  return {
    ...features,
    isLoading,
  };
}

// Break out to individual hooks for each feature
export function useSchedulerEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.SCHEDULER];
}

export function useBimEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.BIM];
}

export function useSerialiedInventoryEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.SERIALIZED_INVENTORY];
}

export function useCustomWorkPermitEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.CUSTOM_PTW];
}

export function usePdfReportEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.PDF_REPORT];
}

export function useWrSaveUserInfoEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.WR_SAVE_USER_INFO];
}

export function useWrRatingIsEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.WR_RATING];
}

export function useManHoursEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.MAN_HOURS];
}

export function useSignatoryEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.SIGNATORY];
}

export function useWoProfitLossEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.WO_PROFIT_LOSS];
}

export function useInventoryLocationEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.INVENTORY_LOCATION];
}

export function useModuleAccessEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.MODULE_ACCESS];
}

export function usePrivateAttachmentEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.PRIVATE_ATTACHMENT];
}

export function useEscalationEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.ESCALATION];
}

export function useExtraFieldPricingEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.EXTRA_FIELD_PRICING];
}

export function useSubEquipmentEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.SUB_EQUIPMENT];
}

// New hook for EQUIPMENT_LCCA
export function useEquipmentLccaEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.EQUIPMENT_LCCA];
}

export function useWoPriorityEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.WO_PRIORITY];
}

export function useWoTradeEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.WO_TRADE];
}

// New hook for SELF_ASSIGN_WORK_ORDER
export function useSelfAssignEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.SELF_ASSIGN_WORK_ORDER];
}

export function useInvestigationEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.INVESTIGATION];
}

export function useIpmNewGraphsEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.IPM_NEW_GRAPHS];
}

export function useMultipleEquipmentPmEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.MULTIPLE_EQUIPMENT_PM];
}

export function useMultipleCurrencyInventoryEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.MULTIPLE_CURRENCY_INVENTORY];
}

export function useSlideShowAnalyticsEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.SLIDE_SHOW_ANALYTICS];
}

export function useWorkOrderPopUpEnabled(dto?: useFeatureProps) {
  const features = useFeatures({ projectId: dto?.projectId });
  return features[FeatureTypeEnum.enum.WO_CLOSE_POP_UP];
}
