import { useState } from "react";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import { TreeView } from "@mui/x-tree-view";
import { ChevronRight, Clear, ExpandMore, FilterAlt, FilterAltOutlined } from "@mui/icons-material";
import { cn } from "../../../../lib/utils";
import { useAppSelector } from "../../../../redux/store";
import { trpc } from "../../../../lib/trpc";
import { RouterOutputs } from "@cerev-cmms/trpc";
import AppButton from "../../../../components/AppButton";
import LocationFilterTreeItem from "./components/LocationFilterTreeItem";

type Location = RouterOutputs["location"]["getLocations"][number];

interface AppSelectLocationFilterDialogProps {
  currentFilter: { id: number; name: string } | undefined | null;
  onApplyFilter: (loc: Location | undefined) => void;
  showFirstLevelOnly?: boolean;
}

export default function AppSelectLocationFilterDialog({
  onApplyFilter,
  currentFilter,
  showFirstLevelOnly,
}: AppSelectLocationFilterDialogProps) {
  const [open, setOpen] = useState(false);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [selectedLocation, setSelectedLocation] = useState<Location | undefined>();

  const { data: locations } = trpc.location.getLocations.useQuery(
    {
      projectId: activeProj?.id?.toString() ?? "",
      locationId: "",
    },
    {
      enabled: !!activeProj?.id,
    }
  );

  const onOpenChange = (open: boolean) => {
    setOpen(open);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          size="lg"
          className={cn(
            "bg-slate-50 flex py-1 px-3 rounded-md border-solid border-slate-200 border-[1px] hover:cursor-pointer items-center hover:bg-slate-100 duration-300 transition-all justify-start w-full",
            {
              "bg-primary-900 hover:bg-primary-900": currentFilter,
            }
          )}
          onClick={() => {
            setOpen(true);
          }}
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              {currentFilter ? (
                <FilterAlt className="text-white mr-2" />
              ) : (
                <FilterAltOutlined className="text-slate-400 mr-2" />
              )}
              <p
                className={cn("font-sans text-slate-400", {
                  "text-white": currentFilter,
                })}
              >
                {currentFilter ? currentFilter.name : "By Location"}
              </p>
            </div>
            {currentFilter ? (
              <Clear
                className={cn("ml-2 text-slate-400", {
                  "text-white": currentFilter,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedLocation(undefined);
                  onApplyFilter(undefined);
                }}
              />
            ) : (
              <ChevronRight className="ml-2 text-slate-400" />
            )}
          </div>
        </Button>
      </DialogTrigger>
      <DialogContent className="w-full max-w-4xl overflow-y-scroll max-h-screen">
        <DialogHeader>
          <DialogTitle>Select a location</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <TreeView
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
        >
          {locations?.map((loc) => (
            <LocationFilterTreeItem
              loc={loc}
              key={loc.id}
              onSelect={(loc) => setSelectedLocation(loc)}
              showFirstLevelOnly={showFirstLevelOnly}
            />
          ))}
        </TreeView>
        <DialogFooter>
          <AppButton
            label="Apply Filter"
            onClick={() => {
              onApplyFilter(selectedLocation);
              setOpen(false);
            }}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}