import { useSearchParams } from "react-router-dom";
import { trpc } from "../../../../lib/trpc";
import { DataTable } from "../../../react-table/DataTable";
import { useReactTable, getCoreRowModel } from "@tanstack/react-table";
import { assetWorkOrderColumns } from "./columns/assetWorkOrderColumns";
import {
  DRAWER_VIEW_STATE,
  WORK_ORDER_DETAIL_DRAWER,
} from "../../AppDrawer";
import { RouterOutputs } from "@cerev-cmms/trpc";

interface AssetWorkOrderTabProps {
  asset?: RouterOutputs["assets"]["getOneAsset"];
}

export default function AssetWorkOrderTab({ asset }: AssetWorkOrderTabProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: workOrders, isLoading } = trpc.assets.getWorkOrders.useQuery(
    {
      assetId: asset?.id.toString() ?? "",
    },
    {
      enabled: !!asset,
    }
  );

  const table = useReactTable({
    data: workOrders ?? [],
    columns: assetWorkOrderColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleRowClick = (row: any) => {
    const workOrder = row.original;
    setSearchParams((p) => {
      p.set("drawerType", WORK_ORDER_DETAIL_DRAWER);
      p.set("drawerState", DRAWER_VIEW_STATE);
      p.set("workOrderId", workOrder.id?.toString() ?? "");
      p.set("workOrderTabIdx", "0");
      return p;
    });
  };

  return (
    <div className="p-4">
      <DataTable
        table={table}
        isLoading={isLoading}
        totalCount={workOrders?.length ?? 0}
        onRowClick={handleRowClick}
      />
    </div>
  );
}
