import React from "react";
import { trpc } from "../../../../lib/trpc";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import { useAppSelector } from "../../../../redux/store";
import { Progress } from "@/components/ui/progress";
import { useSearchParams } from "react-router-dom";
import { cn } from "../../../../lib/utils";

interface WorkOrderClosureRateProps {
  fullScreen?: boolean;
}

export function WorkOrderClosureRate({ fullScreen }: WorkOrderClosureRateProps) {
  const project = useAppSelector((state) => state.root.activeProject);
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month")!)
    : undefined;
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : undefined;

  const { data, isLoading } = trpc.analytics.getWorkOrderClosureRate.useQuery(
    {
      projectId: project?.id ?? 0,
      month,
      year,
    },
    {
      enabled: !!project,
    }
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No work order closure rate data available
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Work Order Closure Rate</CardTitle>
        <CardDescription>
          Percentage of closed work orders
        </CardDescription>
      </CardHeader>
      <CardContent
        className={cn("flex flex-col items-center space-y-4 justify-center mt-24", {
          "max-h-none aspect-video h-full relative mt-0": fullScreen,
        })}
      >
        <div className="text-4xl font-bold">
          {data.closureRate.toFixed(2)}%
        </div>
        <Progress value={data.closureRate} className="w-full h-2" />
        <div className="text-sm text-muted-foreground">
          {data.closedWorkOrders} out of {data.totalWorkOrders} work orders closed
        </div>
      </CardContent>
    </Card>
  );
}

