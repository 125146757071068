import { endOfMonth, startOfMonth } from "date-fns";
import { DateRange } from "react-day-picker";
import { create } from "zustand";

export enum PdfReportType {
  INITIAL = "INITIAL",
  WORK_ORDER_SUMMARY = "WORK_ORDER_SUMMARY",
  WORK_REQUEST_SUMMARY = "WORK_REQUEST_SUMMARY",
  INVENTORY_MOVEMENT_REPORT = "INVENTORY_MOVEMENT_REPORT",
  JKR_APD_SUMMARY = "JKR_APD_SUMMARY",
  JKR_KPI_REPORT = "JKR_KPI_REPORT",
}

type FilterOption = {
  id: number;
  name: string;
};

interface PdfReportStoreState {
  reportType: PdfReportType;
  dateRange: DateRange | null;
  workOrderTypes: FilterOption[];
  locations: FilterOption[];
  workOrderStatuses: FilterOption[];
  workRequestStatuses: FilterOption[];
  actions: {
    setReportType: (reportType: PdfReportType) => void;
    setDateRange: (dateRange: DateRange | undefined | null) => void;
    setWorkOrderTypes: (types: FilterOption[]) => void;
    setLocations: (locations: FilterOption[]) => void;
    setWorkOrderStatuses: (statuses: FilterOption[]) => void;
    setWorkRequestStatuses: (statuses: FilterOption[]) => void;
    reset: () => void;
  };
}

export const usePdfReportStore = create<PdfReportStoreState>((set) => ({
  reportType: PdfReportType.INITIAL,
  dateRange: { to: endOfMonth(new Date()), from: startOfMonth(new Date()) },
  workOrderTypes: [],
  locations: [],
  workOrderStatuses: [],
  workRequestStatuses: [],
  actions: {
    setReportType: (reportType) => {
      set(() => ({
        reportType,
      }));
    },
    setDateRange: (dateRange) => {
      set(() => ({
        dateRange,
      }));
    },
    setWorkOrderTypes: (types) => {
      set(() => ({
        workOrderTypes: types,
      }));
    },
    setLocations: (locations) => {
      set(() => ({
        locations,
      }));
    },
    setWorkOrderStatuses: (statuses) => {
      set(() => ({
        workOrderStatuses: statuses,
      }));
    },
    setWorkRequestStatuses: (statuses) => {
      set(() => ({
        workRequestStatuses: statuses,
      }));
    },
    reset: () => {
      set(() => ({
        reportType: PdfReportType.INITIAL,
        dateRange: null,
        workOrderTypes: [],
        locations: [],
        workOrderStatuses: [],
        workRequestStatuses: [],
      }));
    },
  },
}));

export const usePdfReportDateRange = () =>
  usePdfReportStore((state) => state.dateRange);
export const usePdfReportReportType = () =>
  usePdfReportStore((state) => state.reportType);
export const usePdfReportActions = () =>
  usePdfReportStore((state) => state.actions);
export const usePdfReportLocations = () =>
  usePdfReportStore((state) => state.locations);
export const usePdfReportWorkOrderStatuses = () =>
  usePdfReportStore((state) => state.workOrderStatuses);
export const usePdfReportWorkOrderTypes = () =>
  usePdfReportStore((state) => state.workOrderTypes);
export const usePdfReportWorkRequestStatuses = () =>
  usePdfReportStore((state) => state.workRequestStatuses);
