import React from "react";
import { trpc } from "../../../../lib/trpc";
import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useAppSelector } from "../../../../redux/store";
import { useSearchParams } from "react-router-dom";
import { cn } from "../../../../lib/utils";

interface WorkRequestHistogramProps {
  fullScreen?: boolean;
}
export function WorkRequestHistogram({ fullScreen }: WorkRequestHistogramProps) {
  const project = useAppSelector((state) => state.root.activeProject);
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month") ?? "")
    : undefined;
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year") ?? "")
    : undefined;

  const { data, isLoading } =
    trpc.analytics.getWorkRequestAvgTurnaroundTime.useQuery(
      {
        projectId: project?.id ?? 0,
        month,
        year,
      },
      {
        enabled: !!project,
      }
    );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data || data.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No work request turnaround time data available
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Work Request Turnaround Time</CardTitle>
        <CardDescription>
          Distribution of work requests by turnaround time
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={data.chartConfig}
          className={cn("mx-auto aspect-square max-h-[300px] w-full", {
            "max-h-none aspect-video h-full relative": fullScreen,
          })}
        >
          <BarChart data={data.chartData} accessibilityLayer>
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
            />
            <YAxis tickLine={false} axisLine={false} tickMargin={8} />
            <ChartTooltip content={<ChartTooltipContent indicator="line" />} />
            <Bar dataKey="value" fill="#8884d8" radius={[4, 4, 0, 0]} />
          </BarChart>
        </ChartContainer>
        <div className="mt-4 text-center">
          Average Turnaround Time:{" "}
          {data.averageTurnaroundTimeDays.toFixed(2)} days
        </div>
      </CardContent>
    </Card>
  );
}
