import { useFormContext, useWatch } from "react-hook-form";
import { PmChecklistStatus } from "../../../../api/model";
import { useAppSelector } from "../../../../redux/store";
import { useSelectPmFilterStore } from "../../../../stores/pm/pmFilterStore";
import useDebounce from "../../../../hooks/useDebounce";
import { trpc } from "../../../../lib/trpc";

interface PmTabWithCountProps {
  pmStatus: PmChecklistStatus;
}

export default function PmTabWithCount({ pmStatus }: PmTabWithCountProps) {
  const methods = useFormContext();
  const search = useWatch({ control: methods.control, name: "search" });
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const dateRange = useSelectPmFilterStore((state) => state.dateRange);
  const assetTypesFilter = useSelectPmFilterStore((state) => state.assetTypes);
  const locationFilter = useSelectPmFilterStore(
    (state) => state.filterLocation
  );

  const queryArg = useDebounce({
    value: {
      projectId: activeProj?.id?.toString() ?? "0",
      pmChecklistStatusId: pmStatus.id.toString(),
      locFilterId: locationFilter?.id?.toString(),
      search,
      filterEndDateISO: dateRange?.to?.toISOString(),
      filterStartDateISO: dateRange?.from?.toISOString(),
      assetTypeIds: assetTypesFilter.map((at) => at?.id?.toString() ?? "0"),
    },
    delay: 500,
  });

  const { data: pmData } = trpc.pm.getPmChecklists.useQuery(queryArg, {
    enabled: !!activeProj,
  });

  return <p>{`${pmStatus.name} (${pmData?.totalCount ?? "-"})`}</p>;
}
