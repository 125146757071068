import React from "react";
import { trpc } from "../../../../lib/trpc";
import { PieChart, Pie, Cell, Legend, Label } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useAppSelector } from "../../../../redux/store";
import { cn } from "../../../../lib/utils";

interface ContractPieChartProps {
  fullScreen?: boolean;
}

export function ContractPieChart({ fullScreen }: ContractPieChartProps) {
  const project = useAppSelector((state) => state.root.activeProject);

  const { data, isLoading } = trpc.analytics.getExpiredContractPieChart.useQuery(
    {
      projectId: project?.id ?? 0,
    },
    {
      enabled: !!project,
    }
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data || data.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No contract data available
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Contract Status Distribution</CardTitle>
        <CardDescription>
          Overview of contract statuses by expiry
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={data.chartConfig}
          className={cn("mx-auto aspect-square max-h-[300px] w-full", {
            "max-h-none aspect-video h-full relative mt-0": fullScreen,
          })}
        >
          <PieChart>
            <Pie
              data={data.chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={fullScreen ? 140 : 80}
              innerRadius={fullScreen ? 100 : 60}
              labelLine={false}
              label={({ payload, ...props }) => {
                if (payload.value === 0) {
                  return null;
                }
                return (
                  <text
                    cx={props.cx}
                    cy={props.cy}
                    x={props.x}
                    y={props.y}
                    textAnchor={props.textAnchor}
                    dominantBaseline={props.dominantBaseline}
                    fill={data.chartConfig[payload.name.toLowerCase().replace(/\s+/g, "") as keyof typeof data.chartConfig]?.color}
                  >
                    {`${payload.name} (${payload.value})`}
                  </text>
                );
              }}
            >
              {data.chartData.map((entry, index) => {
                if (entry.value === 0) {
                  return null;
                }
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={data.chartConfig[entry.name.toLowerCase().replace(/\s+/g, "") as keyof typeof data.chartConfig]?.color}
                  />
                );
              })}
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-xl font-bold"
                        >
                          {data.totalContracts}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          Total Contracts
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
          </PieChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
