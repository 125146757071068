import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PublicWorkRequestContainer from "../container/PublicWorkRequestContainer/PublicWorkRequestContainer";
import { auth } from "../firebase/firebaseConfig";
import WorkOrderReport from "../pdf/work-order-report";
import { RootApi } from "../redux/slices/RootApi";
import { clearSession, setFirebaseUserId } from "../redux/slices/RootSlice";
import AnalyticScreen from "../screens/AnalyticScreen/AnalyticScreen";
import A9AssetQrScreen from "../screens/AssetQrScreen/A9AssetQrScreen";
import AssetQrScreen from "../screens/AssetQrScreen/AssetQrScreen";
import AssetScreen from "../screens/AssetScreen/AssetScreen";
import BimScreen from "../screens/BimScreen/BimScreen";
import DashboardScreen from "../screens/DashboardScreen/DashboardScreen";
import OverallTab from "../screens/DashboardScreen/tabs/OverallTab";
import EscalationScreen from "../screens/EscalationScreen/EscalationScreen";
import HomeScreen from "../screens/HomeScreen/HomeScreen";
import InventoryScreen from "../screens/InventoryScreen/InventoryScreen";
import LegalComplianceScreen from "../screens/LegalComplianceScreen/LegalComplianceScreen";
import LoginScreen from "../screens/LoginScreen/LoginScreen";
import MeterScreen from "../screens/MeterScreen/MeterScreen";
import NoAccessScreen from "../screens/NoAccessScreen/NoAccessScreen";
import NotFoundScreen from "../screens/NotFoundScreen/NotFoundScreen";
import PdfReportScreen from "../screens/PdfReportScreen/PdfReportScreen";
import PermitToWorkScanQrCodeScreen from "../screens/PermitToWorkScanQrCodeScreen/PermitToWorkScanQrCodeScreen";
import PermitToWorkScreen from "../screens/PermitToWorkScreen/PermitToWorkScreen";
import PermitToWorkTemplateScreen from "../screens/PermitToWorkTemplateScreen/PermitToWorkTemplateScreen";
import PmSchedulerScreen from "../screens/PmSchedulerScreen/PmSchedulerScreen";
import PmScreen from "../screens/PmScreen/PmScreen";
import PmTemplateScreen from "../screens/PmTemplateScreen/PmTemplateScreen";
import PublicCustomWorkPermitScreen from "../screens/PublicCustomWorkPermitScreen.tsx/PublicCustomWorkPermitScreen";
import PublicWorkPermitScreen from "../screens/PublicWorkPermitScreen/PublicWorkPermitScreen";
import PublicWorkRequestDetailScreen from "../screens/PublicWorkRequestScreen/PublicWorkRequestDetailScreen/PublicWorkRequestDetailScreen";
import PublicWorkRequestFormScreen from "../screens/PublicWorkRequestScreen/PublicWorkRequestFormScreen/PublicWorkRequestFormScreen";
import PublicWorkRequestScreen from "../screens/PublicWorkRequestScreen/PublicWorkRequestScreen";
import PublicWorkRequestTrackingScreen from "../screens/PublicWorkRequestScreen/PublicWorkRequestTrackingScreen/PublicWorkRequestTrackingScreen";
import WRReviewScreen from "../screens/PublicWorkRequestScreen/WRReview/WRReviewScreen";
import SettingScreen from "../screens/SetttingScreen/SettingScreen";
import AccessControlTab from "../screens/SetttingScreen/tabs/AccessControlTab/AccessControlTab";
import AccountTab from "../screens/SetttingScreen/tabs/AccountTab/AccountTab";
import AnalyticsTab from "../screens/SetttingScreen/tabs/AnalyticsTab";
import DataImportSettingsTab from "../screens/SetttingScreen/tabs/DataImportSettingsTab/DataImportSettingsTab";
import WorkOrderTypeTab from "../screens/SetttingScreen/tabs/WorkOrderTypeTab/WorkOrderTypeTab";
import SplashScreen from "../screens/SplashScreen/SplashScreen";
import SuperAdminScreen from "../screens/SuperAdminScreen/SuperAdminScreen";
import CompanyDetailScreen from "../screens/SuperAdminScreen/subScreens/CompanyProjectListScreen/CompanyDetailScreen/CompanyDetailScreen";
import CompanyProjectListTab from "../screens/SuperAdminScreen/subScreens/CompanyProjectListScreen/CompanyProjectListScreen";
import ProjectDetailScreen from "../screens/SuperAdminScreen/subScreens/ProjectsScreen/ProjectDetailScreen/ProjectDetailScreen";
import ProjectScreen from "../screens/SuperAdminScreen/subScreens/ProjectsScreen/ProjectsScreen";
import SuperAdminUserDetailScreen from "../screens/SuperAdminScreen/subScreens/SuperAdminUsersScreen/SuperAdminUserDetailScreen/SuperAdminUserDetailScreen";
import SuperAdminUsersScreen from "../screens/SuperAdminScreen/subScreens/SuperAdminUsersScreen/SuperAdminUsersScreen";
import TnbBillScreen from "../screens/TnbBillScreen/TnbBillScreen";
import VendorScreen from "../screens/VendorScreen/VendorScreen";
import WOSchedulerScreen from "../screens/WOSchedulerScreen/WOSchedulerScreen";
import WorkOrderScreen from "../screens/WorkOrderScreen/WorkOrderScreen";
import WorkPermitScreen from "../screens/WorkPermitScreen/WorkPermitScreen";
import WorkRequestScreen from "../screens/WorkRequestScreen/WorkRequestScreen";
import BimFeatureProtectedRoute from "./BimFeatureProtectedRoute";
import CompanyAdminProtectedRoute from "./CompanyAdminProtectedRoute";
import CustomPtwProtectedRoute from "./CustomPtwProtectedRoute";
import PdfReportProtectedRoute from "./PdfReportProtectedRoute";
import ProtectedRoute from "./ProtectedRoute";
import SuperAdminProtectedRoute from "./SuperAdminProtectedRoute";
import NavigateToCompany from "./navigator/NavigateToCompany";
import PdfView from "../pdf/pdf-view";
import ManHourSettingTab from "../screens/SetttingScreen/tabs/ManHourSettingTab/ManHourSettingTab";
import CustomCsvDownloadingScreen from "../screens/CustomCsvDownloadingScreen/CustomCsvDownloadingScreen";
import WorkRequestSettingTab from "../screens/SetttingScreen/tabs/WorkRequestSettingTab/WorkRequestSettingTab";
import FirstTimeLoginScreen from "../screens/FirstTimeLoginScreen/FirstTimeLoginScreen";
import FirstTimeLoginRouter from "./FirstTimeLoginRouter";
import AppBtnBarStagingNotice from "../components/AppBtmBarStagingNotice";
import SingleAssetA6QrScreen from "../screens/AssetQrScreen/SingleAssetA6QrScreen";
import { JKRCustomWorkOrderPdfPreview } from "../screens/CustomPdf/JKRCustomWorkOrderPdfPreview";
import { JkrApdSummaryPdfReview } from "../screens/CustomPdf/JkrApdSummaryPdfPreview";
import JKRWorkRequestQrCode from "../screens/CustomPdf/JkrWorkRequestQrCode";
import WorkOrderPriority from "../screens/SetttingScreen/tabs/WorkOrderPriority/WorkOrderPriority";
import WorkOrderTrade from "../screens/SetttingScreen/tabs/WorkOrderTrade/WorkOrderTrade";
import CustomExcelScreen from "../screens/CustomExcelScreen/CustomExcelScreen";
import CCDashboard from "../screens/DashboardScreen/components/CCDashboard";
import { CCEquipmentDashboard } from "../screens/DashboardScreen/components/CCEquipmentDashboard";
import CCWorkOrderDashboard from "../screens/DashboardScreen/components/CCWorkOrderDashboard";
import CCStaffAnalytics from "../screens/DashboardScreen/components/CCStaffAnalytics";
import SummaryInventoryDownloadScreen from "../screens/SummaryInventoryDownloadScreen/SummaryInventoryDownloadScreen";
import AnalyticSlideShowScreen from "../screens/AnalyticSlideShowScreen/AnalyticSlideShowScreen";
import PmLocationScreen from "../screens/PmScreen/PmLocationScreen.tsx/PmLocationScreen";

export default function AppRouting() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      // If it is public route, to not navigate user to home screen.
      if (location.pathname.startsWith("/public")) {
        setIsInit(false);
      } else {
        if (user) {
          setIsLogin(true);
          dispatch(setFirebaseUserId(user.uid));
          navigate(
            location.pathname === "/" || location.pathname === "/login"
              ? "home"
              : location.pathname + location.search
          );
        } else {
          dispatch(RootApi.util.resetApiState());
          dispatch(clearSession());
          setIsLogin(false);
          navigate("login");
        }
        setIsInit(false);
      }
    });

    return unsubscribe;
  }, []);

  return (
    <div className="overflow-x-hidden">
      <Routes>
        {/* 
       <Route path="/pdf" element={<PdfView />} />
       */}

        <Route path="/" element={<SplashScreen isInit={isInit} />}>
          <Route path="login" element={<LoginScreen />} />
          <Route path="first-time-login" element={<FirstTimeLoginScreen />} />
          <Route
            path="custom-csv/:csvSettingId"
            element={<CustomCsvDownloadingScreen />}
          />
          <Route
            path="custom-csv/parts-and-accessories-costing"
            element={<CustomExcelScreen />}
          />
          <Route
            path="customPdf/jkr/:workOrderId"
            element={<JKRCustomWorkOrderPdfPreview />}
          />
          <Route
            path="customPdf/jkr/apd-summary"
            element={<JkrApdSummaryPdfReview />}
          />
          <Route
            path="customPdf/jkr/work-request"
            element={<JKRWorkRequestQrCode />}
          />
          <Route
            path="qr-asset"
            element={
              <ProtectedRoute>
                <AssetQrScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="qr-asset/:assetId"
            element={
              // <ProtectedRoute>
              <SingleAssetA6QrScreen />
              // </ProtectedRoute>
            }
          />
          <Route
            path="a9-qr-asset"
            element={
              <ProtectedRoute>
                <A9AssetQrScreen />
              </ProtectedRoute>
            }
          />
          <Route path="work-permit-pdf">
            <Route
              path=":ptwTemplateId"
              element={
                <ProtectedRoute>
                  <PermitToWorkScanQrCodeScreen />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="analytic-slide-show"
            element={<AnalyticSlideShowScreen />}
          />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <FirstTimeLoginRouter>
                  <HomeScreen />
                </FirstTimeLoginRouter>
              </ProtectedRoute>
            }
          >
            <Route path="cc-work-request-analytics" element={<CCDashboard />} />
            <Route
              path="cc-equipment-analytics"
              element={<CCEquipmentDashboard />}
            />
            <Route
              path="cc-work-order-analytics"
              element={<CCWorkOrderDashboard />}
            />
            <Route path="cc-staff-analytics" element={<CCStaffAnalytics />} />
            <Route path="dashboard" element={<DashboardScreen />}>
              <Route path="overall" element={<OverallTab />} />
            </Route>
            <Route path="analytic" element={<AnalyticScreen />} />
            <Route path="work-requests" element={<WorkRequestScreen />} />
            <Route path="work-orders" element={<WorkOrderScreen />} />
            {/* <Route
              path="work-orders/:workOrderId"
              element={<WorkOrderDetailDrawer />}
            /> */}
            <Route path="wo-scheduler" element={<WOSchedulerScreen />} />
            <Route path="inventory" element={<InventoryScreen />} />
            <Route
              path="legal-compliance"
              element={<LegalComplianceScreen />}
            />
            <Route path="meters" element={<MeterScreen />} />
            <Route path="tnb-bills" element={<TnbBillScreen />} />
            <Route
              path="pdf-report"
              element={
                <PdfReportProtectedRoute>
                  <PdfReportScreen />
                </PdfReportProtectedRoute>
              }
            />
            <Route path="pm" element={<PmScreen />} />
            <Route path="template" element={<PmTemplateScreen />} />
            <Route path="scheduler-pm" element={<PmSchedulerScreen />} />
            <Route path="location-pm" element={<PmLocationScreen />} />
            <Route path="work-permit" element={<WorkPermitScreen />} />
            <Route
              path="ptw"
              element={
                <CustomPtwProtectedRoute>
                  <PermitToWorkScreen />
                </CustomPtwProtectedRoute>
              }
            />
            <Route
              path="wp-template"
              element={
                <CustomPtwProtectedRoute>
                  <PermitToWorkTemplateScreen />
                </CustomPtwProtectedRoute>
              }
            />
            <Route
              path="bim"
              element={
                <BimFeatureProtectedRoute>
                  <BimScreen />
                </BimFeatureProtectedRoute>
              }
            />
            <Route path="vendors" element={<VendorScreen />} />
            <Route path="assets" element={<AssetScreen />} />
            <Route path="escalations" element={<EscalationScreen />} />
            <Route
              path="super-admin"
              element={
                <SuperAdminProtectedRoute>
                  <SuperAdminScreen />
                </SuperAdminProtectedRoute>
              }
            >
              <Route index element={<NavigateToCompany />} />
              <Route path="companies" element={<CompanyProjectListTab />} />
              <Route
                path="companies/:companyId"
                element={<CompanyDetailScreen />}
              />
              <Route path="projects" element={<ProjectScreen />} />
              <Route
                path="projects/:projectId"
                element={<ProjectDetailScreen />}
              />
              <Route path="users" element={<SuperAdminUsersScreen />} />
              <Route
                path="users/:userId"
                element={<SuperAdminUserDetailScreen />}
              />
              {/* <Route
                path="invoices"
                element={<SuperAdminInvoicesListScreen />}
              /> */}
            </Route>
            <Route
              path="settings"
              element={
                <CompanyAdminProtectedRoute>
                  <SettingScreen />
                </CompanyAdminProtectedRoute>
              }
            >
              <Route index element={<AccountTab />} />
              <Route path="access-control" element={<AccessControlTab />} />
              <Route
                path="analytics"
                element={
                  <SuperAdminProtectedRoute>
                    <AnalyticsTab />
                  </SuperAdminProtectedRoute>
                }
              />
              <Route
                path="data-import"
                element={
                  <SuperAdminProtectedRoute>
                    <DataImportSettingsTab />
                  </SuperAdminProtectedRoute>
                }
              />
              <Route
                path="work-order-type"
                element={
                  <CompanyAdminProtectedRoute>
                    <WorkOrderTypeTab />
                  </CompanyAdminProtectedRoute>
                }
              />
              <Route
                path="man-hour-settings"
                element={
                  <CompanyAdminProtectedRoute>
                    <ManHourSettingTab />
                  </CompanyAdminProtectedRoute>
                }
              />
              <Route
                path="work-request-settings"
                element={
                  <CompanyAdminProtectedRoute>
                    <WorkRequestSettingTab />
                  </CompanyAdminProtectedRoute>
                }
              />
              <Route
                path="work-order-priority"
                element={
                  <CompanyAdminProtectedRoute>
                    <WorkOrderPriority />
                  </CompanyAdminProtectedRoute>
                }
              />
              <Route
                path="work-order-trade"
                element={
                  <CompanyAdminProtectedRoute>
                    <WorkOrderTrade />
                  </CompanyAdminProtectedRoute>
                }
              />
            </Route>
            <Route path="403" element={<NoAccessScreen />} />
          </Route>
        </Route>

        <Route path="*" element={<NotFoundScreen />} />
        {/* <Route path="/public/3d-building" element={<App3dBuildingScreen />} /> */}
        <Route
          path="/public/work-permit/:projectCode"
          element={<PublicWorkPermitScreen />}
        />
        <Route
          path="/public/work-permit/:projectCode/:ptwTemplateId"
          element={<PublicCustomWorkPermitScreen />}
        />
        <Route
          path="/public/work-request/:projectCode"
          element={<PublicWorkRequestContainer />}
        >
          <Route index element={<PublicWorkRequestScreen />} />
          <Route path="request" element={<PublicWorkRequestFormScreen />} />
          <Route path="track" element={<PublicWorkRequestTrackingScreen />} />
          <Route
            path="track/:workRequestCode"
            element={<PublicWorkRequestDetailScreen />}
          />
          <Route
            path="review/:workRequestCode/:rating"
            element={<WRReviewScreen />}
          />
        </Route>
        <Route
          path="inventory-summary/:projectId"
          element={<SummaryInventoryDownloadScreen />}
        />
      </Routes>
      <AppBtnBarStagingNotice />
    </div>
  );
}
