import { useFormContext } from "react-hook-form";
import { Input, InputProps } from "./ui/input";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { cn } from "../utils/util";
import { ReactNode } from "react";

interface AppTextFieldProp {
  label?: string;
  className?: string;
  inputClassName?: string;
  inputProps?: InputProps;
  name: string;
  description?: string;
  testId?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

export default function AppTextField({
  label,
  className,
  inputClassName,
  inputProps,
  name,
  description,
  testId,
  leftIcon,
  rightIcon,
  ...props
}: AppTextFieldProp & InputProps) {
  const form = useFormContext();

  return (
    <div className={`flex flex-col ${className}`} data-testid={testId}>
      <FormField
        control={form.control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <FormItem className={cn(className)}>
            {label && <FormLabel>{label}</FormLabel>}
            <div className="relative">
              {leftIcon && (
                <div className="absolute left-2 top-1/2 -translate-y-1/2">
                  {leftIcon}
                </div>
              )}
              <FormControl>
                <Input
                  {...props}
                  {...field}
                  value={field.value ?? ""}
                  className={cn(
                    "focus-visible:ring-primary-900 bg-slate-50",
                    {
                      "ring-2 ring-offset-1 ring-red-500": !!error,
                      "pl-8": leftIcon,
                      "pr-8": rightIcon,
                    },
                    inputClassName
                  )}
                />
              </FormControl>
              {rightIcon && (
                <div className="absolute right-2 top-1/2 -translate-y-1/2">
                  {rightIcon}
                </div>
              )}
            </div>

            <FormDescription>{description}</FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
}
