import { ColumnDef, Row } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_VIEW_STATE,
  PM_TEMPLATE_DRAWER,
} from "../../../components/drawers/AppDrawer";
import AppButton from "@/components/AppButton";
import { useState } from "react";
import { AssetListDialog } from "@/components/dialogs/AssetListDialog";
import { RouterOutputs } from "@cerev-cmms/trpc";

type PmTemplateType = RouterOutputs["pm"]["getPmTemplates"]["data"][number];
type Asset = RouterOutputs["pm"]["getPmTemplates"]["data"][number]["asset"];

const PmTemplateNameNav = ({
  pmTemplate,
}: {
  pmTemplate: Row<PmTemplateType>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", PM_TEMPLATE_DRAWER);
          p.set("drawerState", DRAWER_VIEW_STATE);
          p.set("pmTemplateId", pmTemplate.getValue("id"));
          return p;
        });
      }}
    >
      {pmTemplate.getValue("name") ?? "-"}
    </div>
  );
};

const AssetColumn = ({
  assets,
  row,
}: {
  assets: Asset[];
  row: Row<PmTemplateType>;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <AppButton
        variant="outline"
        onClick={() => setIsDialogOpen(true)}
        label={`${assets?.length ?? 0} Equipment`}
      />
      <AssetListDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        assets={assets ?? []}
      />
    </>
  );
};

export const pmSettingColumns: ColumnDef<PmTemplateType>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => <PmTemplateNameNav pmTemplate={row} />,
  },
  {
    accessorKey: "asset",
    header: "Equipments",
    cell: ({ row }) => (
      <AssetColumn assets={row.getValue("asset") ?? []} row={row} />
    ),
  },
];
