import { useMultipleCurrencyInventoryEnabled } from "../hooks/useFeatures";

export default function MultipleCurrencyInventoryGuard({
  children,
}: {
  children: React.ReactNode;
}) {
  const multipleCurrencyInventoryEnabled =
    useMultipleCurrencyInventoryEnabled();
  if (!multipleCurrencyInventoryEnabled) return <></>;

  return <>{children}</>;
}
