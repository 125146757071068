import { z } from "zod";

export const FeatureTypeEnum = z.enum([
  "SCHEDULER",
  "BIM",
  "SERIALIZED_INVENTORY",
  "CUSTOM_PTW",
  "PDF_REPORT",
  "WR_SAVE_USER_INFO",
  "WR_RATING",
  "MAN_HOURS",
  "SIGNATORY",
  "WO_PROFIT_LOSS",
  "INVENTORY_LOCATION",
  "MODULE_ACCESS",
  "PRIVATE_ATTACHMENT",
  "ESCALATION",
  "EXTRA_FIELD_PRICING",
  "SUB_EQUIPMENT",
  "EQUIPMENT_LCCA",
  "WO_PRIORITY",
  "WO_TRADE",
  "SELF_ASSIGN_WORK_ORDER",
  "INVESTIGATION",
  "IPM_NEW_GRAPHS",
  "MULTIPLE_EQUIPMENT_PM",
  "MULTIPLE_CURRENCY_INVENTORY",
  "SLIDE_SHOW_ANALYTICS",
  "WO_CLOSE_POP_UP",
]);

export type FeatureType = z.infer<typeof FeatureTypeEnum>;

export const toggleFeatureSchema = z.object({
  projectId: z.string(),
  featureType: FeatureTypeEnum,
});

export type ToggleFeatureInput = z.infer<typeof toggleFeatureSchema>;

export const getFeaturesSchema = z.object({
  projectId: z.string(),
});

export type GetFeaturesInput = z.infer<typeof getFeaturesSchema>;

export const isFeatureEnabledSchema = z.object({
  projectId: z.string(),
  featureType: FeatureTypeEnum,
});

export type IsFeatureEnabledInput = z.infer<typeof isFeatureEnabledSchema>;
