import { Link } from "react-router-dom";
import { Wrench, ChevronDown, ChevronRight } from "lucide-react";
import {
  SidebarMenuItem,
  SidebarMenuButton,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  useSidebar,
} from "../ui/sidebar";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

interface PreventiveMaintenanceMenuItemProps {
  isActive: (path: string) => boolean;
  schedulerEnabled?: boolean;
}

export function PreventiveMaintenanceMenuItem({
  isActive,
  schedulerEnabled = false,
}: PreventiveMaintenanceMenuItemProps) {
  const { state, isMobile } = useSidebar();
  const isCollapsed = state === "collapsed";

  if (isCollapsed && !isMobile) {
    return (
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              className="h-8 w-8"
              isActive={
                isActive("/home/pm") ||
                isActive("/home/template") ||
                isActive("/home/scheduler-pm") ||
                isActive("/home/pm-location")
              }
              tooltip="Preventive Maintenance"
            >
              <Wrench className="size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            side="right"
            align="start"
            className="w-56"
            onCloseAutoFocus={(e) => e.preventDefault()}
          >
            <Link to="/home/pm">
              <DropdownMenuItem className="cursor-pointer">
                PM List
              </DropdownMenuItem>
            </Link>
            <Link to="/home/location-pm">
              <DropdownMenuItem className="cursor-pointer">
                View By Location
              </DropdownMenuItem>
            </Link>
            <Link to="/home/template">
              <DropdownMenuItem className="cursor-pointer">
                PM Template
              </DropdownMenuItem>
            </Link>

            {schedulerEnabled && (
              <Link to="/home/scheduler-pm">
                <DropdownMenuItem className="cursor-pointer">
                  PM Scheduler
                </DropdownMenuItem>
              </Link>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    );
  }

  return (
    <SidebarMenuItem>
      <Collapsible defaultOpen className="group/collapsible w-full">
        <CollapsibleTrigger className="w-full">
          <SidebarMenuItem className="group/label text-sm text-sidebar-foreground hover:bg-sidebar-accent hover:text-sidebar-accent-foreground flex w-full items-center justify-between">
            <SidebarMenuButton asChild>
              <div className="flex items-center gap-2">
                <Wrench className="size-4" />
                <span>Preventive Maintenance</span>
              </div>
            </SidebarMenuButton>
            {!isCollapsed && (
              <ChevronRight className="ml-auto size-4 shrink-0 transition-transform group-data-[state=open]/collapsible:rotate-90" />
            )}
          </SidebarMenuItem>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <SidebarMenuSub>
            <SidebarMenuSubItem>
              <Link to="/home/pm">
                <SidebarMenuSubButton asChild isActive={isActive("/home/pm")}>
                  <span>PM List</span>
                </SidebarMenuSubButton>
              </Link>
            </SidebarMenuSubItem>
            <SidebarMenuSubItem>
              <Link to="/home/location-pm">
                <SidebarMenuSubButton
                  asChild
                  isActive={isActive("/home/location-pm")}
                >
                  <span>View By Location</span>
                </SidebarMenuSubButton>
              </Link>
            </SidebarMenuSubItem>
            <SidebarMenuSubItem>
              <Link to="/home/template">
                <SidebarMenuSubButton
                  asChild
                  isActive={isActive("/home/template")}
                >
                  <span>PM Template</span>
                </SidebarMenuSubButton>
              </Link>
            </SidebarMenuSubItem>

            {schedulerEnabled && (
              <SidebarMenuSubItem>
                <Link to="/home/scheduler-pm">
                  <SidebarMenuSubButton
                    asChild
                    isActive={isActive("/home/scheduler-pm")}
                  >
                    <span>PM Scheduler</span>
                  </SidebarMenuSubButton>
                </Link>
              </SidebarMenuSubItem>
            )}
          </SidebarMenuSub>
        </CollapsibleContent>
      </Collapsible>
    </SidebarMenuItem>
  );
}
