import { useSearchParams } from "react-router-dom";
import { trpc } from "../../../../lib/trpc";
import { DataTable } from "../../../react-table/DataTable";
import { useReactTable, getCoreRowModel } from "@tanstack/react-table";
import { assetPmColumns } from "./columns/assetPmColumns";
import {
  DRAWER_UPDATE_STATE,
  DRAWER_VIEW_STATE,
  PM_CHECKLIST_DETAIL_DRAWER,
  PM_CHECKLIST_FORM_DRAWER,
} from "../../AppDrawer";

export default function AssetPmTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const assetId = searchParams.get("assetId");

  const { data: pmChecklists, isLoading } = trpc.assets.getAssetPm.useQuery(
    {
      assetId: assetId ?? "",
    },
    {
      enabled: !!assetId,
    }
  );

  const table = useReactTable({
    data: pmChecklists ?? [],
    columns: assetPmColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleRowClick = (row: any) => {
    const pmChecklist = row.original;
    if (pmChecklist.pmStatus.name === "Close") {
      setSearchParams((p) => {
        p.set("drawerType", PM_CHECKLIST_DETAIL_DRAWER);
        p.set("drawerState", DRAWER_VIEW_STATE);
        p.set("pmChecklistId", pmChecklist.id?.toString() ?? "");
        return p;
      });
    } else {
      setSearchParams((p) => {
        p.set("drawerState", DRAWER_UPDATE_STATE);
        p.set("drawerType", PM_CHECKLIST_FORM_DRAWER);
        p.set("pmChecklistId", pmChecklist.id?.toString() ?? "");
        return p;
      });
    }
  };

  return (
    <div className="p-4">
      <DataTable
        table={table}
        isLoading={isLoading}
        totalCount={pmChecklists?.length ?? 0}
        onRowClick={handleRowClick}
      />
    </div>
  );
}
