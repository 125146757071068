import { ColumnDef } from "@tanstack/react-table";
import { RouterOutputs } from "@cerev-cmms/trpc";
import { Badge } from "../../../../components/ui/badge";
import { cn } from "../../../../lib/utils";
import AppFullLocation from "../../../../components/AppFullLocation";
import useDate from "../../../../hooks/useDate";
import { useState } from "react";
import AppButton from "../../../../components/AppButton";
import { AssetListDialog } from "../../../../components/dialogs/AssetListDialog";
import { DRAWER_VIEW_STATE } from "../../../../components/drawers/AppDrawer";
import { PM_CHECKLIST_DETAIL_DRAWER } from "../../../../components/drawers/AppDrawer";
import { useSearchParams } from "react-router-dom";

type PmChecklist =
  RouterOutputs["pm"]["getUpcomingPmChecklist"]["data"][number];

function getContrastTextColor(hexColor: string | null | undefined): string {
  if (!hexColor) return "text-foreground";

  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.4 ? "text-neutral-900" : "text-white";
}

export const upcomingPmColumns: ColumnDef<PmChecklist>[] = [
  {
    accessorKey: "pmTemplate.name",
    header: "Checklist",
    cell: ({ row }) => {
      const [, setSearchParams] = useSearchParams();
      return (
        <div
          className="font-medium text-primary-900 underline hover:cursor-pointer"
          onClick={() => {
            const pmChecklist = row.original;
            setSearchParams((p) => {
              p.set("drawerType", PM_CHECKLIST_DETAIL_DRAWER);
              p.set("drawerState", DRAWER_VIEW_STATE);
              p.set("pmChecklistId", pmChecklist.id?.toString() ?? "");
              return p;
            });
          }}
        >
          {row.original.pmTemplate.name}
        </div>
      );
    },
  },
  {
    accessorKey: "refNo",
    header: "Reference No.",
    cell: ({ row }) => <div>{row.original.refNo ?? "-"}</div>,
  },
  {
    accessorKey: "asset.name",
    header: "Equipment",
    cell: ({ row }) => {
      const isMultipleEquipments = !!row.original.multipleAssets?.length;
      const [isDialogOpen, setIsDialogOpen] = useState(false);
      return (
        <div className="flex">
          <AppButton
            variant="outline"
            onClick={(e) => {
              e.stopPropagation();
              setIsDialogOpen(true);
            }}
            label={`${row.original.multipleAssets?.length ?? 0} Equipments`}
          />
          <AssetListDialog
            isOpen={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            assets={row.original.multipleAssets ?? []}
          />
        </div>
      );
    },
  },
  {
    accessorKey: "plannedOn",
    header: "Planned Date",
    cell: ({ row }) => {
      const { formatDateToLocal } = useDate();
      return (
        <div>
          {row.original.plannedOn
            ? formatDateToLocal({
                dateStr: row.original.plannedOn?.toISOString(),
              })
            : "-"}
        </div>
      );
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const { pmStatus } = row.original;
      const textColor = getContrastTextColor(pmStatus.colorHex);

      return (
        <Badge
          className={cn(
            "flex items-center gap-1 w-fit border-slate-300",
            textColor
          )}
          style={{
            backgroundColor: `#${pmStatus.colorHex}`,
          }}
        >
          <span>{pmStatus.name}</span>
        </Badge>
      );
    },
  },
];
