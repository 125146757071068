import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import WorkRequestTrend from "../DashboardScreen/components/WorkRequestAnalytics/WorkRequestTrend";
import { WorkRequestStatusBreakdownPieChart } from "../DashboardScreen/components/WorkRequestAnalytics/WorkRequestStatusBreakdownPieChart";
import WorkRequestStatusMonthlyBreakdown from "../DashboardScreen/components/WorkRequestAnalytics/WorkRequestStatusMonthlyBreakdown";
import WorkRequestKpiAvgTurnAroundTime from "../DashboardScreen/components/WorkRequestAnalytics/WorkRequestKpiAvgTurnAroundTime";
import WorkRequestDayTrendTurnaroundTime from "../DashboardScreen/components/WorkRequestAnalytics/WorkRequestDayTrendTurnaroundTime";
import { WorkRequestAvgRating } from "../DashboardScreen/components/WorkRequestAnalytics/WorkRequestAvgRating";
import { WorkRequestHistogram } from "../DashboardScreen/components/WorkRequestAnalytics/WorkRequestHistogram";
import WorkOrderTrend from "../DashboardScreen/components/WorkOrderAnalytics/WorkOrderTrend";
import { WorkOrderClosureRate } from "../DashboardScreen/components/WorkOrderAnalytics/WorkOrderClosureRate";
import { WorkOrderTypeApportionment } from "../DashboardScreen/components/WorkOrderAnalytics/WorkOrderTypeApportionment";
import { PMApportionmentStatusPieChart } from "../DashboardScreen/components/PMAnalytics/PMApportionmentStatusPieChart";
import { PMTrendAreaChart } from "../DashboardScreen/components/PMAnalytics/PMTrendAreaChart";
import { PMUpdatedTrendChart } from "../DashboardScreen/components/PMAnalytics/PMUpdatedTrendChart";
import { ContractPieChart } from "../DashboardScreen/components/LegalComplianceAnalytics/ContractPieChart";
import { cn } from "../../lib/utils";
import { ArrowLeft } from "lucide-react";
import AppButton from "../../components/AppButton";
import { useSelectedCharts } from "../../stores/dashboardStore/dashboardStore";

const SLIDE_DURATION = 4000; // 4 seconds per slide

// Map chart IDs to their components
const chartComponents: Record<string, {
  component: React.ComponentType<any>;
  title: string;
}> = {
  "work-request-trend": {
    component: WorkRequestTrend,
    title: "Work Request Trend",
  },
  "work-request-status-breakdown": {
    component: WorkRequestStatusBreakdownPieChart,
    title: "Status Breakdown",
  },
  "work-request-status-monthly": {
    component: WorkRequestStatusMonthlyBreakdown,
    title: "Monthly Status Breakdown",
  },
  "work-request-kpi-turnaround": {
    component: WorkRequestKpiAvgTurnAroundTime,
    title: "Average Turnaround Time",
  },
  "work-request-day-trend": {
    component: WorkRequestDayTrendTurnaroundTime,
    title: "Daily Turnaround Trend",
  },
  "work-request-avg-rating": {
    component: WorkRequestAvgRating,
    title: "Average Rating",
  },
  "work-request-histogram": {
    component: WorkRequestHistogram,
    title: "Work Request Distribution",
  },
  "work-order-trend": {
    component: WorkOrderTrend,
    title: "Work Order Trend",
  },
  "work-order-type-apportionment": {
    component: WorkOrderTypeApportionment,
    title: "Work Order Type Distribution",
  },
  "work-order-closure-rate": {
    component: WorkOrderClosureRate,
    title: "Work Order Closure Rate",
  },
  "pm-apportionment-status-pie-chart": {
    component: PMApportionmentStatusPieChart,
    title: "PM Status Distribution",
  },
  "pm-trend-area-chart": {
    component: PMTrendAreaChart,
    title: "PM Trend Analysis",
  },
  "pm-updated-trend-chart": {
    component: PMUpdatedTrendChart,
    title: "PM Update Trend",
  },
  "contract-pie-chart": {
    component: ContractPieChart,
    title: "Contract Status Overview",
  },
};

export default function AnalyticSlideShowScreen() {
  const navigate = useNavigate();
  const selectedCharts = useSelectedCharts();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Get the selected chart components
  const selectedComponents = selectedCharts
    .map(chartId => chartComponents[chartId])
    .filter(Boolean); // Remove any undefined components

  // If no charts are selected, redirect back to dashboard
  useEffect(() => {
    if (selectedComponents.length === 0) {
      navigate("/home");
    }
  }, [selectedComponents.length, navigate]);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentSlideIndex((prev) => (prev + 1) % selectedComponents.length);
        setIsTransitioning(false);
      }, 500);
    }, SLIDE_DURATION);

    return () => clearInterval(timer);
  }, [selectedComponents.length]);

  if (selectedComponents.length === 0) {
    return null;
  }

  const CurrentComponent = selectedComponents[currentSlideIndex].component;

  return (
    <div className="relative h-screen w-screen">
      {/* Back Button */}
      <AppButton
        label="Go Back"
        icon={<ArrowLeft />}
        variant="outline"
        onClick={() => navigate("/home")}
        className="absolute top-4 right-4 z-10 p-2 bg-white/10 hover:bg-white/20 backdrop-blur-sm transition-colors"
      />

      {/* Slide Content */}
      <div
        className={cn(
          "h-screen w-screen p-0 transition-opacity duration-500",
          isTransitioning ? "opacity-0" : "opacity-100"
        )}
      >
        <CurrentComponent fullScreen />
      </div>
    </div>
  );
}
