import React from "react";
import { trpc } from "../../../../lib/trpc";
import { useSearchParams } from "react-router-dom";
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useAppSelector } from "../../../../redux/store";
import { cn } from "../../../../lib/utils";

interface WorkRequestDayTrendTurnaroundTimeProps {
  fullScreen?: boolean;
}

export default function WorkRequestDayTrendTurnaroundTime({
  fullScreen,
}: WorkRequestDayTrendTurnaroundTimeProps) {
  const project = useAppSelector((state) => state.root.activeProject);
  const [searchParams] = useSearchParams();
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : undefined;
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month")!)
    : undefined;

  const { data, isLoading } = trpc.analytics.dayTrendTurnaroundTime.useQuery(
    {
      projectId: project?.id ?? 0,
      year,
      month,
    },
    {
      enabled: !!project,
    }
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data || data.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No work request data available for the selected period
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Daily Turnaround Time Trend</CardTitle>
        <CardDescription>
          Average turnaround time per day:{" "}
          {data.statistics.overallAvgHours.toFixed(1)} hours
        </CardDescription>
      </CardHeader>
      <CardContent
        className={cn({
          "flex flex-col max-h-screen relative": fullScreen,
        })}
      >
        <ChartContainer
          config={data.chartConfig}
          className={cn("mx-auto aspect-square max-h-[300px] w-full", {
            "max-h-none aspect-video h-full relative": fullScreen,
          })}
        >
          <AreaChart
            data={data.chartData}
            margin={{ left: 12, right: 12, top: 20, bottom: 20 }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="day"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              angle={fullScreen ? -10 : undefined}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              label={{
                value: "Hours",
                angle: -90,
                position: "insideLeft",
                style: { textAnchor: "middle" },
              }}
            />
            <ChartTooltip content={<ChartTooltipContent />} />
            <Area
              type="monotone"
              dataKey="avgHours"
              name={data.chartConfig.avgHours.label}
              stroke={data.chartConfig.avgHours.color}
              fill={data.chartConfig.avgHours.color}
              fillOpacity={0.3}
            />
          </AreaChart>
        </ChartContainer>

        {/* Statistics */}
        <div className={cn({
          "mt-6 grid grid-cols-2 gap-4": !fullScreen,
          "absolute top-4 right-6 p-4 bg-white border-slate-400 border-solid border rounded-md": fullScreen,
        })}>
          <div className="space-y-1">
            <p className="text-sm text-muted-foreground">Total Requests</p>
            <p className="text-2xl font-bold">
              {data.statistics.totalRequests}
            </p>
          </div>
          <div className="space-y-1">
            <p className="text-sm text-muted-foreground">Overall Average</p>
            <p className="text-2xl font-bold">
              {data.statistics.overallAvgHours.toFixed(1)}h
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
