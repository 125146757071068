import { useCallback } from "react";
import WorkOrderPdfReportViewer from "../pdfs/WorkOrderPdfReportViewer";
import {
  PdfReportType,
  usePdfReportReportType,
} from "../../../../stores/pdfReportStore/pdfReportStore";
import { FileSearch, Filter } from "lucide-react";
import { JkrApdSummaryPdfReview } from "../../../CustomPdf/JkrApdSummaryPdfPreview";
import JkrKpiReport from "../../../DashboardScreen/components/JkrKpiReport";
import { ScrollArea } from "../../../../components/ui/scroll-area";
import WorkRequestPdfReportViewer from "../pdfs/WorkRequestPdfReportViewer";

export default function ReportSection() {
  const pdfReportType = usePdfReportReportType();

  const renderContent = useCallback(() => {
    switch (pdfReportType) {
      case PdfReportType.INITIAL:
        return (
          <div className="flex flex-col justify-center items-center h-[calc(100vh-150px)] gap-4">
            <FileSearch className="text-gray-300 h-20 w-20" />
            <h3 className="text-lg text-gray-400">Pick a report</h3>
          </div>
        );
      case PdfReportType.WORK_ORDER_SUMMARY:
        return <WorkOrderPdfReportViewer />;
      case PdfReportType.WORK_REQUEST_SUMMARY:
        return <WorkRequestPdfReportViewer />;
      case PdfReportType.INVENTORY_MOVEMENT_REPORT:
        return <div>Inventory Movement Report</div>;
      case PdfReportType.JKR_APD_SUMMARY:
        return <JkrApdSummaryPdfReview />;
      case PdfReportType.JKR_KPI_REPORT:
        return <JkrKpiReport />;
    }
  }, [pdfReportType]);

  return (
    <div className="p-2 h-[calc(100vh-150px)] w-full flex flex-col">
      <ScrollArea className="border border-slate-200 rounded-lg">
        {renderContent()}
      </ScrollArea>
    </div>
  );
}
