import {
  GetWorkOrderSummaryReportPdfInput,
  getWorkOrderSummaryReportPdfSchema,
} from "@cerev-cmms/zod-types";
import { zodResolver } from "@hookform/resolvers/zod";
import { endOfMonth, parseISO, startOfMonth } from "date-fns";
import { useMemo } from "react";
import { DateRange } from "react-day-picker";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import AppButton from "../../../../components/AppButton";
import { AppDateRangePicker } from "../../../../components/AppDateRangePicker";
import AppSelectWithDialog from "../../../../components/dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { useAppSelector } from "../../../../redux/store";
import { usePdfReportActions } from "../../../../stores/pdfReportStore/pdfReportStore";
import { trpc } from "../../../../lib/trpc";
import { workOrderTypeColumns } from "./columns/workOrderTypeColumns";
import { workOrderStatusColumns } from "./columns/workOrderStatusColumns";
import AppSelectLocationFilterDialog from "./AppSelectLocationFilterDialog";
import { RouterOutputs } from "@cerev-cmms/trpc";
import { ScrollArea } from "../../../../components/ui/scroll-area";
import { Card } from "../../../../components/ui/card";
import { Separator } from "../../../../components/ui/separator";

type Location = RouterOutputs["location"]["getLocations"][number];

export default function WorkOrderSummaryFilter() {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const {
    setDateRange,
    setWorkOrderTypes,
    setLocations,
    setWorkOrderStatuses,
  } = usePdfReportActions();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    setValue,
    reset,
    watch,
  } = useForm<GetWorkOrderSummaryReportPdfInput>({
    resolver: zodResolver(getWorkOrderSummaryReportPdfSchema),
    defaultValues: {
      projectId: activeProj?.id?.toString() ?? "",
      dateStartISO: startOfMonth(new Date()).toISOString(),
      dateEndISO: endOfMonth(new Date()).toISOString(),
      workOrderTypes: [],
      locations: [],
      workOrderStatuses: [],
    },
  });

  // Get data for filters
  const { data: workOrderTypes } = trpc.workOrders.getWorkOrderTypes.useQuery(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      enabled: !!activeProj?.id,
    }
  );

  const { data: workOrderStatuses } =
    trpc.workOrders.getWorkOrderStatus.useQuery(
      {
        projectId: activeProj?.id ?? 0,
      },
      {
        enabled: !!activeProj?.id,
      }
    );

  const dateStart = useWatch({ control, name: "dateStartISO" });
  const dateEnd = useWatch({ control, name: "dateEndISO" });
  const selectedWorkOrderTypes = useWatch({ control, name: "workOrderTypes" });
  const selectedLocations = useWatch({ control, name: "locations" });
  const selectedStatuses = useWatch({ control, name: "workOrderStatuses" });

  const dateRange = useMemo(() => {
    return {
      from: dateStart ? parseISO(dateStart) : undefined,
      to: dateEnd ? parseISO(dateEnd) : undefined,
    };
  }, [dateStart, dateEnd]);

  const onChange = (dateRange: DateRange | undefined | null) => {
    setValue("dateStartISO", dateRange?.from?.toISOString(), {
      shouldDirty: true,
    });
    setValue("dateEndISO", dateRange?.to?.toISOString(), { shouldDirty: true });
  };

  const onLocationSelect = (location: Location | undefined) => {
    setValue(
      "locations",
      location ? [{ id: location.id, name: location.name ?? "" }] : [],
      { shouldDirty: true }
    );
  };

  const onConfirm: SubmitHandler<GetWorkOrderSummaryReportPdfInput> = (
    data
  ) => {
    setDateRange({
      from: dateStart ? parseISO(dateStart) : undefined,
      to: dateEnd ? parseISO(dateEnd) : undefined,
    });
    setWorkOrderTypes(data.workOrderTypes ?? []);
    setLocations(data.locations ?? []);
    setWorkOrderStatuses(data.workOrderStatuses ?? []);
  };

  const currentLocation = selectedLocations?.[0];

  return (
    <div className="space-y-6 mt-6">
      {/* Date Range Section */}
      <div className="space-y-2">
        <h3 className="text-sm font-medium text-slate-900">Date Range</h3>
        <AppDateRangePicker
          date={dateRange}
          setDate={onChange}
          onClear={() => onChange(undefined)}
          hideOnClear
        />
      </div>

      {/* Work Order Types Section */}
      <div className="space-y-2">
        <h3 className="text-sm font-medium text-slate-900">Work Order Types</h3>
        <AppSelectWithDialog
          multiple
          columns={workOrderTypeColumns}
          placeholder="Select Work Order Types"
          defaultValue={[]}
          control={control}
          name="workOrderTypes"
          items={workOrderTypes ?? []}
          onResultRender={(item) => (
            <div className="font-medium">{item?.name}</div>
          )}
          onOptionsRender={(item) => (
            <div className="font-medium">{item?.name}</div>
          )}
          dialogTitle="Select Work Order Types"
        />
      </div>

      {/* Location Section */}
      <div className="space-y-2">
        <h3 className="text-sm font-medium text-slate-900">Location</h3>
        <AppSelectLocationFilterDialog
          currentFilter={currentLocation}
          onApplyFilter={onLocationSelect}
        />
      </div>

      {/* Work Order Statuses Section */}
      <div className="space-y-2">
        <h3 className="text-sm font-medium text-slate-900">
          Work Order Statuses
        </h3>
        <AppSelectWithDialog
          multiple
          columns={workOrderStatusColumns}
          placeholder="Select Statuses"
          defaultValue={[]}
          control={control}
          name="workOrderStatuses"
          items={workOrderStatuses ?? []}
          onResultRender={(item) => (
            <div className="font-medium">{item?.name}</div>
          )}
          onOptionsRender={(item) => (
            <div className="font-medium">{item?.name}</div>
          )}
          dialogTitle="Select Work Order Statuses"
        />
      </div>

      {/* Update Button */}
      <AppButton
        label="Update"
        onClick={handleSubmit(onConfirm)}
        className="w-full"
      />
    </div>
  );
}
