import { useSlideShowAnalyticsEnabled } from "../hooks/useFeatures";

export default function SlideShowAnalyticGuard({
  children,
}: {
  children: React.ReactNode;
}) {
  const slideShowAnalyticsEnabled = useSlideShowAnalyticsEnabled();
  if (!slideShowAnalyticsEnabled) return <></>;

  return <>{children}</>;
}
