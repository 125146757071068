import { useQueryClient } from "@tanstack/react-query";
import { Pencil, QrCode } from "lucide-react";
import { Link, useSearchParams } from "react-router-dom";
import { useAssetControllerGetOneAsset } from "../../../api/assets/assets";
import useAppTabsNavigation from "../../../hooks/useAppTabsNavigation";
import {
  useEquipmentLccaEnabled,
  useSubEquipmentEnabled,
} from "../../../hooks/useFeatures";
import { useAppSelector } from "../../../redux/store";
import AppButton from "../../AppButton";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import { ASSET_FORM_DRAWER, DRAWER_UPDATE_STATE } from "../AppDrawer";
import AssetDetailTab from "./tabs/AssetDetailTab/AssetDetailTab";
import AssetPmTab from "./tabs/AssetPmTab";
import AssetWorkOrderTab from "./tabs/AssetWorkOrderTab";
import SubEquipmentTab from "./tabs/SubEquipmentTab/SubEquipmentTab";
import AssetLifecycleTab from "./tabs/AssetLifecycleTab/AssetLifecycleTab";
import { trpc } from "../../../lib/trpc";

export default function AssetDetailDrawer() {
  const qc = useQueryClient();
  const [searchParam, setSearchParams] = useSearchParams();
  const tabIdx = searchParam.get("assetTabIdx");
  const assetId = searchParam.get("assetId");
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "assetDetailTabIdx",
    defaultValue: "0",
  });

  const isSubEqEnabled = useSubEquipmentEnabled();
  const isEquipmentLccaEnabled = useEquipmentLccaEnabled();

  const { data: asset, isLoading: assetIsLoading } =
    trpc.assets.getOneAsset.useQuery(
      {
        projectId: activeProj?.id ?? 0,
        assetId: Number(assetId),
      },
      {
        enabled: !!assetId && !!activeProj,
      }
    );

  if (assetIsLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4">
        <div className="flex flex-col">
          <p className="font-sans text-2xl font-bold">
            {asset ? asset.name : "-"}
          </p>
          <p className="font-sans text-neutral-600">
            {asset?.assetType?.name ?? "-"}
          </p>
        </div>
        <AppButton
          variant="outline"
          label="Edit"
          icon={<Pencil />}
          onClick={() => {
            setSearchParams((p) => {
              p.set("drawerType", ASSET_FORM_DRAWER);
              p.set("drawerState", DRAWER_UPDATE_STATE);
              p.set("assetId", assetId ?? "");
              return p;
            });
          }}
        />
        <Link to={`/qr-asset/${assetId}`} target="_blank">
          <AppButton
            variant="outline"
            label="Print QR Code"
            icon={<QrCode />}
          />
        </Link>
      </div>
      <Tabs value={currentTabIdx} onValueChange={onTabChange} defaultValue="0">
        <TabsList>
          <TabsTrigger value="0">
            <p>Detail</p>
          </TabsTrigger>
          {isEquipmentLccaEnabled && (
            <TabsTrigger value="1">
              <p>Life Cycle</p>
            </TabsTrigger>
          )}
          <TabsTrigger value="2">
            <p>Work Order</p>
          </TabsTrigger>
          <TabsTrigger value="3">
            <p>PM</p>
          </TabsTrigger>
          {isSubEqEnabled && (
            <TabsTrigger value="4">
              {asset?.parentAssetId ? (
                <p>Parent Equipment</p>
              ) : (
                <p>Sub Equipment</p>
              )}
            </TabsTrigger>
          )}
        </TabsList>
        <TabsContent value={"0"}>
          <AssetDetailTab asset={asset} />
        </TabsContent>
        {isEquipmentLccaEnabled && (
          <TabsContent value={"1"}>
            <AssetLifecycleTab asset={asset} />
          </TabsContent>
        )}
        <TabsContent value={"2"}>
          <AssetWorkOrderTab asset={asset} />
        </TabsContent>
        <TabsContent value={"3"}>
          <AssetPmTab />
        </TabsContent>
        {isSubEqEnabled && (
          <TabsContent value={"4"}>
            <SubEquipmentTab />
          </TabsContent>
        )}
      </Tabs>
    </div>
  );
}
