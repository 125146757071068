import { useSearchParams } from "react-router-dom";
import { trpc } from "../../../lib/trpc";
import { DataTable } from "../../../components/react-table/DataTable";
import { useReactTable, getCoreRowModel } from "@tanstack/react-table";
import { upcomingPmColumns } from "./columns/upcomingPmColumns";
import { useAppSelector } from "../../../redux/store";
import {
  DRAWER_UPDATE_STATE,
  DRAWER_VIEW_STATE,
  PM_CHECKLIST_DETAIL_DRAWER,
  PM_CHECKLIST_FORM_DRAWER,
} from "../../../components/drawers/AppDrawer";
import { useSelectPmFilterStore } from "../../../stores/pm/pmFilterStore";
import useDebounce from "../../../hooks/useDebounce";
import { RouterInputs } from "@cerev-cmms/trpc";
import { useFormContext, useWatch } from "react-hook-form";

type GetUpcomingPmChecklistInput = RouterInputs["pm"]["getUpcomingPmChecklist"];

export default function UpcomingPmChecklistDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const project = useAppSelector((state) => state.root.activeProject);

  // Get filters from the store
  const dateRange = useSelectPmFilterStore((state) => state.dateRange);
  const assetTypesFilter = useSelectPmFilterStore((state) => state.assetTypes);
  const locationFilter = useSelectPmFilterStore(
    (state) => state.filterLocation
  );

  // Create debounced query arguments
  const queryArg = useDebounce<GetUpcomingPmChecklistInput>({
    value: {
      projectId: project?.id?.toString() ?? "",
      locFilterId: locationFilter?.id?.toString(),
      filterEndDateISO: dateRange?.to?.toISOString(),
      filterStartDateISO: dateRange?.from?.toISOString(),
      assetTypeIds: assetTypesFilter.map((at) => at?.id?.toString() ?? "0"),
    },
    delay: 500,
  });

  const { data: upcomingPmChecklists, isLoading } =
    trpc.pm.getUpcomingPmChecklist.useQuery(
      queryArg,
      {
        enabled: !!project,
      }
    );

  const table = useReactTable({
    data: upcomingPmChecklists?.data ?? [],
    columns: upcomingPmColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-2xl font-bold">Upcoming PM Checklists</h1>
      <DataTable
        table={table}
        isLoading={isLoading}
        totalCount={upcomingPmChecklists?.totalCount ?? 0}
      />
    </div>
  );
}
