import { AssetPPMCompletionRate } from "./AssetPPMCompletionRate";
import { AssetPPMCompletionRateTable } from "../AssetPPMCompletionRateTable/AssetPPMCompletionRateTable";
import { PMApportionmentStatusPieChart } from "./PMApportionmentStatusPieChart";
import { PmStatusCountSection } from "./PmStatusCountSection";
import { PMTrendAreaChart } from "./PMTrendAreaChart";
import { DateFilter } from "../WorkRequestAnalytics/DateFilter";
import { PMUpdatedTrendChart } from "./PMUpdatedTrendChart";
import SelectableChart from "../../../../components/SelectableChart";

export function PMAnalytic() {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="flex items-center justify-between mt-2 col-span-2">
        <DateFilter />
      </div>
      <div className="col-span-2">
        <SelectableChart chartId="pm-apportionment-status-pie-chart">
          <PMApportionmentStatusPieChart />
        </SelectableChart>
      </div>
      <PmStatusCountSection />
      <div className="col-span-2">
        <SelectableChart chartId="pm-trend-area-chart">
          <PMTrendAreaChart />
        </SelectableChart>
      </div>
      <div className="col-span-2">
        <SelectableChart chartId="pm-updated-trend-chart">
          <PMUpdatedTrendChart />
        </SelectableChart>
      </div>
      <div className="col-span-2">
        <AssetPPMCompletionRateTable />
      </div>
    </div>
  );
}
