import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { DataTable } from "@/components/react-table/DataTable";
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Input } from "../ui/input";
import { useState } from "react";
import { DialogDescription } from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { Asset } from "@cerev-cmms/prisma";
import { RouterOutputs } from "@cerev-cmms/trpc";

type PmTemplateAsset =
  RouterOutputs["pm"]["getPmTemplates"]["data"][number]["asset"];
type PmChecklistAsset = RouterOutputs["pm"]["getOnePmChecklist"]["asset"];
type PmChecklistEquipment =
  RouterOutputs["pm"]["getUpcomingPmChecklist"]["data"][number]["multipleAssets"];
interface AssetListDialogProps {
  isOpen: boolean;
  onClose: () => void;
  assets: (Asset | PmTemplateAsset | PmChecklistAsset | PmChecklistEquipment)[];
}

export function AssetListDialog({
  isOpen,
  onClose,
  assets,
}: AssetListDialogProps) {
  const [globalFilter, setGlobalFilter] = useState("");

  const columns: ColumnDef<
    Asset | PmTemplateAsset | PmChecklistAsset | PmChecklistEquipment
  >[] = [
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "code",
      header: "Code",
    },
  ];

  const table = useReactTable({
    data: assets,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    state: {
      globalFilter,
    },
  });

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent
        className="max-w-3xl overflow-y-scroll max-h-screen"
      >
        <DialogHeader>
          <DialogTitle>Equipment List</DialogTitle>
          <VisuallyHidden.Root>
            <DialogDescription>
              A list of equipment under this pm
            </DialogDescription>
          </VisuallyHidden.Root>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <Input
            placeholder="Search assets..."
            value={globalFilter ?? ""}
            onChange={(event) => setGlobalFilter(String(event.target.value))}
          />
          <DataTable
            table={table}
            totalCount={table.getFilteredRowModel().rows.length}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
