import { RouterInputs } from "@cerev-cmms/trpc";
import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { DataTable } from "../../../components/react-table/DataTable";
import useDebounce from "../../../hooks/useDebounce";
import { trpc } from "../../../lib/trpc";
import { DefectStatus } from "../../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../../redux/store";
import { useSelectWorkOrderFilterStore } from "../../../stores/workOrder/workOrderFilterStore";
import {
  useRowSelectionState,
  useWorkOrderIsMultiActionMode,
  useWorkOrderMultiSelectActions,
} from "../../../stores/workOrderMultiActionStore/workOrderMultiActionStore";
import { workOrderColumns } from "./WorkOrderColumns";

type GetWorkOrdersInput = RouterInputs["workOrders"]["getWorkOrders"];
type GetWorkOrderCountInput = RouterInputs["workOrders"]["getWorkOrderCount"];

interface WorkOrderTabProps {
  workOrderStatus: DefectStatus;
}

export default function WorkOrderTab({ workOrderStatus }: WorkOrderTabProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [currentPg, setCurrentPg] = useState(0);
  const methods = useFormContext();
  const search = useWatch({ control: methods.control, name: "search" });
  const locationFilter = useSelectWorkOrderFilterStore(
    (state) => state.filterLocation
  );

  const dateRange = useSelectWorkOrderFilterStore((state) => state.dateRange);
  const assetTypesFilter = useSelectWorkOrderFilterStore(
    (state) => state.assetTypes
  );
  const usersFilter = useSelectWorkOrderFilterStore((state) => state.raisedBy);

  const workOrderTypeFilter = useSelectWorkOrderFilterStore(
    (state) => state.workOrderType
  );

  const workTrades = useSelectWorkOrderFilterStore((state) => state.workTrades);
  const priorities = useSelectWorkOrderFilterStore((state) => state.priorities);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryArg = useDebounce<GetWorkOrdersInput>({
    value: {
      locFilterId: locationFilter?.id.toString() ?? "",
      workOrderStatusId: workOrderStatus.id.toString(),
      projectId: activeProj?.id?.toString() ?? "",
      page: pagination.pageIndex,
      rowsPerPage: pagination.pageSize,
      search,
      filterEndDateISO: dateRange?.to?.toISOString(),
      filterStartDateISO: dateRange?.from?.toISOString(),
      filterAssetTypeIds: assetTypesFilter.map(
        (at) => at?.id?.toString() ?? "0"
      ),
      filterRaisedByIds: usersFilter.map((u) => u?.id?.toString() ?? "0"),
      filterWorkOrderTypeIds: workOrderTypeFilter.map(
        (wo) => wo?.id?.toString() ?? "0"
      ),
      filterWorkTradeIds: workTrades.map((wt) => wt?.id?.toString() ?? "0"),
      filterPriorityIds: priorities.map((p) => p?.id?.toString() ?? "0"),
    },
    delay: 500,
  });

  const queryStatusArg = useDebounce<GetWorkOrderCountInput>({
    value: {
      locFilterId: locationFilter?.id.toString() ?? "",
      projectId: activeProj?.id?.toString() ?? "",
      workStatusId: workOrderStatus.id.toString(),
      filterEndDateISO: dateRange?.to?.toISOString(),
      filterStartDateISO: dateRange?.from?.toISOString(),
      search,
      filterAssetTypeIds: assetTypesFilter.map(
        (at) => at?.id?.toString() ?? "0"
      ),
      filterRaisedByIds: usersFilter.map((u) => u?.id?.toString() ?? "0"),
      filterWorkOrderTypeIds: workOrderTypeFilter.map(
        (wo) => wo?.id?.toString() ?? "0"
      ),
      filterWorkTradeIds: workTrades.map((wt) => wt?.id?.toString() ?? "0"),
      filterPriorityIds: priorities.map((p) => p?.id?.toString() ?? "0"),
    },
    delay: 500,
  });

  const {
    data: workOrders,
    isLoading,
    isFetching,
  } = trpc.workOrders.getWorkOrders.useQuery(queryArg, {
    enabled: !!activeProj,
  });

  const { data: workOrderCount } = trpc.workOrders.getWorkOrderCount.useQuery(
    queryStatusArg,
    {
      enabled: !!activeProj,
    }
  );

  const rowSelection = useRowSelectionState();
  const { setRowSelection } = useWorkOrderMultiSelectActions();

  const linkingMode = useWorkOrderIsMultiActionMode();

  const table = useReactTable({
    data: workOrders ?? [],
    columns: workOrderColumns,
    manualPagination: true,
    getRowId: (row) => row.id?.toString() ?? "-",
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: workOrderCount
      ? Math.ceil(+workOrderCount / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
        select: linkingMode,
        equipmentName: false,
      },
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
  });

  // reset table pagination if filters have changed
  useEffect(() => {
    table.resetPagination();
  }, [
    search,
    dateRange,
    assetTypesFilter,
    usersFilter,
    locationFilter,
    workTrades,
    priorities,
  ]);

  return (
    <DataTable
      isLoading={isLoading}
      isFetching={isFetching}
      table={table}
      totalCount={workOrderCount ? +workOrderCount : 0}
      rowsPerPage={pagination.pageSize}
      pageIdx={pagination.pageIndex}
    />
  );
}
