import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { proxy, wrap } from "comlink";
import type { WorkerType } from "./workers/work-request-summary-pdf.worker";
import Worker from "./workers/work-request-summary-pdf.worker?worker";
import useDate from "../../hooks/useDate";
import { Font } from "@react-pdf/renderer";

Font.register({
  family: "Fira Sans",
  fonts: [
    {
      src: "https://storage.googleapis.com/cerev-dev.appspot.com/public-fonts/FiraSans-Regular.ttf",
      fontStyle: "normal",
      fontWeight: "normal",
    },
    {
      src: "https://storage.googleapis.com/cerev-dev.appspot.com/public-fonts/FiraSans-Bold.ttf",
      fontStyle: "normal",
      fontWeight: "bold",
    },
  ],
});

export const workRequestSummaryPdfWorker = wrap<WorkerType>(new Worker());
workRequestSummaryPdfWorker.onProgress(proxy((info: any) => console.log(info)));

export const useRenderWorkRequestSummaryPdf = ({
  workRequests,
  generatedDateStr,
}: Parameters<WorkerType["renderWorkRequestSummaryPdfInWorker"]>[0]) => {
  const { formatDateToLocal } = useDate();

  const {
    data: url,
    isFetching,
    error,
  } = useQuery({
    queryKey: ["workRequestSummaryPdf", workRequests.length],
    queryFn: async () =>
      await workRequestSummaryPdfWorker.renderWorkRequestSummaryPdfInWorker({
        workRequests,
        generatedDateStr: generatedDateStr,
      }),
    enabled: !!workRequests.length,
  });

  useEffect(() => (url ? () => URL.revokeObjectURL(url) : undefined), [url]);

  return {
    url,
    loading: isFetching,
    error,
  };
};
