import { FormProvider, useForm } from "react-hook-form";
import debounce from 'lodash/debounce';
import { useCallback, useEffect } from 'react';
import { trpc } from "../../../../lib/trpc";
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { AppDateTimePickerForm } from "../../../datetimePicker/AppDateTimePickerForm";
import { useQueryClient } from "@tanstack/react-query";

const TIMEZONE = 'Asia/Kuala_Lumpur';
const DEBOUNCE_DELAY = 500;

interface PlannedDateFormValues {
  plannedDate: Date | undefined;
}

interface PlannedDateFieldProps {
  disabled?: boolean;
  pmChecklistId: string;
}

export function PlannedDateField({ 
  disabled,
  pmChecklistId
}: PlannedDateFieldProps) {
  const utils = trpc.useUtils();
  const qc = useQueryClient();
  const methods = useForm<PlannedDateFormValues>();

  // Fetch PM checklist data
  const { data: pmChecklist } = trpc.pm.getOnePmChecklist.useQuery(
    { 
      pmChecklistId: +pmChecklistId,
    },
    { 
      enabled: !!pmChecklistId 
    }
  );

  // Update form when PM checklist data changes
  useEffect(() => {
    if (pmChecklist?.plannedOn) {
      const plannedDateInKL = utcToZonedTime(pmChecklist.plannedOn, TIMEZONE);
      methods.setValue('plannedDate', plannedDateInKL);
    } else {
      methods.setValue('plannedDate', undefined);
    }
  }, [pmChecklist?.plannedOn, methods]);

  const { mutate: updatePlannedDate } = trpc.pm.updatePlannedDate.useMutation({
    onSuccess: () => {
      // Invalidate relevant queries
      utils.pm.invalidate();
      qc.invalidateQueries({
        predicate: (q) => (q.queryKey[0] as string)?.includes("pm-checklist"),
      });
    },
  });

  // Create a debounced update function
  const debouncedUpdate = useCallback(
    debounce((date: Date | null) => {
      if (!pmChecklistId) return;

      const utcDate = date ? zonedTimeToUtc(date, TIMEZONE) : null;
      updatePlannedDate({
        pmChecklistId,
        plannedDateISO: utcDate?.toISOString() ?? null,
      });
    }, DEBOUNCE_DELAY),
    [pmChecklistId, updatePlannedDate]
  );

  const handleDateChange = (date: Date | null) => {
    // Update form state immediately
    methods.setValue('plannedDate', date || undefined);
    
    // Debounce the API call
    debouncedUpdate(date);
  };

  return (
    <FormProvider {...methods}>
      <AppDateTimePickerForm
        name="plannedDate"
        showTime={false}
        onChange={handleDateChange}
      />
    </FormProvider>
  );
} 