import { ColumnDef } from "@tanstack/react-table";
import { RouterOutputs } from "@cerev-cmms/trpc";
import { Badge } from "../../../../ui/badge";
import { cn } from "../../../../../lib/utils";
import AppFullLocation from "../../../../AppFullLocation";
import useDate from "../../../../../hooks/useDate";
import { isAfter } from "date-fns";

type PmChecklist = RouterOutputs["assets"]["getAssetPm"][number];

// Helper function to determine if text should be white or black based on background color
function getContrastTextColor(hexColor: string | null | undefined): string {
  if (!hexColor) return "text-foreground";

  // Convert hex to RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Calculate relative luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return white for dark backgrounds, black for light backgrounds
  return luminance > 0.4 ? "text-neutral-900" : "text-white";
}

export const assetPmColumns: ColumnDef<PmChecklist>[] = [
  {
    accessorKey: "pmTemplate.name",
    header: "Checklist",
    cell: ({ row }) => (
      <div className="font-medium text-primary-900 underline hover:cursor-pointer">
        {row.original.pmTemplate.name}
      </div>
    ),
  },
  {
    accessorKey: "refNo",
    header: "Reference No.",
    cell: ({ row }) => <div>{row.original.refNo ?? "-"}</div>,
  },
  {
    accessorKey: "location",
    header: "Location",
    cell: ({ row }) =>
      row.original.pmTemplate.pmTemplateType === "ONE_EQUIPMENT" ? (
        <AppFullLocation
          locationId={row.original.asset?.locationId?.toString()}
        />
      ) : (
        "-"
      ),
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const { pmStatus, plannedOn } = row.original;
      
      // Check if it's an upcoming PM
      const isUpcoming = plannedOn && isAfter(new Date(plannedOn), new Date());
      
      if (isUpcoming) {
        return (
          <Badge
            className={cn(
              "flex items-center gap-1 w-fit border-slate-300",
              "text-neutral-900"
            )}
            style={{
              backgroundColor: "#E2E8F0", // slate-200 color
            }}
          >
            <span>Upcoming</span>
          </Badge>
        );
      }

      const textColor = getContrastTextColor(pmStatus.colorHex);

      return (
        <Badge
          className={cn("flex items-center gap-1 w-fit border-slate-300", textColor)}
          style={{
            backgroundColor: `#${pmStatus.colorHex}`,
          }}
        >
          <span>{pmStatus.name}</span>
        </Badge>
      );
    },
  },
  {
    accessorKey: "completedBy",
    header: "Completed By",
    cell: ({ row }) => <div>{row.original.updatedBy?.name ?? "-"}</div>,
  },
  {
    accessorKey: "completedOn",
    header: "Completed On",
    cell: ({ row }) => {
      const { formatDateToLocal } = useDate();
      return (
        <div>
          {row.original.completedOn
            ? formatDateToLocal({
                dateStr: row.original.completedOn.toISOString(),
              })
            : "-"}
        </div>
      );
    },
  },
];
