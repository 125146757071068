import { Trash2 } from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "../../../ui/use-toast";
import { trpc } from "../../../../lib/trpc";
import AppButton from "../../../AppButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../ui/dialog";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function DeletePmChecklistButton({ pmChecklistId }: { pmChecklistId: number }) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { toast } = useToast();
  const qc = useQueryClient();

  const { mutate: deletePmChecklist, isPending } = trpc.pm.deletePmChecklist.useMutation({
    onSuccess: () => {
      toast({
        title: "Success",
        description: "PM Checklist deleted successfully",
      });
      qc.invalidateQueries({
        predicate: (query) => {
          return (query.queryKey[0] as string).includes("pm");
        },
      });
      // Clear the drawer params to close it
      setSearchParams(new URLSearchParams());
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    },
  });

  const handleDelete = () => {
    deletePmChecklist({ pmChecklistId });
  };

  return (
    <>
      <AppButton
        variant="ghost"
        className="text-red-500 hover:text-red-700 hover:bg-red-50"
        label="Delete"
        icon={<Trash2 className="h-4 w-4" />}
        onClick={() => setIsOpen(true)}
      />

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete PM Checklist</DialogTitle>
          </DialogHeader>
          
          <div className="py-4">
            <p className="text-sm text-gray-500">
              Are you sure you want to delete this PM checklist? This action cannot be undone.
            </p>
          </div>

          <DialogFooter>
            <AppButton
              type="button"
              variant="outline"
              label="Cancel"
              onClick={() => setIsOpen(false)}
            />
            <AppButton
              type="button"
              variant="destructive"
              className="text-white hover:text-red-700 hover:bg-red-50"
              label="Delete"
              isLoading={isPending}
              onClick={handleDelete}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
} 