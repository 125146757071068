import { Separator } from "../ui/separator";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_VIEW_STATE,
  PM_CHECKLIST_FORM_DRAWER,
  PM_TEMPLATE_DRAWER,
} from "../drawers/AppDrawer";
import { RouterOutputs } from "@cerev-cmms/trpc";
import { FrequencySettingType, getFrequencyDescription } from "./FrequencyDescription";
import { Clock } from "lucide-react";

type PmTemplate = RouterOutputs["pm"]["getPmTemplates"]["data"][number];

interface PmTemplateItemProps {
  template: PmTemplate;
  isLast?: boolean;
}

export default function PmTemplateItem({ template, isLast }: PmTemplateItemProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTemplateClick = (templateId: number) => {
    setSearchParams((p) => {
      p.set("drawerType", PM_TEMPLATE_DRAWER);
      p.set("drawerState", DRAWER_VIEW_STATE);
      p.set("pmTemplateId", templateId.toString());
      return p;
    });
  };

  const handleGenerateClick = (templateId: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setSearchParams((p) => {
      p.set("drawerType", PM_CHECKLIST_FORM_DRAWER);
      p.set("drawerState", DRAWER_VIEW_STATE);
      p.set("pmTemplateId", templateId.toString());
      return p;
    });
  };

  return (
    <div>
      <div
        className="py-3 px-4 hover:bg-gray-50 transition-colors cursor-pointer space-y-3"
        onClick={() => handleTemplateClick(template.id)}
      >
        <div className="flex justify-between items-start gap-2">
          <div className="flex-1 space-y-2">
            <div className="text-sm font-medium text-primary-900 hover:underline">
              {template.name}
            </div>
            <div className="space-y-1">
              <div className="text-xs text-gray-500 flex items-center gap-2">
                <Clock className="w-3 h-3" />
                <span>
                  {getFrequencyDescription({
                    frequencySetting: template.frequencySetting?.frequencySetting as FrequencySettingType,
                    weeklyRepeatDay: template.frequencySetting?.weeklyRepeatDay,
                    monthlyEveryStepCount: template.frequencySetting?.monthlyEveryStepCount,
                    monthlyRepeatDate: template.frequencySetting?.monthlyRepeatDate,
                    yearlyEveryStepCount: template.frequencySetting?.yearlyEveryStepCount,
                    yearlyRepeatDate: template.frequencySetting?.yearlyRepeatDate,
                    yearlyRepeatMonth: template.frequencySetting?.yearlyRepeatMonth,
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isLast && <Separator className="my-0" />}
    </div>
  );
} 