import { Loader2 } from "lucide-react";
import useDate from "../../../../hooks/useDate";
import { trpc } from "../../../../lib/trpc";
import { useRenderWorkOrderSummaryPdf } from "../../../../pdf/render-optimization/useRenderWorkOrderSummaryPdf";
import { useAppSelector } from "../../../../redux/store";
import { usePdfReportDateRange, usePdfReportWorkOrderStatuses, usePdfReportLocations, usePdfReportWorkOrderTypes } from "../../../../stores/pdfReportStore/pdfReportStore";
import AppCenterEmpty from "../../../../components/AppCenterEmpty";

export default function WorkOrderPdfReportViewer() {
  const { formatDateToLocal } = useDate();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const dateRange = usePdfReportDateRange();
  const locations = usePdfReportLocations();
  const workOrderTypes = usePdfReportWorkOrderTypes();
  const workOrderStatuses = usePdfReportWorkOrderStatuses();

  const { data: workOrders, isFetching: isWorkOrdersFetching } = trpc.reports.getWorkOrderSummaryPdf.useQuery(
    {
      projectId: activeProj?.id?.toString() ?? "",
      dateStartISO: dateRange?.from?.toISOString(),
      dateEndISO: dateRange?.to?.toISOString(),
      locations: locations,
      workOrderTypes: workOrderTypes,
      workOrderStatuses: workOrderStatuses,
    },
    {
      enabled: !!activeProj?.id && !!dateRange,
    }
  );

  const {
    url,
    loading: pdfLoading,
    error: pdfError,
  } = useRenderWorkOrderSummaryPdf({
    workOrders: workOrders ?? [],
    generatedDateStr: formatDateToLocal({
      dateStr: new Date().toISOString(),
      formatting: "d MMM yyyy, h:m a",
    }),
  });

  const src = url ? `${url}#toolbar=1` : null;

  if (isWorkOrdersFetching || pdfLoading)
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-150px)]">
        <div className="flex flex-col items-center justify-center gap-4">
          <Loader2 className="text-primary-900 animate-spin w-12 h-12" />
          <p className="text-slate-900 font-bold">Generating your PDF...</p>
          <p className="text-slate-500 text-sm">
            This process may take up to 5 - 10 mins depending on size of data...
          </p>
        </div>
      </div>
    );

  if (!workOrders?.length) {
    return (
      <div className="h-[calc(100vh-150px)] flex items-center justify-center">
        <AppCenterEmpty />
      </div>
    );
  }

  return (
    <iframe
      className="h-[calc(100vh-150px)]"
      src={src ?? undefined}
      style={{
        width: "100%",
        height: "calc(100vh-150px)",
        borderRadius: "10px",
      }}
    />
  );
}
