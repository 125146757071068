import { FormProvider, useForm } from "react-hook-form";
import debounce from 'lodash/debounce';
import { useCallback, useEffect } from 'react';
import { useAppSelector } from "../../../../../../redux/store";
import { trpc } from "../../../../../../lib/trpc";
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { AppDateTimePickerForm } from "../../../../../datetimePicker/AppDateTimePickerForm";
import { useQueryClient } from "@tanstack/react-query";

const TIMEZONE = 'Asia/Kuala_Lumpur';
const DEBOUNCE_DELAY = 500;

interface PlannedDateFormValues {
  plannedDate: Date | undefined;
}

interface PlannedDateFieldProps {
  disabled?: boolean;
  workOrderId: string;
}

export function PlannedDateField({ 
  disabled,
  workOrderId
}: PlannedDateFieldProps) {
  const utils = trpc.useUtils();
  const qc = useQueryClient();
  const methods = useForm<PlannedDateFormValues>();

  // Fetch work order data
  const { data: workOrder } = trpc.workOrders.getOneWorkOrder.useQuery(
    { 
      workOrderId: +workOrderId,
    },
    { 
      enabled: !!workOrderId 
    }
  );

  // Update form when work order data changes
  useEffect(() => {
    if (workOrder?.plannedOn) {
      const plannedDateInKL = utcToZonedTime(workOrder.plannedOn, TIMEZONE);
      methods.setValue('plannedDate', plannedDateInKL);
    } else {
      methods.setValue('plannedDate', undefined);
    }
  }, [workOrder?.plannedOn, methods]);

  const { mutate: updatePlannedDate } = trpc.workOrders.updatePlannedDate.useMutation({
    onSuccess: () => {
      // Invalidate relevant queries
      utils.workOrders.invalidate();
      qc.invalidateQueries({
        predicate: (q) => (q.queryKey[0] as string)?.includes("defect") || (q.queryKey[0] as string)?.includes("work-order"),
      });
    },
  });

  // Create a debounced update function
  const debouncedUpdate = useCallback(
    debounce((date: Date | null) => {
      if (!workOrderId) return;

      const utcDate = date ? zonedTimeToUtc(date, TIMEZONE) : null;
      updatePlannedDate({
        workOrderId,
        plannedDateISO: utcDate?.toISOString() ?? null,
      });
    }, DEBOUNCE_DELAY),
    [workOrderId, updatePlannedDate]
  );

  const handleDateChange = (date: Date | null) => {
    // Update form state immediately
    methods.setValue('plannedDate', date || undefined);
    
    // Debounce the API call
    debouncedUpdate(date);
  };

  return (
    <FormProvider {...methods}>
        <AppDateTimePickerForm
          name="plannedDate"
          showTime={false}
          onChange={handleDateChange}
        />
    </FormProvider>
  );
} 