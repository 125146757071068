import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { format } from "date-fns";
import { useFormContext } from "react-hook-form";
import { Input } from "../ui/input";

interface DateTimePickerFormProps {
  name: string;
  label?: string;
  showTime?: boolean;
  onChange?: (date: Date | null) => void;
}

export function AppDateTimePickerForm({
  name,
  label,
  showTime = true,
  onChange,
}: DateTimePickerFormProps) {
  const form = useFormContext();

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          {label && <FormLabel className="text-left">{label}</FormLabel>}
          <FormControl>
            <Input
              type={showTime ? "datetime-local" : "date"}
              className="ring-primary-900 flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
              value={field.value ? format(field.value, showTime ? "yyyy-MM-dd'T'HH:mm" : "yyyy-MM-dd") : ''}
              onChange={(e) => {
                const date = e.target.value ? new Date(e.target.value) : null;
                field.onChange(date);
                onChange?.(date);
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
