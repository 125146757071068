import SelectableChart from "../../../../components/SelectableChart";
import { useAppSelector } from "../../../../redux/store";
import { ContractMetrics } from "./ContractMetrics";
import { ContractPieChart } from "./ContractPieChart";
import { ListOfExpiredContracts } from "./ListOfExpiredContracts/ListOfExpiredContracts";
import { ListOfFmContracts } from "./ListOfFmContracts/ListOfFmContracts";

export default function LegalComplianceAnalytics() {
  const activeProject = useAppSelector((state) => state.root.activeProject);
  return (
    <div className="space-y-4">
      <h2 className="text-xl font-bold">Legal Compliance Overview</h2>
      <ContractMetrics />
      <SelectableChart chartId="contract-pie-chart">
        <ContractPieChart />
      </SelectableChart>
      <ListOfExpiredContracts />
      {activeProject?.code === "OS" && <ListOfFmContracts />}
    </div>
  );
}
