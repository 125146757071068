import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useDefectStatusControllerGetDefectStatus } from "../../api/defect-status/defect-status";
import AppButton from "../../components/AppButton";
import {
  WORK_ORDER_FILTER_DRAWER,
  WORK_ORDER_FORM_DRAWER,
} from "../../components/drawers/AppDrawer";
import AppSearchAndFilter from "../../components/searchAndFilter/AppSearchAndFilter";

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import useAppTabsNavigation from "../../hooks/useAppTabsNavigation";
import { useAppSelector } from "../../redux/store";
import { useSelectWorkOrderFilterStore } from "../../stores/workOrder/workOrderFilterStore";
import AppTitle from "../HomeScreen/components/AppTitle";
import WorkOrderTabWithCount from "./components/WorkOrderTabWithCount";
import WorkOrderTab from "./tabs/WorkOrderTab";
import MoreActions from "./components/MoreActions";
import MultiSelectBottomBar from "./components/MultiSelectBottomBar/MultiSelectBottomBar";
import { CerevModule } from "../../api/model";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
import WorkOrderScreenMenu from "./components/WorkOrderScreenMenu";
import { useSchedulerEnabled } from "../../hooks/useFeatures";

export default function WorkOrderScreen() {
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });
  const [, setSearchParams] = useSearchParams();
  // const [value, setValue] = useState(0);
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "workRequestTabIdx",
    defaultValue: "0",
  });

  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const { data: workStatuses } = useDefectStatusControllerGetDefectStatus(
    {
      companyId: activeComp?.id.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeComp,
      },
    }
  );

  const filterCount = useSelectWorkOrderFilterStore(
    (state) => state.getFilterCount
  );

  // clear filter and sort params when unmount
  const cleanUp = useSelectWorkOrderFilterStore((state) => state.cleanUp);
  const schedulerEnabled = useSchedulerEnabled();

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  // At this moment, is not worth the effort and time to make this a link
  // based tabs, to explore this in the future.
  return (
    <ModuleAccessGuard module={CerevModule.WORK_ORDER}>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-center mb-3">
          <div className="flex items-center gap-4">
            <AppTitle title="Work Order" className="mb-0" />
            <AppButton
              label="+ Create Work Order"
              onClick={() => {
                setSearchParams((p) => {
                  p.set("drawerType", WORK_ORDER_FORM_DRAWER);
                  p.set("drawerState", "CREATE");
                  return p;
                });
              }}
            />
          </div>
          {schedulerEnabled && <WorkOrderScreenMenu />}
        </div>
        <FormProvider {...methods}>
          <div className="flex gap-4">
            <AppSearchAndFilter
              onFilterClick={() => {
                setSearchParams((p) => {
                  p.set("drawerType", WORK_ORDER_FILTER_DRAWER);
                  return p;
                });
              }}
              placeholder="Ref No, Subject, Asset..."
              filterCount={filterCount()}
            />
            <MoreActions />
          </div>
          <Tabs
            value={currentTabIdx}
            onValueChange={onTabChange}
            className="mb-4"
          >
            <TabsList className="mb-2">
              {workStatuses?.data?.map((woStatus, idx) => (
                <TabsTrigger key={woStatus.id} value={idx.toString()}>
                  <WorkOrderTabWithCount workOrderStatus={woStatus} />
                </TabsTrigger>
              ))}
            </TabsList>
            {workStatuses?.data?.map((woStat, idx) => {
              return (
                <TabsContent key={woStat.id} value={idx.toString()}>
                  <WorkOrderTab workOrderStatus={woStat} />
                </TabsContent>
              );
            })}
          </Tabs>
        </FormProvider>
        <MultiSelectBottomBar />
      </div>
    </ModuleAccessGuard>
  );
}
