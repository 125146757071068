import { Link } from "react-router-dom";
import { FileStack, ChevronRight } from "lucide-react";
import {
  SidebarMenuItem,
  SidebarMenuButton,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  useSidebar,
} from "../ui/sidebar";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

interface WorkOrderMenuItemProps {
  isActive: (path: string) => boolean;
  schedulerEnabled?: boolean;
}

export function WorkOrderMenuItem({
  isActive,
  schedulerEnabled = false,
}: WorkOrderMenuItemProps) {
  const { state, isMobile } = useSidebar();
  const isCollapsed = state === "collapsed";

  // If scheduler is not enabled, show simple menu button
  if (!schedulerEnabled) {
    return (
      <SidebarMenuItem>
        <Link to="/home/work-orders">
          <SidebarMenuButton
            asChild
            isActive={isActive("/home/work-orders")}
            tooltip={isCollapsed && !isMobile ? "Work Order" : undefined}
          >
            <div className="flex items-center gap-2">
              <FileStack className="size-4" />
              <span>Work Order</span>
            </div>
          </SidebarMenuButton>
        </Link>
      </SidebarMenuItem>
    );
  }

  // Collapsed state with scheduler enabled
  if (isCollapsed && !isMobile) {
    return (
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              className="h-8 w-8"
              isActive={
                isActive("/home/work-orders") || isActive("/home/wo-scheduler")
              }
              tooltip="Work Order"
            >
              <FileStack className="size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            side="right"
            align="start"
            className="w-56"
            onCloseAutoFocus={(e) => e.preventDefault()}
          >
            <Link to="/home/work-orders">
              <DropdownMenuItem className="cursor-pointer">
                Work Order List
              </DropdownMenuItem>
            </Link>
            <Link to="/home/wo-scheduler">
              <DropdownMenuItem className="cursor-pointer">
                WO Scheduler
              </DropdownMenuItem>
            </Link>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    );
  }

  // Expanded state with scheduler enabled
  return (
    <SidebarMenuItem>
      <Collapsible defaultOpen className="group/collapsible w-full">
        <CollapsibleTrigger className="w-full">
          <SidebarMenuItem className="group/label text-sm text-sidebar-foreground hover:bg-sidebar-accent hover:text-sidebar-accent-foreground flex w-full items-center">
            <SidebarMenuButton asChild>
              <div className="flex items-center gap-2">
                <FileStack className="size-4" />
                <span>Work Order</span>
              </div>
            </SidebarMenuButton>
            {!isCollapsed && (
              <ChevronRight className="ml-auto size-4 shrink-0 transition-transform group-data-[state=open]/collapsible:rotate-90" />
            )}
          </SidebarMenuItem>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <SidebarMenuSub>
            <SidebarMenuSubItem>
              <Link to="/home/work-orders">
                <SidebarMenuSubButton
                  asChild
                  isActive={isActive("/home/work-orders")}
                >
                  <span>WO List</span>
                </SidebarMenuSubButton>
              </Link>
            </SidebarMenuSubItem>
            <SidebarMenuSubItem>
              <Link to="/home/wo-scheduler">
                <SidebarMenuSubButton
                  asChild
                  isActive={isActive("/home/wo-scheduler")}
                >
                  <span>WO Scheduler</span>
                </SidebarMenuSubButton>
              </Link>
            </SidebarMenuSubItem>
          </SidebarMenuSub>
        </CollapsibleContent>
      </Collapsible>
    </SidebarMenuItem>
  );
}
