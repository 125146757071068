import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ScrollArea } from "../../components/ui/scroll-area";
import AppDialog from "../../components/dialogs/AppDialog";
import AppDrawer from "../../components/drawers/AppDrawer";
import GlobalSnackbar from "../../components/snackbar/GlobalSnackbar";
import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
} from "../../components/ui/sidebar";
import { CerevSidebar } from "./components/CerevSidebar/CerevSidebar";
import { Separator } from "../../components/ui/separator";
import PmSchedulerSidebar from "../PmSchedulerScreen/components/PmSchedulerSidebar";
import WOSchedulerSidebar from "../WOSchedulerScreen/components/WOSchedulerSidebar";
import { cn } from "../../utils/util";

export default function HomeScreen() {
  const location = useLocation();
  const navigate = useNavigate();

  // To have indexed route when first navigating into home.
  useEffect(() => {
    if (location.pathname === "/home") navigate("/home/dashboard");
  }, [location]);

  const isPmSchedulerScreen = location.pathname.includes("/home/scheduler-pm");
  const isWOSchedulerScreen = location.pathname.includes("/home/wo-scheduler");

  return (
    <SidebarProvider defaultOpen={true}>
      <CerevSidebar />
      <SidebarInset
        className={cn("p-5", {
          "pr-[416px]": isWOSchedulerScreen,
          "pr-[274px]": isPmSchedulerScreen,
        })}
      >
        <ScrollArea className="min-h-screen">
          <div className="flex pb-4  items-center gap-2 transition-[width,height] ease-linear">
            <SidebarTrigger className="ml-1 text-slate-400" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <p className="text-muted-foreground text-sm text-slate-400">
              Cerev CMMS
            </p>
          </div>
          <div className="px-1 py-1">
            <Outlet />
          </div>
          <GlobalSnackbar />
        </ScrollArea>
      </SidebarInset>
      {isPmSchedulerScreen && <PmSchedulerSidebar />}
      {isWOSchedulerScreen && <WOSchedulerSidebar />}
      <AppDialog />
      <AppDrawer />
    </SidebarProvider>
  );
}
