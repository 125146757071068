import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/store";
import AppCenterEmpty from "../../../AppCenterEmpty";
import DrawerFormSkeleton from "../../../skeletons/DrawerFormSkeleton";
import PmChecklistItem from "./components/PmChecklistItem";
import { trpc } from "../../../../lib/trpc";

export default function UpcomingPmDetailTab() {
  const [searchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const locationId = searchParams.get("locationId");
  const assetId = searchParams.get("assetId");

  const { data: pmChecklists, isLoading: pmChecklistsIsLoading } =
    trpc.pm.getUpcomingPmChecklist.useQuery(
      {
        projectId: activeProj?.id?.toString() ?? "",
        ...(locationId && { locFilterId: locationId }),
        ...(assetId && { assetId }),
      },
      {
        enabled: !!activeProj,
      }
    );

  if (pmChecklistsIsLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-3">
      {pmChecklists?.totalCount === 0 && <AppCenterEmpty />}
      {pmChecklists?.data?.map((pmChecklist) => (
        <PmChecklistItem pmChecklist={pmChecklist} key={pmChecklist.id} />
      ))}
    </div>
  );
} 