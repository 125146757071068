import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { proxy, wrap } from "comlink";
import type { WorkerType } from "./workers/work-order-summary-pdf.worker";
import Worker from "./workers/work-order-summary-pdf.worker?worker";
import useDate from "../../hooks/useDate";

export const workOrderSummaryPdfWorker = wrap<WorkerType>(new Worker());
workOrderSummaryPdfWorker.onProgress(proxy((info: any) => console.log(info)));

export const useRenderWorkOrderSummaryPdf = ({
  workOrders,
  generatedDateStr,
}: Parameters<WorkerType["renderWorkOrderSummaryPdfInWorker"]>[0]) => {
  const { formatDateToLocal } = useDate();

  const {
    data: url,
    isFetching,
    error,
  } = useQuery({
    queryKey: ["workOrderSummaryPdf", workOrders.length],
    queryFn: async () =>
      await workOrderSummaryPdfWorker.renderWorkOrderSummaryPdfInWorker({
        workOrders,
        generatedDateStr: generatedDateStr,
      }),
    enabled: !!workOrders.length,
  });

  useEffect(() => (url ? () => URL.revokeObjectURL(url) : undefined), [url]);

  return {
    url,
    loading: isFetching,
    error,
  };
};
