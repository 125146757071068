import { RouterOutputs } from "@cerev-cmms/trpc";
import {
    GetWorkRequestSummaryReportPdfInput,
    getWorkRequestSummaryReportPdfSchema,
} from "@cerev-cmms/zod-types";
import { zodResolver } from "@hookform/resolvers/zod";
import { endOfMonth, parseISO, startOfMonth } from "date-fns";
import { useMemo } from "react";
import { DateRange } from "react-day-picker";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import AppButton from "../../../../components/AppButton";
import { AppDateRangePicker } from "../../../../components/AppDateRangePicker";
import AppSelectWithDialog from "../../../../components/dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { useAppSelector } from "../../../../redux/store";
import { usePdfReportActions } from "../../../../stores/pdfReportStore/pdfReportStore";
import AppSelectLocationFilterDialog from "./AppSelectLocationFilterDialog";
import { workRequestStatusColumns } from "./columns/workRequestStatusColumns";

type Location = RouterOutputs["location"]["getLocations"][number];

export default function WorkRequestSummaryFilter() {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { setDateRange, setLocations, setWorkRequestStatuses } =
    usePdfReportActions();

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
    setValue,
    reset,
    watch,
  } = useForm<GetWorkRequestSummaryReportPdfInput>({
    resolver: zodResolver(getWorkRequestSummaryReportPdfSchema),
    defaultValues: {
      projectId: activeProj?.id?.toString() ?? "",
      dateStartISO: startOfMonth(new Date()).toISOString(),
      dateEndISO: endOfMonth(new Date()).toISOString(),
      locations: [],
      workRequestStatuses: [],
    },
  });

  const dateStart = useWatch({ control, name: "dateStartISO" });
  const dateEnd = useWatch({ control, name: "dateEndISO" });
  const selectedLocations = useWatch({ control, name: "locations" });
  const selectedStatuses = useWatch({ control, name: "workRequestStatuses" });

  const dateRange = useMemo(() => {
    return {
      from: dateStart ? parseISO(dateStart) : undefined,
      to: dateEnd ? parseISO(dateEnd) : undefined,
    };
  }, [dateStart, dateEnd]);

  const onChange = (dateRange: DateRange | undefined | null) => {
    setValue("dateStartISO", dateRange?.from?.toISOString(), {
      shouldDirty: true,
    });
    setValue("dateEndISO", dateRange?.to?.toISOString(), { shouldDirty: true });
  };

  const onLocationSelect = (location: Location | undefined) => {
    setValue(
      "locations",
      location ? [{ id: location.id, name: location.name ?? "" }] : [],
      { shouldDirty: true }
    );
  };

  const onConfirm: SubmitHandler<GetWorkRequestSummaryReportPdfInput> = (
    data
  ) => {
    setDateRange({
      from: dateStart ? parseISO(dateStart) : undefined,
      to: dateEnd ? parseISO(dateEnd) : undefined,
    });
    setLocations(data.locations ?? []);
    setWorkRequestStatuses(data.workRequestStatuses ?? []);
  };

  const currentLocation = selectedLocations?.[0];

  const workRequestStatuses = [
    { id: 0, name: "NEW" },
    { id: 1, name: "IN_PROGRESS" },
    { id: 2, name: "COMPLETED" },
    { id: 3, name: "CANCELLED" },
  ];

  return (
    <div className="space-y-6 mt-6">
      {/* Date Range Section */}
      <div className="space-y-2">
        <h3 className="text-sm font-medium text-slate-900">Date Range</h3>
        <AppDateRangePicker
          date={dateRange}
          setDate={onChange}
          onClear={() => onChange(undefined)}
          hideOnClear
        />
      </div>

      {/* Location Section */}
      <div className="space-y-2">
        <h3 className="text-sm font-medium text-slate-900">Location</h3>
        <AppSelectLocationFilterDialog
          currentFilter={currentLocation}
          onApplyFilter={onLocationSelect}
        />
      </div>

      {/* Work Request Statuses Section */}
      <div className="space-y-2">
        <h3 className="text-sm font-medium text-slate-900">
          Work Request Statuses
        </h3>
        <AppSelectWithDialog
          multiple
          columns={workRequestStatusColumns}
          placeholder="Select Statuses"
          defaultValue={[]}
          control={control}
          name="workRequestStatuses"
          items={workRequestStatuses}
          onResultRender={(item) => (
            <div className="font-medium">{item?.name}</div>
          )}
          onOptionsRender={(item) => (
            <div className="font-medium">{item?.name}</div>
          )}
          dialogTitle="Select Work Request Statuses"
        />
      </div>

      {/* Update Button */}
      <AppButton
        label="Update"
        onClick={handleSubmit(onConfirm)}
        className="w-full"
      />
    </div>
  );
}
