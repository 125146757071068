import { FormProvider, useForm, useWatch } from "react-hook-form";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
} from "../../../components/ui/sidebar";
import { ScrollArea, ScrollBar } from "../../../components/ui/scroll-area";
import { useAppSelector } from "../../../redux/store";
import { trpc } from "../../../lib/trpc";
import AppTextField from "../../../components/AppTextField";
import { Inbox, Search, X } from "lucide-react";
import useDebounce from "../../../hooks/useDebounce";
import PmTemplateItem from "../../../components/pm/PmTemplateItem";

export default function PmSchedulerSidebar() {
  const activeProject = useAppSelector((state) => state.root.activeProject);
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });

  const search = useWatch({ control: methods.control, name: "search" });
  const debouncedQuery = useDebounce({
    value: {
      projectId: activeProject?.id ?? 0,
      search,
      page: 0,
      rowsPerPage: 50,
    },
    delay: 500,
  });

  const { data: pmTemplateData, isLoading } = trpc.pm.getPmTemplates.useQuery(
    debouncedQuery,
    {
      enabled: !!activeProject,
    }
  );

  return (
    <Sidebar
      side="right"
      className="bg-white border-l h-screen top-0 right-0 z-10 fixed"
      collapsible="none"
    >
      <SidebarHeader className="border-b p-4 bg-white">
        <h2 className="text-lg font-semibold text-gray-900">PM Templates</h2>
        <FormProvider {...methods}>
          <AppTextField
            name="search"
            className="w-full mt-2 text-slate-900"
            placeholder="Search templates..."
            leftIcon={<Search className="h-4 w-4 text-gray-500" />}
            rightIcon={
              search ? (
                <X
                  className="h-4 w-4 text-gray-500 hover:text-gray-900 cursor-pointer"
                  onClick={() => methods.reset({ search: "" })}
                />
              ) : undefined
            }
          />
        </FormProvider>
      </SidebarHeader>
      <SidebarContent className="bg-white">
        <SidebarGroup className="flex flex-col">
          <SidebarMenu>
            <SidebarMenuItem>
              {isLoading ? (
                <div className="flex justify-center items-center h-20">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                </div>
              ) : pmTemplateData?.data.length === 0 ? (
                <div className="flex flex-col gap-4 mt-20 items-center">
                  <Inbox className="text-gray-400 w-12 h-12" />
                  <p className="text-gray-400">No templates found</p>
                </div>
              ) : (
                pmTemplateData?.data.map((template, index) => (
                  <PmTemplateItem
                    key={template.id}
                    template={template}
                    isLast={index === pmTemplateData.data.length - 1}
                  />
                ))
              )}
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
