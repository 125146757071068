import { WorkRequestSummaryReport } from "@cerev-cmms/pdf";
import { PDFViewer } from "@react-pdf/renderer";
import { Loader2 } from "lucide-react";
import AppCenterEmpty from "../../../../components/AppCenterEmpty";
import useDate from "../../../../hooks/useDate";
import { useAppSelector } from "../../../../redux/store";
import {
  usePdfReportDateRange,
  usePdfReportLocations,
  usePdfReportWorkRequestStatuses,
} from "../../../../stores/pdfReportStore/pdfReportStore";
import { trpc } from "../../../../lib/trpc";
import { useRenderWorkRequestSummaryPdf } from "../../../../pdf/render-optimization/useRenderWorkRequestSummaryPdf";

export default function WorkRequestPdfReportViewer() {
  const { formatDateToLocal } = useDate();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const dateRange = usePdfReportDateRange();
  const workRequestStatuses = usePdfReportWorkRequestStatuses();
  const locations = usePdfReportLocations();

  const { data: workRequests, isFetching: isWorkRequestsFetching } =
    trpc.reports.getWorkRequestSummaryPdf.useQuery(
      {
        projectId: activeProj?.id?.toString() ?? "",
        dateStartISO: dateRange?.from?.toISOString(),
        dateEndISO: dateRange?.to?.toISOString(),
        workRequestStatuses,
        locations,
      },
      {
        enabled: !!activeProj?.id && !!dateRange,
      }
    );

  const {
    url,
    loading: pdfLoading,
    error: pdfError,
  } = useRenderWorkRequestSummaryPdf({
    workRequests: workRequests ?? [],
    generatedDateStr: formatDateToLocal({
      dateStr: new Date().toISOString(),
      formatting: "d MMM yyyy, h:m a",
    }),
  });

  if (isWorkRequestsFetching || pdfLoading)
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-150px)]">
        <div className="flex flex-col items-center justify-center gap-4">
          <Loader2 className="text-primary-900 animate-spin w-12 h-12" />
          <p className="text-slate-900 font-bold">Generating your PDF...</p>
          <p className="text-slate-500 text-sm">
            This process may take up to 5 - 10 mins depending on size of data...
          </p>
        </div>
      </div>
    );

  if (!workRequests?.length) {
    return (
      <div className="h-[calc(100vh-150px)] flex items-center justify-center">
        <AppCenterEmpty />
      </div>
    );
  }

  return (
    <iframe
      className="h-[calc(100vh-150px)]"
      src={url ?? undefined}
      style={{
        width: "100%",
        height: "calc(100vh-150px)",
        borderRadius: "10px",
      }}
    />
  );
}
