import { Link } from "react-router-dom";
import { CerevModule } from "../../api/model";
import AppButton from "../../components/AppButton";
import { Tabs } from "../../components/ui/tabs";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
import useAppTabsNavigation from "../../hooks/useAppTabsNavigation";
import { useAppSelector } from "../../redux/store";
import AppTitle from "../HomeScreen/components/AppTitle";
import { getDashboardComponent } from "./dashboards";
import { Presentation } from "lucide-react";
import DashboardBottomBar from "./components/DashboardBottomBar";
import { useDashboardActions } from "../../stores/dashboardStore/dashboardStore";
import SlideShowAnalyticGuard from "../../guards/SlideShowAnalyticGuard";

export default function DashboardScreen() {
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "dashboardTabIdx",
    defaultValue: "0",
  });

  const { toggleSelectionMode } = useDashboardActions();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const dashboardConfig = getDashboardComponent(activeProj?.code);
  const DashboardComponent = dashboardConfig.component;

  if (!activeProj) return <div>No active project selected</div>;

  return (
    <ModuleAccessGuard module={CerevModule.DASHBOARD}>
      <div className="relative">
        <div className="flex items-center gap-4 mb-4">
          <AppTitle title="Dashboard" className="mb-0" />
          <SlideShowAnalyticGuard>
            <AppButton
              variant="outline"
              icon={<Presentation />}
              onClick={toggleSelectionMode}
            >
              Slideshow
            </AppButton>
          </SlideShowAnalyticGuard>
        </div>
        <Tabs value={currentTabIdx} onValueChange={onTabChange}>
          <DashboardComponent currentTabIdx={currentTabIdx} />
        </Tabs>
        <DashboardBottomBar />
      </div>
    </ModuleAccessGuard>
  );
}
