import { z } from "zod";

export const getLocationsSchema = z.object({
  projectId: z.string(),
  locationId: z.string().optional(),
  search: z.string().optional(),
});

export type GetLocationsInput = z.infer<typeof getLocationsSchema>;

