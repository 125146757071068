import { Card } from "@/components/ui/card";
import { EventInput } from "@fullcalendar/core";
import { useSearchParams } from "react-router-dom";
import AppStatusBadge from "../../../components/badge/AppStatusBadge";
import AppButton from "../../../components/AppButton";
import { DRAWER_VIEW_STATE, WORK_ORDER_DETAIL_DRAWER } from "../../../components/drawers/AppDrawer";

interface UnplannedWorkOrderCardProps {
  workOrder: EventInput;
}

export default function UnplannedWorkOrderCard({ workOrder: wo }: UnplannedWorkOrderCardProps) {
  const [, setSearchParams] = useSearchParams();

  return (
    <Card
      key={wo.id}
      data-event={JSON.stringify(wo)}
      className="fc-event group relative hover:shadow-md hover:cursor-move border border-gray-200 hover:border-primary-500 w-full"
    >
      {/* Status Badge - Top Right */}
      <div className="absolute top-3 right-3">
        <AppStatusBadge
          defectStatus={wo.extendedProps?.defectStatus}
        />
      </div>

      {/* Main Content */}
      <div className="p-4">
        {/* Title Section */}
        <div className="mb-3">
          <h3 className="font-medium text-sm text-gray-900 mb-1 pr-20">
            {wo.title}
          </h3>
          <p className="text-xs text-gray-500">
            Ref: {wo.extendedProps?.refNo ?? "-"}
          </p>
        </div>

        {/* Info Grid */}
        <div className="grid grid-cols-2 gap-2 mb-3 text-xs">
          <div>
            <p className="text-gray-500">Equipment</p>
            <p className="text-gray-900 font-medium truncate">
              {wo.extendedProps?.asset?.name ?? "-"}
            </p>
          </div>
          <div>
            <p className="text-gray-500">Location</p>
            <p className="text-gray-900 font-medium truncate">
              {wo.extendedProps?.asset?.location?.name ?? "-"}
            </p>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex items-center gap-2 mt-2">
          <AppButton
            size="sm"
            variant="outline"
            label="View Details"
            className="text-xs py-1 px-2 h-7"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", WORK_ORDER_DETAIL_DRAWER);
                p.set("drawerState", DRAWER_VIEW_STATE);
                p.set("workOrderId", wo.extendedProps?.id);
                p.set("workOrderTabIdx", "0");
                return p;
              });
            }}
          />
          <p className="text-xs text-gray-400 italic">
            Drag to schedule
          </p>
        </div>
      </div>
    </Card>
  );
} 