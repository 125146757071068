import { useQueryClient } from "@tanstack/react-query";
import { useAppSelector } from "../../../../../redux/store";
import { trpc } from "../../../../../lib/trpc";
import { Button } from "../../../../../components/ui/button";
import { Plus, Trash2, Edit2 } from "lucide-react";
import { useState } from "react";
import { RouterInputs } from "@cerev-cmms/trpc/src/lib/trpc/root";
import DynamicFieldDialog from "./DynamicFieldDialog";
import AppButton from "../../../../../components/AppButton";

const fieldTypes = [
  { value: "text", label: "Text" },
  { value: "number", label: "Number" },
  { value: "date", label: "Date" },
  { value: "select", label: "Single Select" },
  { value: "multiselect", label: "Multi Select" },
  { value: "checkbox", label: "Checkbox" },
] as const;

export type DynamicField =
  NonNullable<NonNullable<RouterInputs["workRequest"]["updateWorkRequestSetting"]["dynamicFields"]>["fields"]>[number];

type WorkRequestDynamicFields =
  RouterInputs["workRequest"]["updateWorkRequestSetting"]["dynamicFields"];

export default function DynamicSetting() {
  const [open, setOpen] = useState(false);
  const [editingField, setEditingField] = useState<DynamicField | null>(null);
  const qc = useQueryClient();
  const utils = trpc.useUtils();
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data: workReqSettings } =
    trpc.workRequest.getOneWorkRequestSetting.useQuery(
      {
        projectId: activeProj?.id ?? 0,
      },
      {
        enabled: !!activeProj,
        select: (res) => res.data,
      }
    );

  const { mutate, isPending } =
    trpc.workRequest.updateWorkRequestSetting.useMutation({
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => {
            utils.workRequest.getOneWorkRequestSetting.invalidate();
            return (q.queryKey[0] as string).includes("work-request-setting");
          },
        });
        setOpen(false);
        setEditingField(null);
      },
    });

  function onSubmit(values: DynamicField) {
    if (!workReqSettings || !activeProj?.id) return;

    const currentFields =
      (workReqSettings.dynamicFields as WorkRequestDynamicFields)?.fields || [];
    let updatedFields: DynamicField[];

    if (editingField) {
      updatedFields = currentFields.map((field: DynamicField) =>
        field.id === editingField.id ? values : field
      );
    } else {
      updatedFields = [
        ...currentFields,
        { ...values, id: crypto.randomUUID() },
      ];
    }

    mutate({
      ...workReqSettings,
      projectId: activeProj.id,
      dynamicFields: {
        fields: updatedFields,
      },
    });
  }

  function handleDelete(fieldId: string) {
    if (!workReqSettings || !activeProj?.id) return;

    const currentFields =
      (workReqSettings.dynamicFields as WorkRequestDynamicFields)?.fields || [];
    const updatedFields = currentFields.filter(
      (field: DynamicField) => field.id !== fieldId
    );

    mutate({
      ...workReqSettings,
      projectId: activeProj.id,
      dynamicFields: {
        fields: updatedFields,
      },
    });
  }

  const dynamicFields =
    (workReqSettings?.dynamicFields as WorkRequestDynamicFields)?.fields || [];

  return (
    <div className="mt-16">
      <div className="flex items-center justify-between mb-4">
        <div className="space-y-2">
          <h3 className="text-2xl font-bold">Dynamic Fields</h3>
          <p className="text-sm text-slate-600">
            Adjust here for additional fields that you wish for your users to
            fill in as part of the work request
          </p>
        </div>
        <AppButton
          onClick={() => {
            setEditingField(null);
            setOpen(true);
          }}
          icon={<Plus className="w-4 h-4" />}
        >
          Add Field
        </AppButton>
      </div>

      <DynamicFieldDialog
        open={open}
        onOpenChange={setOpen}
        editingField={editingField}
        onSubmit={onSubmit}
        isPending={isPending}
      />

      <div className="space-y-4">
        {dynamicFields.map((field: DynamicField) => (
          <div
            key={field.id}
            className="flex items-center justify-between p-4 border rounded-lg"
          >
            <div>
              <h4 className="font-medium">{field.label}</h4>
              <p className="text-sm text-gray-500">
                Type: {fieldTypes.find((t) => t.value === field.type)?.label}
              </p>
              {field.options && field.options.length > 0 && (
                <p className="text-sm text-gray-500">
                  Options: {field.options.join(", ")}
                </p>
              )}
              <div className="flex gap-2 mt-1">
                {field.required && (
                  <span className="px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded">
                    Required
                  </span>
                )}
                {field.visibility === "HIDDEN" && (
                  <span className="px-2 py-1 text-xs bg-gray-100 text-gray-800 rounded">
                    Hidden
                  </span>
                )}
              </div>
            </div>
            <div className="flex gap-2">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  setEditingField(field);
                  setOpen(true);
                }}
              >
                <Edit2 className="w-4 h-4" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleDelete(field.id)}
              >
                <Trash2 className="w-4 h-4 text-red-500" data-testid="delete-dynamic-field" />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
