import { ColumnDef } from "@tanstack/react-table";
import { RouterOutputs } from "@cerev-cmms/trpc";
import { Badge } from "../../../../ui/badge";
import { cn } from "../../../../../lib/utils";
import AppFullLocation from "../../../../AppFullLocation";
import useDate from "../../../../../hooks/useDate";

type WorkOrder = RouterOutputs["assets"]["getWorkOrders"][number];

export const assetWorkOrderColumns: ColumnDef<WorkOrder>[] = [
  {
    accessorKey: "subject",
    header: "Subject",
    cell: ({ row }) => (
      <div className="font-medium text-primary-900 underline hover:cursor-pointer">
        {row.original.subject ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "refNo",
    header: "Reference No.",
    cell: ({ row }) => <div>{row.original.refNo ?? "-"}</div>,
  },
  {
    accessorKey: "location",
    header: "Location",
    cell: ({ row }) => (
      <AppFullLocation locationId={row.original.locationId?.toString()} />
    ),
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const { defectStatus } = row.original;
      if (!defectStatus) return "-";

      return (
        <Badge
          className={cn("flex items-center gap-1 w-fit border-slate-300 text-white")}
          style={{
            backgroundColor: `#${defectStatus.colorHex}`,
          }}
        >
          <span>{defectStatus.name}</span>
        </Badge>
      );
    },
  },
  {
    accessorKey: "createdBy",
    header: "Created By",
    cell: ({ row }) => <div>{row.original.createdBy?.name ?? "-"}</div>,
  },
  {
    accessorKey: "createdOn",
    header: "Created On",
    cell: ({ row }) => {
      const { formatDateToLocal } = useDate();
      return (
        <div>
          {formatDateToLocal({
            dateStr: row.original.createdOn.toISOString(),
          })}
        </div>
      );
    },
  },
]; 