import * as React from "react";
import { Text, View, Image } from "@react-pdf/renderer";
import { useJkrPataWorkOrder } from "..";

export function RepairActionSection() {
  const workOrder = useJkrPataWorkOrder();

  return (
    <View style={{ border: "1px solid black" }} wrap={false}>
      <View style={{ backgroundColor: "#f0f0f0", padding: 4 }}>
        <Text style={{ fontSize: 9, fontWeight: "bold" }}>
          D. Tindakan Pembaikan
        </Text>
      </View>
      <View style={{ padding: 8, gap: 6 }}>
        <View>
          <Text style={{ fontSize: 9 }}>Perihal Kerja / Tindakan:</Text>
          <View
            style={{
              minHeight: 60,
              borderBottom: "1px solid #ddd",
              marginTop: 4,
              padding: 4,
            }}
          >
            <Text style={{ fontSize: 9, fontWeight: "bold" }}>
              {workOrder.correctiveAction ?? "-"}
            </Text>
          </View>
        </View>

        {/* Attachments/Photos Section */}
        {workOrder.comments?.some(
          (comment) => comment.attachments && comment.attachments.length > 0
        ) && (
          <View style={{ marginVertical: 16 }}>
            <Text style={{ fontSize: 9, marginBottom: 4 }}>Lampiran:</Text>
            <View style={{ flexDirection: "row", gap: 8, flexWrap: "wrap" }}>
              {workOrder.comments?.flatMap(
                (comment, commentIndex) =>
                  comment.attachments?.map((attachment, attachmentIndex) => (
                    <View
                      key={`${commentIndex}-${attachmentIndex}`}
                      style={{
                        width: 100,
                        height: 100,
                        border: "1px solid #ddd",
                      }}
                    >
                      {attachment.url && (
                        <Image
                          src={attachment.url}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                      <Text
                        style={{
                          fontSize: 8,
                          textAlign: "center",
                          marginTop: 2,
                        }}
                      >
                        {`Gambar ${attachmentIndex + 1} (${new Date(
                          comment.createdOn ?? ""
                        ).toLocaleDateString()})`}
                      </Text>
                    </View>
                  )) ?? []
              )}
            </View>
          </View>
        )}

        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text style={{ fontSize: 9 }}>Tarikh & Masa Mula: </Text>
          <Text style={{ fontSize: 9, fontWeight: "bold" }}>
            {workOrder.createdOn ?? "_________________"}
          </Text>
          <Text style={{ fontSize: 9 }}>Tarikh & Masa Siap: </Text>
          <Text style={{ fontSize: 9, fontWeight: "bold" }}>
            {workOrder.workOrderCompletedOn ?? "_________________"}
          </Text>
        </View>
      </View>
    </View>
  );
}
