import { useSearchParams } from "react-router-dom";
import useDate from "../../../../../hooks/useDate";
import AppButton from "../../../../AppButton";
import AppFullLocation from "../../../../AppFullLocation";
import AppMultiImage from "../../../../AppMultiImage";
import {
  DRAWER_UPDATE_STATE,
  DRAWER_VIEW_STATE,
  PM_CHECKLIST_DETAIL_DRAWER,
  PM_CHECKLIST_FORM_DRAWER,
} from "../../../AppDrawer";
import { Separator } from "../../../../ui/separator";
import { Avatar, AvatarFallback } from "../../../../ui/avatar";
import { Pencil } from "lucide-react";
import { RouterOutputs } from "@cerev-cmms/trpc";
import { useEffect } from "react";

type PmChecklistType = RouterOutputs["assets"]["getAssetPm"][number];
type GetPmChecklistType = RouterOutputs["pm"]["getPmChecklists"]["data"][number];

interface PmChecklistItemProps {
  pmChecklist: PmChecklistType | GetPmChecklistType;
}

export default function PmChecklistItem({ pmChecklist }: PmChecklistItemProps) {
  const [searchParam, setSearchParams] = useSearchParams();
  const { diffDateTillNow } = useDate();

  return (
    <div
      key={pmChecklist.id}
      className="bg-neutral-300 rounded-xl p-4 flex flex-col"
    >
      <p className="text-xl font-bold font-sans">
        {pmChecklist.pmTemplate.name ?? "-"}
      </p>
      <p className="text-slate-500 text-sm mt-1">{pmChecklist.refNo ?? "-"}</p>
      {pmChecklist.pmTemplate.pmTemplateType === "ONE_EQUIPMENT" && (
        <AppFullLocation
          locationId={pmChecklist.asset?.locationId?.toString()}
          className="text-neutral-500"
        />
      )}
      {pmChecklist.pmStatus.name === "Close" ? (
        <div className="flex flex-col">
          <Separator className="my-5" />
          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <p className="font-bold font-sans">Completed By</p>
              <p className="text-neutral-600 text-sm">
                {diffDateTillNow({
                  compareISODate: pmChecklist.completedOn?.toISOString() ?? "",
                })}
              </p>
            </div>
            <div className="flex gap-2">
              <Avatar className="bg-primary-900 text-base">
                <AvatarFallback className="bg-transparent text-white">
                  {pmChecklist.updatedBy?.name?.substring(0, 2).toUpperCase()}
                </AvatarFallback>
              </Avatar>
              <div className="flex flex-col">
                <p className="font-medium font-sans">
                  {pmChecklist.updatedBy?.name}
                </p>
                <p className="text-neutral-500 text-sm">
                  {pmChecklist.updatedBy?.position ?? "-"}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <AppButton
              label="View"
              variant="outline"
              onClick={() => {
                setSearchParams((p) => {
                  p.set("drawerType", PM_CHECKLIST_DETAIL_DRAWER);
                  p.set("drawerState", DRAWER_VIEW_STATE);
                  p.set("pmChecklistId", pmChecklist.id?.toString() ?? "");
                  return p;
                });
              }}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-row-reverse">
          <AppButton
            icon={<Pencil />}
            label="Start PM"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerState", DRAWER_UPDATE_STATE);
                p.set("drawerType", PM_CHECKLIST_FORM_DRAWER);
                p.set("pmChecklistId", pmChecklist.id?.toString() ?? "");
                return p;
              });
            }}
          />
        </div>
      )}
    </div>
  );
}
