import { z } from "zod";

export const getWorkOrderSummaryReportPdfSchema = z.object({
  projectId: z.string(),
  dateStartISO: z.string().optional(),
  dateEndISO: z.string().optional(),
  workOrderTypes: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional(),
  locations: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional(),
  workOrderStatuses: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional(),
});

export const workOrderSummaryReportPdfResponseSchema = z.object({
  dateCreated: z.string(),
  subject: z.string(),
  location: z.string(),
  completionDate: z.string(),
  description: z.array(z.string()),
  workOrderStatus: z.string(),
  photoUrls: z.array(z.string()),
  workOrderType: z.string(),
});

export type GetWorkOrderSummaryReportPdfInput = z.infer<
  typeof getWorkOrderSummaryReportPdfSchema
>;
export type GetWorkOrderSummaryReportPdfResponse = z.infer<
  typeof workOrderSummaryReportPdfResponseSchema
>;

export const getWorkRequestSummaryReportPdfSchema = z.object({
  projectId: z.string(),
  dateStartISO: z.string().optional(),
  dateEndISO: z.string().optional(),
  locations: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional(),
  workRequestStatuses: z.array(z.object({
    id: z.number(),
    name: z.string(),
  })).optional(),
});

export const workRequestSummaryReportPdfResponseSchema = z.object({
  dateCreated: z.date(),
  title: z.string().nullable(),
  location: z.string().nullable(),
  closedDate: z.date().nullable(),
  description: z.string().nullable(),
  workRequestStatus: z.string(),
  photoUrls: z.array(z.string()),
  name: z.string().nullable(),
  department: z.string().nullable(),
  contactNo: z.string().nullable(),
  email: z.string().nullable(),
});

export type GetWorkRequestSummaryReportPdfInput = z.infer<
  typeof getWorkRequestSummaryReportPdfSchema
>;
export type WorkRequestSummaryReportPdfResponse = z.infer<
  typeof workRequestSummaryReportPdfResponseSchema
>;
