import { MapPin, Tag } from "lucide-react";
import AppItemInfo from "../AppItemInfo";
import { useState } from "react";
import { AssetListDialog } from "../dialogs/AssetListDialog";
import AppButton from "../AppButton";
import { RouterOutputs } from "@cerev-cmms/trpc";

type Asset = RouterOutputs["pm"]["getOnePmChecklist"]["asset"];
type MultipleAssets = RouterOutputs["pm"]["getOnePmChecklist"]["multipleAssets"][number];

interface PmChecklistEquipmentInfoProps {
  isMultipleEquipments: boolean;
  asset?: Asset | null;
  multipleAssets?: (Asset | MultipleAssets)[];
}

export default function PmChecklistEquipmentInfo({
  isMultipleEquipments,
  asset,
  multipleAssets,
}: PmChecklistEquipmentInfoProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (isMultipleEquipments) {
    return (
      <>
        <AppItemInfo
          label="Equipment List"
          icon={<Tag className="text-primary-900" />}
          content={
            <div className="flex">
              <AppButton
                variant="outline"
                onClick={() => setIsDialogOpen(true)}
                label={`${multipleAssets?.length ?? 0} Equipments`}
              />
              <AssetListDialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                assets={multipleAssets ?? []}
              />
            </div>
          }
        />
      </>
    );
  }

  return (
    <>
      <AppItemInfo
        label="Equipment Name"
        icon={<Tag className="text-primary-900" />}
        content={<p>{asset?.name ?? "-"}</p>}
      />
      <AppItemInfo
        label="Location"
        icon={<MapPin className="text-primary-900" />}
        content={<p>{asset?.location?.name ?? "-"}</p>}
      />
    </>
  );
}
