const weekDayMapper = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

const monthMapper = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

// Match the enum from schema.prisma
export enum FrequencySettingType {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  BY_MONTH = "BY_MONTH",
  BY_YEAR = "BY_YEAR"
}

interface FrequencyDescriptionProps {
  frequencySetting: FrequencySettingType;
  weeklyRepeatDay?: string | null;
  monthlyEveryStepCount?: number | null;
  monthlyRepeatDate?: string | null;
  yearlyEveryStepCount?: number | null;
  yearlyRepeatDate?: string | null;
  yearlyRepeatMonth?: string | null;
}

export function getFrequencyDescription({
  frequencySetting,
  weeklyRepeatDay,
  monthlyEveryStepCount,
  monthlyRepeatDate,
  yearlyEveryStepCount,
  yearlyRepeatDate,
  yearlyRepeatMonth,
}: FrequencyDescriptionProps): string {
  switch (frequencySetting) {
    case FrequencySettingType.DAILY:
      return "Repeats everyday";
    case FrequencySettingType.WEEKLY:
      return weeklyRepeatDay
        ? `Repeats every week, on ${weeklyRepeatDay
            .split(",")
            .map((d) => weekDayMapper[Number(d) as keyof typeof weekDayMapper])
            .join(", ")}`
        : "Weekly";
    case FrequencySettingType.BY_MONTH:
      return monthlyEveryStepCount && monthlyRepeatDate
        ? `Repeats every ${monthlyEveryStepCount} month, on the date of ${monthlyRepeatDate}`
        : "Monthly";
    case FrequencySettingType.BY_YEAR:
      return yearlyEveryStepCount && yearlyRepeatDate && yearlyRepeatMonth
        ? `Repeats every ${yearlyEveryStepCount} year, on ${yearlyRepeatDate} of ${yearlyRepeatMonth
            .split(",")
            .map((d) => monthMapper[Number(d) as keyof typeof monthMapper])
            .join(", ")}`
        : "Yearly";
    default:
      return "-";
  }
} 