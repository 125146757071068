import { FormProvider, useForm, useWatch } from "react-hook-form";
import {
  CerevModule,
  PmChecklistControllerGetPmChecklistTableParams,
} from "../../api/model";
import {
  usePmChecklistControllerGetPmChecklistTable,
  usePmChecklistControllerUpdatePlannedTime,
} from "../../api/preventive-maintenance-checklists/preventive-maintenance-checklists";
import useDebounce from "../../hooks/useDebounce";
import { useAppSelector } from "../../redux/store";
import AppTitle from "../HomeScreen/components/AppTitle";
import AppCalendar from "../../components/calendar/AppCalendar";
import { EventInput, EventSourceInput } from "@fullcalendar/core";
import {
  useCalendarDateEnd,
  useCalendarDateStart,
  useCalendarRefActions,
} from "../../stores/calendarRefStore/calendarRefStore";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { addHours } from "date-fns";
import {
  DRAWER_VIEW_STATE,
  PM_CHECKLIST_DETAIL_DRAWER,
} from "../../components/drawers/AppDrawer";
import { useSearchParams } from "react-router-dom";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
} from "../../components/ui/sidebar";
import { RouterInputs } from "@cerev-cmms/trpc";
import { trpc } from "../../lib/trpc";

type GetPmChecklistsInput = RouterInputs["pm"]["getPmChecklists"];

export default function PmSchedulerScreen() {
  const [, setSearchParams] = useSearchParams();
  const qc = useQueryClient();
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });

  const search = useWatch({ control: methods.control, name: "search" });

  const activeProj = useAppSelector((state) => state.root.activeProject);
  const dateStart = useCalendarDateStart();
  const dateEnd = useCalendarDateEnd();

  const queryArg = useDebounce<GetPmChecklistsInput>({
    value: {
      projectId: activeProj?.id?.toString() ?? "0",
      plannedDateStartISO: dateStart.toISOString(),
      plannedDateEndISO: dateEnd.toISOString(),
      search: search || undefined,
    },
    delay: 500,
  });

  const { data: pmChecklists, isFetching: pmChecklistsIsFetching } =
    trpc.pm.getPmChecklists.useQuery(queryArg, {
      select: (res) =>
        res.data.map(
          (pm) =>
            ({
              id: pm.id.toString(),
              title: pm.pmTemplate.name,
              start: pm.plannedOn,
              end: pm.plannedEndOn,
              allDay: !pm.plannedEndOn,
              extendedProps: {
                ...pm,
              },
            } as EventInput)
        ),
      enabled: !!activeProj,
    });

  const { mutate: updatePmChecklistPlannedTime } =
    usePmChecklistControllerUpdatePlannedTime({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (query) =>
              (query.queryKey[0] as string).includes("pm-checklist"),
          });
        },
      },
    });

  const { cleanUp } = useCalendarRefActions();

  useEffect(() => {
    return cleanUp;
  }, []);

  return (
    <ModuleAccessGuard module={CerevModule.PM}>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <AppTitle title="PM Scheduler" />
        </div>
        <FormProvider {...methods}>
          <div className="grid grid-cols-4 gap-6">
            <div className="col-span-4">
              <AppCalendar
                isLoading={pmChecklistsIsFetching}
                events={pmChecklists}
                eventClick={(arg) => {
                  setSearchParams((p) => {
                    p.set("drawerType", PM_CHECKLIST_DETAIL_DRAWER);
                    p.set("drawerState", DRAWER_VIEW_STATE);
                    p.set("pmChecklistId", arg.event.id);
                    return p;
                  });
                }}
                eventContent={(arg) => {
                  return (
                    <div
                      className="truncate flex gap-2 items-center text-white w-full h-full border-solid border-2"
                      style={{
                        backgroundColor: `#${arg?.event?.extendedProps?.pmStatus?.colorHex}`,
                        borderColor: `#${arg?.event?.extendedProps?.pmStatus?.colorHex}`,
                        borderRadius: "4px",
                        padding: "2px 4px",
                      }}
                    >
                      {/* <AppStatusBadge
                defectStatus={
                  arg?.event?.extendedProps?.defectStatus as DefectStatus
                }
              /> */}
                      <p className="truncate">{arg.event.title}</p>
                    </div>
                  );
                }}
                eventResize={(arg) => {
                  if (arg.event.start && arg.event.end && arg.event.id) {
                    updatePmChecklistPlannedTime({
                      pmChecklistId: arg.event.id,
                      data: {
                        plannedOn: arg.event.start?.toISOString(),
                        plannedEndOn: arg.event.end?.toISOString(),
                      },
                    });
                  }
                }}
                eventDrop={(arg) => {
                  const isAllDay = arg.event.allDay;
                  if (arg.event.start && arg.event.id)
                    updatePmChecklistPlannedTime({
                      pmChecklistId: arg.event.id,
                      data: {
                        plannedOn: arg.event.start?.toISOString(),
                        plannedEndOn: isAllDay
                          ? null
                          : arg.event.end
                          ? arg.event.end.toISOString()
                          : addHours(arg.event.start, 1).toISOString(),
                      },
                    });
                }}
                eventReceive={(arg) => {
                  if (arg.event.start && arg.event.id)
                    updatePmChecklistPlannedTime({
                      pmChecklistId: arg.event.id,
                      data: {
                        plannedOn: arg.event.start?.toISOString(),
                      },
                    });
                }}

                //   filterNode={<WOSchedulerFilter />}
              />
            </div>
          </div>
        </FormProvider>
      </div>
    </ModuleAccessGuard>
  );
}
