import { TreeItem } from "@mui/x-tree-view";
import { RouterOutputs } from "@cerev-cmms/trpc";
import { trpc } from "../../../../../lib/trpc";

type Location = RouterOutputs["location"]["getLocations"][number];

interface LocationFilterTreeItemProps {
  loc: Location;
  onSelect: (loc: Location) => void;
  showFirstLevelOnly?: boolean;
}

export default function LocationFilterTreeItem({
  loc,
  onSelect,
  showFirstLevelOnly,
}: LocationFilterTreeItemProps) {
  const { data: childLocations } = trpc.location.getLocations.useQuery(
    {
      projectId: loc.projectId.toString(),
      locationId: loc.id.toString(),
    },
    {
      enabled: !showFirstLevelOnly,
    }
  );

  return (
    <TreeItem
      nodeId={loc.id.toString()}
      onClick={() => onSelect(loc)}
      label={
        <div
          className="p-1 rounded-sm flex w-full items-between"
          onClick={() => {
            onSelect(loc);
          }}
        >
          <p>{loc.name}</p>
        </div>
      }
    >
      {!showFirstLevelOnly &&
        childLocations?.map((childLoc) => (
          <LocationFilterTreeItem
            key={childLoc.id}
            loc={childLoc}
            onSelect={onSelect}
          />
        ))}
    </TreeItem>
  );
}
