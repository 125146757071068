import { ColumnDef, Row } from "@tanstack/react-table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetWorkOrdersResponseDto, User } from "../../../api/model";
import AppFullLocation from "../../../components/AppFullLocation";
import AppMultiImage from "../../../components/AppMultiImage";
import {
  DRAWER_VIEW_STATE,
  WORK_ORDER_DETAIL_DRAWER,
} from "../../../components/drawers/AppDrawer";
import { Avatar, AvatarFallback } from "../../../components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuLabel,
} from "../../../components/ui/dropdown-menu";
import useDate from "../../../hooks/useDate";
import { Button } from "../../../components/ui/button";
import { MoreVertical } from "lucide-react";
import { Checkbox } from "../../../components/ui/checkbox";
import { RouterOutputs } from "@cerev-cmms/trpc";

type WorkOrder = RouterOutputs["workOrders"]["getWorkOrders"][number];

const WorkOrderRefNoNav = ({ wo }: { wo: Row<WorkOrder> }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const nav = useNavigate();
  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", WORK_ORDER_DETAIL_DRAWER);
          p.set("drawerState", DRAWER_VIEW_STATE);
          p.set("workOrderId", wo.getValue("id"));
          p.set("drawerWidth", "1000");
          p.set("workOrderTabIdx", "0");
          return p;
        });
        // nav(`/home/work-orders/${wo.getValue("id")}`);
      }}
    >
      {wo.getValue("subject") ?? "-"}
    </div>
  );
};

const AssignedToCell = ({ row }: { row: Row<WorkOrder> }) => {
  const assignedUsers = row.getValue("assignedUsers") as User[];

  if (assignedUsers.length <= 0) return <p>-</p>;

  if (assignedUsers.length < 2)
    return (
      <div>
        {(row.getValue("assignedUsers") as User[])?.map((user) => (
          <div className="flex gap-2 items-center" key={user.id}>
            <Avatar className="bg-primary-900 text-base">
              <AvatarFallback className="bg-transparent text-white">
                {user?.name?.substring(0, 2)?.toUpperCase() ?? "-"}
              </AvatarFallback>
            </Avatar>
            <p>{user?.name}</p>
          </div>
        ))}
      </div>
    );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex items-center hover:cursor-pointer -space-x-2 ">
          {assignedUsers?.map((u, idx) => {
            if (idx < 2)
              return (
                <Avatar
                  className="bg-primary-900 text-base border-white border"
                  key={u.id}
                >
                  <AvatarFallback className="bg-transparent text-white">
                    {(u?.name ?? "-").substring(0, 2).toUpperCase()}
                  </AvatarFallback>
                </Avatar>
              );
          })}
          {
            <p className="pl-3 text-slate-400">
              {assignedUsers.length > 2 ? (
                `+${assignedUsers.length - 2}`
              ) : (
                <></>
              )}
            </p>
          }
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {assignedUsers.map((u) => (
          <DropdownMenuItem key={u.id}>
            <div className="flex items-center">
              <Avatar className="bg-primary-900 text-base" key={u.id}>
                <AvatarFallback className="bg-transparent text-white">
                  {(u?.name ?? "-").substring(0, 2).toUpperCase()}
                </AvatarFallback>
              </Avatar>
              <p className="ml-2">{u?.name}</p>
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const workOrderColumns: ColumnDef<WorkOrder>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    header: "",
    id: "select",
    cell: ({ row }) => (
      <Checkbox
        className="data-[state=checked]:bg-primary-900 h-5 w-5 data-[state=checked]:border-primary-900 border-slate-400"
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {
          row.toggleSelected(!!value);
        }}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "subject",
    header: "Subject",
    cell: ({ row }) => <WorkOrderRefNoNav wo={row} />,
  },
  {
    accessorKey: "createdOn",
    header: "Created On",
    cell: ({ row }) => {
      const { formatDateToLocal } = useDate();
      return (
        <p>
          {formatDateToLocal({
            dateStr: row.original.createdOn?.toISOString() ?? "",
            formatting: "dd MMM yy, hh:mm a",
          })}
        </p>
      );
    },
  },
  {
    accessorKey: "refNo",
    header: "Ref No.",
  },
  {
    id: "equipmentName",
    accessorFn: (row) => `${row.asset?.name ?? "-"}`,
  },

  {
    header: "Equipment",
    cell: ({ row }) => (
      <div className="flex flex-col">
        <p>{row.getValue("equipmentName")}</p>
      </div>
    ),
  },
  {
    id: "equipmentCode",
    accessorFn: (row) => `${row.asset?.code ?? "-"}`,
    header: "Code",
  },
  {
    id: "locationId",
    accessorFn: (row) => `${row.locationId ?? "-"}`,
    header: "Location",
    cell: ({ row }) => (
      <AppFullLocation locationId={row.getValue("locationId")} />
    ),
  },
  {
    accessorKey: "assignedUsers",
    id: "assignedUsers",
    header: "Assigned To",
    cell: ({ row }) => <AssignedToCell row={row} />,
  },
  {
    accessorKey: "photos",
    header: "Photos",
    cell: ({ row }) =>
      row.original.photos?.length ? (
        <AppMultiImage attachments={row.original.photos} hideMoreThanThree />
      ) : (
        <></>
      ),
  },
];
