import { useSearchParams } from "react-router-dom";
import { trpc } from "../../../lib/trpc";
import { DataTable } from "../../../components/react-table/DataTable";
import { useReactTable, getCoreRowModel } from "@tanstack/react-table";
import { upcomingWorkOrderColumns } from "./columns/upcomingWorkOrderColumns";
import {
  DRAWER_VIEW_STATE,
  WORK_ORDER_DETAIL_DRAWER,
} from "../../../components/drawers/AppDrawer";
import { useAppSelector } from "../../../redux/store";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelectWorkOrderFilterStore } from "../../../stores/workOrder/workOrderFilterStore";
import useDebounce from "../../../hooks/useDebounce";
import { RouterInputs } from "@cerev-cmms/trpc";

type GetUpcomingWorkOrdersInput = RouterInputs["workOrders"]["getUpcomingWorkOrders"];

export default function UpcomingWorkOrderDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const project = useAppSelector((state) => state.root.activeProject);

  // Get all filters from the store
  const locationFilter = useSelectWorkOrderFilterStore(
    (state) => state.filterLocation
  );
  const dateRange = useSelectWorkOrderFilterStore((state) => state.dateRange);
  const assetTypesFilter = useSelectWorkOrderFilterStore(
    (state) => state.assetTypes
  );
  const usersFilter = useSelectWorkOrderFilterStore((state) => state.raisedBy);
  const workOrderTypeFilter = useSelectWorkOrderFilterStore(
    (state) => state.workOrderType
  );
  const workTrades = useSelectWorkOrderFilterStore((state) => state.workTrades);
  const priorities = useSelectWorkOrderFilterStore((state) => state.priorities);

  // Create debounced query arguments
  const queryArg = useDebounce<GetUpcomingWorkOrdersInput>({
    value: {
      projectId: project?.id?.toString() ?? "",
      locFilterId: locationFilter?.id.toString(),
      assetTypeIds: assetTypesFilter.map((at) => at?.id?.toString() ?? "0"),
      filterStartDateISO: dateRange?.from?.toISOString(),
      filterEndDateISO: dateRange?.to?.toISOString(),
      filterRaisedByIds: usersFilter.map((u) => u?.id?.toString() ?? "0"),
      filterWorkOrderTypeIds: workOrderTypeFilter.map(
        (wo) => wo?.id?.toString() ?? "0"
      ),
      filterWorkTradeIds: workTrades.map((wt) => wt?.id?.toString() ?? "0"),
      filterPriorityIds: priorities.map((p) => p?.id?.toString() ?? "0"),
    },
    delay: 500,
  });

  const { data: upcomingWorkOrders, isLoading } = 
    trpc.workOrders.getUpcomingWorkOrders.useQuery(
      queryArg,
      {
        enabled: !!project,
      }
    );

  const table = useReactTable({
    data: upcomingWorkOrders?.data ?? [],
    columns: upcomingWorkOrderColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleRowClick = (row: any) => {
    const workOrder = row.original;
    setSearchParams((p) => {
      p.set("drawerType", WORK_ORDER_DETAIL_DRAWER);
      p.set("drawerState", DRAWER_VIEW_STATE);
      p.set("workOrderId", workOrder.id?.toString() ?? "");
      p.set("workOrderTabIdx", "0");
      return p;
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-2xl font-bold">Upcoming Work Orders</h1>
      <DataTable
        table={table}
        isLoading={isLoading}
        totalCount={upcomingWorkOrders?.totalCount ?? 0}
        onRowClick={handleRowClick}
      />
    </div>
  );
}
