import {
  useSelectedCharts,
  useDashboardActions,
  useIsSelectionMode,
} from "../stores/dashboardStore/dashboardStore";
import { cn } from "../lib/utils";
import { Check } from "lucide-react";

interface SelectableChartProps {
  chartId: string;
  children: React.ReactNode;
  className?: string;
}

export default function SelectableChart({
  chartId,
  children,
  className,
}: SelectableChartProps) {
  const selectedCharts = useSelectedCharts();
  const { toggleChart } = useDashboardActions();
  const isSelectionMode = useIsSelectionMode();
  const isSelected = selectedCharts.includes(chartId);

  return (
    <div
      className={cn(
        "relative transition-all",
        isSelectionMode && "cursor-pointer",
        isSelected &&
          isSelectionMode &&
          "ring-2 ring-primary-900 ring-offset-2 rounded-md",
        className
      )}
      onClick={() => toggleChart(chartId)}
    >
      {isSelected && isSelectionMode ? (
        <div className="absolute top-2 right-2 p-1 bg-primary-900 rounded-full">
          <Check className="w-4 h-4 text-white" />
        </div>
      ) : (
        <div></div>
      )}
      {children}
    </div>
  );
}
