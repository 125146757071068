import { create } from "zustand";

interface DashboardStore {
  selectedCharts: string[];
  isSelectionMode: boolean;
  actions: {
    toggleChart: (chartId: string) => void;
    clearSelection: () => void;
    toggleSelectionMode: () => void;
  };
}

export const useDashboardStore = create<DashboardStore>((set) => ({
  selectedCharts: [],
  isSelectionMode: false,
  actions: {
    toggleChart: (chartId: string) =>
      set((state) => ({
        selectedCharts: state.selectedCharts.includes(chartId)
          ? state.selectedCharts.filter((id) => id !== chartId)
          : [...state.selectedCharts, chartId],
      })),
    clearSelection: () => set({ selectedCharts: [], isSelectionMode: false }),
    toggleSelectionMode: () =>
      set((state) => ({ isSelectionMode: !state.isSelectionMode })),
  },
}));

export const useDashboardActions = () => useDashboardStore((state) => state.actions);
export const useSelectedCharts = () => useDashboardStore((state) => state.selectedCharts);
export const useIsSelectionMode = () => useDashboardStore((state) => state.isSelectionMode); 