import { useQueryClient } from "@tanstack/react-query";
import { Loader2, Pencil } from "lucide-react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDefectStatusV2ControllerGetProjectBasedWorkOrderStatus } from "../api/defect-status-v2/defect-status-v2";
import { useDefectControllerGetOneDefect } from "../api/work-orders-defects/work-orders-defects";
import useIsCloseWorkOrder from "../hooks/useIsCloseWorkOrder";
import { useHasWorkOrderEditAfterClosePolicy } from "../hooks/usePolicies/usePolicies";
import { trpc } from "../lib/trpc";
import { useGetUserDataQuery } from "../redux/slices/Auth/AuthApi";
import {
  DefectStatus,
  GetOneWorkOrderResponseDto,
} from "../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../redux/store";
import { cn } from "../utils/util";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { Form } from "./ui/form";
import AppTextField from "./AppTextField";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "./ui/dialog";
import { Rating } from "@mui/material";
import AppButton from "./AppButton";
import { useWorkOrderPopUpEnabled } from "../hooks/useFeatures";

interface AppWorkOrderStatsUpdateMenuProps {
  workOrder?: GetOneWorkOrderResponseDto;
}

export default function AppWorkOrderStatusUpdateMenu({
  workOrder,
}: AppWorkOrderStatsUpdateMenuProps) {
  const [searchParam] = useSearchParams();
  const qc = useQueryClient();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isClose = useIsCloseWorkOrder({ workOrder });
  const utils = trpc.useUtils();

  const isWorkOrderPopUpEnabled = useWorkOrderPopUpEnabled();

  const { data: user } = useGetUserDataQuery();

  const hasEditAfterClosePolicy = useHasWorkOrderEditAfterClosePolicy();

  const {
    mutateAsync: updateWorkStatus,
    isPending: updateWorkStatusIsLoading,
  } = trpc.workOrders.updateWorkOrderStatus.useMutation({
    onSuccess: () => {
      utils.workOrders.invalidate();
      qc.invalidateQueries({
        predicate: (qry) => {
          return (
            (qry.queryKey[0] as string).includes("work-order") ||
            (qry.queryKey[0] as string).includes("defect")
          );
        },
      });
    },
  });

  const activeWoId = searchParam.get("workOrderId");
  const { isFetching } = useDefectControllerGetOneDefect(activeWoId ?? "");

  const { data: workOrderStatuses } =
    useDefectStatusV2ControllerGetProjectBasedWorkOrderStatus(
      {
        roleId:
          user?.roles
            ?.filter((role) => role.projectId === activeProj?.id)[0]
            ?.id?.toString() ?? "",
      },
      {
        query: {
          enabled:
            !!activeProj &&
            !!user?.roles?.filter(
              (role) => role.projectId === activeProj?.id
            )[0],
          select: (res) => res.data,
        },
      }
    );

  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<DefectStatus | null>(
    null
  );

  const form = useForm<{rating: number, closingRemark: string}>({
    resolver: zodResolver(z.object({
      rating: z.number().optional(),
      closingRemark: z.string().optional(),
    })),
    defaultValues: {
      rating: 0,
      closingRemark: "",
    },
  });

  const { mutateAsync: closeAndReview, isPending: isClosing } =
    trpc.workOrders.closeAndReviewWorkOrder.useMutation({
      onSuccess: () => {
        utils.workOrders.invalidate();
        qc.invalidateQueries({
          predicate: (qry) => {
            return (
              (qry.queryKey[0] as string).includes("work-order") ||
              (qry.queryKey[0] as string).includes("defect")
            );
          },
        });
        setShowCloseDialog(false);
        form.reset();
      },
    });

  const handleClose = async (workOrderStatus: DefectStatus) => {
    if (workOrderStatus.name === "Close" && isWorkOrderPopUpEnabled) {
      setSelectedStatus(workOrderStatus);
      setShowCloseDialog(true);
      return;
    }

    if (
      workOrderStatus &&
      workOrder &&
      user &&
      activeProj &&
      workOrder.id &&
      activeProj.id
    ) {
      updateWorkStatus({
        defectId: workOrder.id,
        defectStatusId: workOrderStatus.id,
        projectId: activeProj.id,
      });
    }
    setAnchorEl(null);
  };

  const onSubmitClose = async (data: {rating: number, closingRemark: string}) => {
    if (!workOrder?.id || !activeProj?.id) return;

    await closeAndReview({
      workOrderId: workOrder.id,
      projectId: activeProj.id,
      closingRemark: data.closingRemark,
      rating: data.rating,
    });
  };

  if (isClose && !hasEditAfterClosePolicy) return <></>;

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" data-testid="update-workorder-status-pen">
            {updateWorkStatusIsLoading || isFetching ? (
              <Loader2 className="h-6 w-6 animate-spin text-slate-800" />
            ) : (
              <Pencil className="text-primary-900" />
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          {workOrderStatuses
            ?.filter((sta) => sta?.id !== workOrder?.defectStatusId)
            ?.map((status) => {
              return (
                <DropdownMenuItem
                  key={status.id}
                  onClick={() => handleClose(status)}
                >
                  <div className="flex gap-4 items-center">
                    <div
                      className={cn("h-5 w-5 rounded")}
                      style={{ backgroundColor: `#${status.colorHex}` }}
                    ></div>
                    <p className="font-sans">{status.name}</p>
                  </div>
                </DropdownMenuItem>
              );
            })}
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={showCloseDialog} onOpenChange={setShowCloseDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Close Work Order</DialogTitle>
          </DialogHeader>

          <Form {...form}>
            <div
              className="space-y-4"
            >
              <AppTextField
                name="closingRemark"
                label="Closing Remark"
                placeholder="Enter closing remark"
              />

              <div className="space-y-2">
                <label className="text-sm font-medium">Rating</label>
                <Controller
                  defaultValue={5}
                  control={form.control}
                  name="rating"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <div className="flex">
                        <Rating
                          name="Quality Rating"
                          value={value}
                          size="large"
                          onChange={(event, newValue) => {
                            onChange(newValue);
                          }}
                        />
                      </div>
                    );
                  }}
                />
              </div>

              <DialogFooter>
                <AppButton
                  variant="ghost"
                  onClick={() => setShowCloseDialog(false)}
                  label="Cancel"
                />
                <AppButton 
                  isLoading={isClosing}
                  label="Close Work Order"
                  onClick={form.handleSubmit(onSubmitClose)}
                />
              </DialogFooter>
            </div>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
}
