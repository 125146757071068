import { Bell } from "lucide-react";
import { trpc } from "../../../lib/trpc";
import { useAppSelector } from "../../../redux/store";
import AppButton from "../../../components/AppButton";
import { useSearchParams } from "react-router-dom";
import { UPCOMING_WORK_ORDER_DRAWER } from "../../../components/drawers/AppDrawer";
import { useSelectWorkOrderFilterStore } from "../../../stores/workOrder/workOrderFilterStore";
import useDebounce from "../../../hooks/useDebounce";
import { RouterInputs } from "@cerev-cmms/trpc";

type GetUpcomingWorkOrdersInput = RouterInputs["workOrders"]["getUpcomingWorkOrders"];

export default function WorkOrderScreenMenu() {
  const [searchParams, setSearchParams] = useSearchParams();
  const project = useAppSelector((state) => state.root.activeProject);

  // Get all filters from the store
  const locationFilter = useSelectWorkOrderFilterStore(
    (state) => state.filterLocation
  );
  const dateRange = useSelectWorkOrderFilterStore((state) => state.dateRange);
  const assetTypesFilter = useSelectWorkOrderFilterStore(
    (state) => state.assetTypes
  );
  const usersFilter = useSelectWorkOrderFilterStore((state) => state.raisedBy);
  const workOrderTypeFilter = useSelectWorkOrderFilterStore(
    (state) => state.workOrderType
  );
  const workTrades = useSelectWorkOrderFilterStore((state) => state.workTrades);
  const priorities = useSelectWorkOrderFilterStore((state) => state.priorities);

  // Create debounced query arguments
  const queryArg = useDebounce<GetUpcomingWorkOrdersInput>({
    value: {
      projectId: project?.id?.toString() ?? "",
      locFilterId: locationFilter?.id.toString(),
      assetTypeIds: assetTypesFilter.map((at) => at?.id?.toString() ?? "0"),
      filterStartDateISO: dateRange?.from?.toISOString(),
      filterEndDateISO: dateRange?.to?.toISOString(),
      filterRaisedByIds: usersFilter.map((u) => u?.id?.toString() ?? "0"),
      filterWorkOrderTypeIds: workOrderTypeFilter.map(
        (wo) => wo?.id?.toString() ?? "0"
      ),
      filterWorkTradeIds: workTrades.map((wt) => wt?.id?.toString() ?? "0"),
      filterPriorityIds: priorities.map((p) => p?.id?.toString() ?? "0"),
    },
    delay: 500,
  });

  // Fetch upcoming work orders count
  const { data: upcomingWorkOrders, isLoading } =
    trpc.workOrders.getUpcomingWorkOrders.useQuery(
      queryArg,
      {
        enabled: !!project,
      }
    );

  const upcomingCount = upcomingWorkOrders?.totalCount || "0";

  const navigateToUpcomingWorkOrderDrawer = () => {
    setSearchParams((prev) => {
      prev.set("drawerType", UPCOMING_WORK_ORDER_DRAWER);
      return prev;
    });
  };

  return (
    <AppButton
      variant="outline"
      icon={<Bell className="h-5 w-5" />}
      label={`Upcoming (${upcomingCount})`}
      isLoading={isLoading}
      onClick={navigateToUpcomingWorkOrderDrawer}
    />
  );
} 