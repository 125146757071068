import { useParams } from "react-router-dom";
import { trpc } from "../../../../lib/trpc";
import { Switch } from "../../../ui/switch";
import { useMultipleEquipmentPmEnabled } from "../../../../hooks/useFeatures";
import { Loader2 } from "lucide-react";
import { FeatureTypeEnum } from "@cerev-cmms/zod-types";
import { toast } from "sonner";

export default function MultipleEquipmentPmToggleSwitch() {
    const util = trpc.useUtils();
    const { projectId } = useParams();
    const multipleEquipmentPmEnabled = useMultipleEquipmentPmEnabled({
      projectId: projectId as string,
    });
  
    const { mutate: toggleSwitch, isPending } = trpc.featureToggle.toggleFeature.useMutation({
      onSuccess: () => {
        util.featureToggle.getFeatures.invalidate();
        util.featureToggle.isFeatureEnabled.invalidate();
        toast("Successfully updated");
      },
    });
  
    return (
      <div className="flex items-center gap-4 mt-2">
        <Switch
          className="data-[state=checked]:bg-primary-900"
          checked={multipleEquipmentPmEnabled ?? false}
          onCheckedChange={() => {
            toggleSwitch({
              projectId: projectId as string,
              featureType: FeatureTypeEnum.enum.MULTIPLE_EQUIPMENT_PM,
            });
          }}
        />
        {isPending && (
          <Loader2 className="h-6 w-6 animate-spin text-primary-900" />
        )}
      </div>
    );
}
