import { TabsTrigger } from "./tabs";
import { trpc } from "../../lib/trpc";
import { useAppSelector } from "../../redux/store";

interface TabTriggerWithCountProps {
  value: string;
  label: string;
  statusId?: number;
  locationId?: string | null;
  assetId?: string | null;
  isUpcoming?: boolean;
}

export function TabTriggerWithCount({
  value,
  label,
  statusId,
  locationId,
  assetId,
  isUpcoming,
}: TabTriggerWithCountProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const queryFn = isUpcoming
    ? trpc.pm.getUpcomingPmChecklist.useQuery
    : trpc.pm.getPmChecklists.useQuery;

  const { data: pmChecklists } = queryFn(
    {
      projectId: activeProj?.id?.toString() ?? "",
      ...(statusId && { pmChecklistStatusId: statusId.toString() }),
      ...(locationId && { locFilterId: locationId }),
      ...(assetId && { assetId }),
    },
    {
      enabled: !!activeProj,
    }
  );

  return (
    <TabsTrigger value={value}>
      {label} ({pmChecklists?.totalCount ?? 0})
    </TabsTrigger>
  );
} 