import { dynamicFieldSchema } from "@cerev-cmms/zod-types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import { useAuthControllerGetCurrentUser } from "../../../api/auth/auth";
import {
  useLocationControllerPublicShowLocation
} from "../../../api/location/location";
import { useWorkRequestControllerCreateWorkRequestInternally } from "../../../api/work-request/work-request";
import useAppStorage from "../../../hooks/useAppStorage";
import useIsUpdateDrawerState from "../../../hooks/useDrawerState";
import { trpc } from "../../../lib/trpc";
import { Attachment } from "../../../redux/slices/OpenApi/cerevApi";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import AppButton from "../../AppButton";
import AppCameraFieldFile from "../../AppCameraFieldFiles";
import AppTextAreaField from "../../AppTextAreaField";
import AppTextField from "../../AppTextField";
import AppSelectWithDialog from "../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import DynamicField from "../../DynamicField/DynamicField";
import { Checkbox } from "../../ui/checkbox";
import { Separator } from "../../ui/separator";
import { DRAWER_UPDATE_STATE } from "../AppDrawer";
import WorkRequestSelectAssetDialog from "./components/WorkRequestSelectAssetDialog";

type DynamicFieldType = z.infer<typeof dynamicFieldSchema>;
type DynamicFieldValue = string | string[];

const isDynamicFieldRequired = (
  fieldId: string,
  dynamicFields?: any
): boolean => {
  return (
    (dynamicFields as { fields: DynamicFieldType[] })?.fields?.find(
      (field: DynamicFieldType) => field.id === fieldId
    )?.required ?? false
  );
};

const createInternalWorkRequestSchema = (workRequestSetting: any) =>
  z.object({
    email: z.string().email({ message: "Please input a proper email" }),
    contactNo: z
      .string()
      .min(6, { message: "Please input your contact number" }),
    location: z.string().min(1, { message: "Please indicate the location" }),
    title: z.string().min(1, { message: "Please add a title" }),
    description: z
      .string()
      .min(1, { message: "Please add a description of the problem" }),
    photos: z.instanceof(File).array().optional(),
    dbLocation: z.object({
      id: z.number(),
      name: z.string(),
    }),
    asset: z
      .object({
        id: z.number(),
        name: z.string(),
      })
      .optional(),
    dynamicFields: z
      .record(
        z.union([
          z.string().superRefine((val, ctx) => {
            const fieldId = (ctx.path as string[])[1];
            if (
              isDynamicFieldRequired(fieldId, workRequestSetting?.dynamicFields)
            ) {
              if (val.length === 0) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: "This is required",
                });
              }
              return val.length > 0;
            }
            return true;
          }),
          z.any().superRefine((val, ctx) => {
            const fieldId = (ctx.path as string[])[1];
            if (
              isDynamicFieldRequired(fieldId, workRequestSetting?.dynamicFields)
            ) {
              if (val.length === 0) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: "Please select at least one option",
                });
              }
              return val.length > 0;
            }
            return true;
          }),
        ])
      )
      .optional(),
  });

export default function WorkRequestFormDrawer() {
  const dispatch = useAppDispatch();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const [searchParam, setSearchParams] = useSearchParams();
  const [uploadedPhotos, setUploadedPhotos] = useState<Attachment[]>([]);
  const isUpdateDrawer = useIsUpdateDrawerState();

  const { data: workRequestSetting } =
    trpc.workRequest.getOneWorkRequestSetting.useQuery(
      {
        projectId: activeProj?.id ?? 0,
      },
      {
        enabled: !!activeProj?.id,
        select: (res) => res.data,
      }
    );

  const methods = useForm<
    z.infer<ReturnType<typeof createInternalWorkRequestSchema>>
  >({
    resolver: zodResolver(createInternalWorkRequestSchema(workRequestSetting)),
    defaultValues: {
      email: "",
      contactNo: "",
      location: "",
      title: "",
      description: "",
      photos: [],
      dynamicFields: {},
    },
  });

  useEffect(() => {
    if (workRequestSetting) {
      methods.setValue(
        "dynamicFields",
        (
          (workRequestSetting?.dynamicFields as { fields: DynamicFieldType[] })
            ?.fields ?? []
        ).reduce<Record<string, DynamicFieldValue>>((acc, field) => {
          acc[field.id] =
            (field.type === "select" || field.type === "multiselect") ? [] : "";
          return acc;
        }, {})
      );
    }
  }, [workRequestSetting]);

  const qc = useQueryClient();

  const { data: user } = useAuthControllerGetCurrentUser();
  const { mutateAsync: createWorkRequest } =
    useWorkRequestControllerCreateWorkRequestInternally();

  const { useUploadAttachmentMutation } = useAppStorage();
  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (query) => {
          return (query.queryKey[0] as string).includes("work-request");
        },
      });
      setSearchParams(new URLSearchParams());
    },
  });

  const { data: fullLocationData } = useLocationControllerPublicShowLocation(
    activeProj?.workRequestCode ?? "",
    {
      query: {
        enabled: !!activeProj,
      },
    }
  );

  const onSubmit: SubmitHandler<
    z.infer<ReturnType<typeof createInternalWorkRequestSchema>>
  > = async (data) => {
    await mutate({
      files: data.photos ?? [],
      mutateAsync: async (atts) => {
        const dynamicFieldValues = data.dynamicFields
          ? Object.fromEntries(
              Object.entries(data.dynamicFields).map(([key, value]) => [
                key,
                JSON.stringify(value),
              ])
            )
          : undefined;

        await createWorkRequest({
          data: {
            email: data.email,
            contactNo: data.contactNo,
            location: data.location,
            title: data.title,
            description: data.description,
            photos: atts,
            projectCode: activeProj?.workRequestCode ?? "",
            dbLocationId: data.dbLocation?.id?.toString(),
            assetId: data.asset?.id?.toString(),
            dynamicFieldValues,
          },
        });
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {isUpdateDrawer ? "Update" : "Create"} Work Request
        </p>
        <AppTextField label="Email" name="email" />
        <AppTextField label="Contact No." name="contactNo" />
        <AppSelectWithDialog
          label="Select Location"
          columns={[
            {
              header: "",
              id: "select",
              cell: ({ row, table }) => (
                <Checkbox
                  checked={row.getIsSelected()}
                  onCheckedChange={(value) => {
                    row.toggleSelected(!!value);
                  }}
                  aria-label="Select row"
                />
              ),
              enableSorting: false,
              enableHiding: false,
            },
            {
              accessorKey: "name",
              header: "Name",
            },
            {
              accessorKey: "code",
              header: "Code",
            },
          ]}
          placeholder="Select Location"
          control={methods.control}
          defaultValue={undefined}
          name="dbLocation"
          items={fullLocationData?.data ?? []}
          onResultRender={(item, idx) => (
            <div className="font-medium">{item?.name ?? "-"}</div>
          )}
          onOptionsRender={(item, idx) => (
            <div className="font-medium">{item?.name ?? "-"}</div>
          )}
          dialogTitle="Select Location"
          error={!!methods.formState.errors.dbLocation?.message}
          helperText={methods.formState.errors.dbLocation?.message}
        />
        <WorkRequestSelectAssetDialog />
        <AppTextField label="Location" name="location" />
        <AppTextField label="Title" name="title" />
        <AppTextAreaField label="Description" name="description" />

        <Controller
          control={methods.control}
          name="photos"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppCameraFieldFile
                label="Photos"
                onChange={onChange}
                onDelete={(url) => {
                  const newSetPhotos = value?.filter((v) => v !== url);
                  onChange(newSetPhotos);
                }}
                photos={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.photos?.message !== ""
                    ? "At least one photo required"
                    : ""
                }
                uploadedPhotos={uploadedPhotos}
                onDeleteUploadedPhoto={async (pho) => {}}
              />
            );
          }}
        />
        <Separator className="mt-10" />
        <p className="text-xl font-bold">Additional Fields</p>
        {(workRequestSetting?.dynamicFields as any)?.fields?.map(
          (field: any) => (
            <DynamicField
              key={field.id}
              field={field}
              control={methods.control}
              formState={methods.formState}
            />
          )
        )}

        <AppButton
          isLoading={isPending}
          label={
            searchParam.get("drawerState") === DRAWER_UPDATE_STATE
              ? "Update"
              : "Create"
          }
          className="w-40 mt-4"
          onClick={methods.handleSubmit(onSubmit)}
        />
      </div>
    </FormProvider>
  );
}
