import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
  useEscalationControllerCreateEscalation,
  useEscalationControllerDeleteEscalation,
  useEscalationControllerGetOneEscalation,
  useEscalationControllerUpdateEscalation,
} from "../../../api/escalation/escalation";
import { useUserControllerGetUsers } from "../../../api/users/users";
import useCurrentUser from "../../../hooks/useCurrentUser/useCurrentUser";
import useIsUpdateDrawerState from "../../../hooks/useDrawerState";
import { useAppSelector } from "../../../redux/store";
import AppButton from "../../AppButton";
import AppTextField from "../../AppTextField";
import AppSelectWithDialog from "../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import ConfirmDeleteBtnWithDialog from "../../dialogs/ConfirmDeleteWithTextDialog";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import { assignedUserColumns } from "../workOrders/columns/assignedUserColumns";
import { EscalationForm, EscalationFormSchema } from "./EscalationFormSchema";
import { useQueryClient } from "@tanstack/react-query";
import EscalationTypeSelectField from "./components/EscalationTypeSelectField";
import EscalationSelectStatusField, {
  escalationWorkRequestStatus,
} from "./components/EscalationSelectStatusField";
import EscalationSettingSummary from "./components/EscalationSettingSummary";
import { EscalationType } from "../../../api/model";

export default function EscalationFormDrawer() {
  const qc = useQueryClient();
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const isUpdateDrawer = useIsUpdateDrawerState();
  const [searchParams, setSearchParams] = useSearchParams();
  const escalationId = searchParams.get("escalationId");
  const { data: user } = useCurrentUser();

  const methods = useForm<EscalationForm>({
    resolver: zodResolver(EscalationFormSchema),
    defaultValues: {
      name: "",
      numberOfDays: 0,
      escalationStatuses: {
        escalationType: EscalationType.WORK_ORDER,
        workOrderStatuses: [],
        pmStatuses: [],
        workRequestStatuses: [],
      },
      notifyUsers: [],
    },
  });

  const { data: usersData } = useUserControllerGetUsers(
    {
      projectId: activeProj?.id ?? 0,
      userId: user?.id ?? 0,
      companyId: activeComp?.id ?? 0,
    },
    {
      query: {
        enabled: !!activeProj && !!user && !!activeComp,
      },
    }
  );

  const { data: editEscalation, isLoading: editEscalationIsLoading } =
    useEscalationControllerGetOneEscalation(
      escalationId ?? "",
      {
        projectId: activeProj?.id ?? 0,
      },
      {
        query: {
          enabled: !!escalationId && !!activeProj,
          select: (res) => res.data.data,
        },
      }
    );

  const { mutate: createEscalation, isPending: createEscalationIsPending } =
    useEscalationControllerCreateEscalation({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) => (q.queryKey[0] as string).includes("escalation"),
          });
          setSearchParams(new URLSearchParams());
        },
      },
    });

  const { mutate: updateEscalation, isPending: updateEscalationIsPending } =
    useEscalationControllerUpdateEscalation({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) => (q.queryKey[0] as string).includes("escalation"),
          });
          setSearchParams(new URLSearchParams());
        },
      },
    });

  const { mutateAsync: deleteEscalation } =
    useEscalationControllerDeleteEscalation({
      mutation: {
        onSuccess: () => {
          qc.invalidateQueries({
            predicate: (q) => (q.queryKey[0] as string).includes("escalation"),
          });
          setSearchParams(new URLSearchParams());
        },
      },
    });

  // is edit mode
  useEffect(() => {
    if (editEscalation && isUpdateDrawer) {
      methods.reset({
        name: editEscalation.name ?? "",
        numberOfDays: editEscalation.numberOfDays ?? 0,
        escalationStatuses: {
          escalationType: editEscalation.escalationType,
          workOrderStatuses: editEscalation.defectStatus,
          pmStatuses: editEscalation.pmStatus,
          workRequestStatuses: editEscalation.workRequestStatus.map((s) => ({
            id:
              escalationWorkRequestStatus.find(
                (ews) => ews.value === s.workRequestStatus
              )?.id ?? 0,
            name: s.workRequestStatus,
            value: escalationWorkRequestStatus.find(
              (ews) => ews.value === s.workRequestStatus
            )?.value,
          })),
        },
        notifyUsers: editEscalation.notifyUsers?.map((usr) => ({
          id: usr.id ?? 0,
          name: usr.name ?? "",
        })),
      });
    }
  }, [editEscalation, searchParams]);

  const onSubmit: SubmitHandler<EscalationForm> = (data) => {
    if (!activeProj || !activeProj.id) return;
    if (isUpdateDrawer) {
      if (!editEscalation || !editEscalation.id) return;
      return updateEscalation({
        escalationId: editEscalation.id.toString(),
        data: {
          name: data.name,
          numberOfDays: data.numberOfDays,
          notifyUsersId: data.notifyUsers.map((nu) => nu.id),
          projectId: activeProj.id,
          escalationType: data.escalationStatuses.escalationType,
          workOrderStatusIds: data.escalationStatuses.workOrderStatuses.map(
            (s) => s.id
          ),
          pmStatusIds: data.escalationStatuses.pmStatuses.map((s) => s.id),
          workRequestStatuses: data.escalationStatuses.workRequestStatuses.map(
            (s) => s.value
          ),
        },
      });
    }
    createEscalation({
      data: {
        name: data.name,
        numberOfDays: data.numberOfDays,
        notifyUsersId: data.notifyUsers.map((u) => u.id),
        escalationType: data.escalationStatuses.escalationType,
        projectId: activeProj?.id ?? 0,
        workOrderStatusIds: data.escalationStatuses.workOrderStatuses.map(
          (s) => s.id
        ),
        pmStatusIds: data.escalationStatuses.pmStatuses.map((s) => s.id),
        workRequestStatuses: data.escalationStatuses.workRequestStatuses.map(
          (s) => s.value
        ),
      },
    });
  };

  if (isUpdateDrawer && editEscalationIsLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className="font-sans text-2xl font-bold">
            {isUpdateDrawer ? "Update" : "Create"} Escalations
          </div>
          <div className="text-sm text-gray-400 font-sans leading-relaxed">
            Escalation will send email to selected users when certain work
            order, preventive maintenance or work request remains unchanged
          </div>
        </div>
        <AppTextField label="Name *" name="name" />
        <EscalationTypeSelectField />
        <EscalationSelectStatusField />
        <AppTextField
          label="Number of days *"
          name="numberOfDays"
          type="number"
        />
        <AppSelectWithDialog
          multiple
          label="Users to notify *"
          placeholder="Select Users"
          defaultValue={[]}
          control={methods.control}
          columns={assignedUserColumns} // Same columns to be displayed in the dialog
          name="notifyUsers"
          items={usersData?.data ?? []}
          onResultRender={(item, idx) => (
            <div className="font-medium">{item?.name ?? "-"}</div>
          )}
          onOptionsRender={(item, idx) => (
            <div className="font-medium">{item?.name ?? "-"}</div>
          )}
          dialogTitle="Select users to be notified"
          error={!!methods.formState.errors.notifyUsers?.message}
          helperText={methods.formState.errors.notifyUsers?.message}
        />
        <EscalationSettingSummary />
        <div className="flex gap-4">
          <AppButton
            isLoading={createEscalationIsPending || updateEscalationIsPending}
            label={isUpdateDrawer ? "Update" : "Create"}
            onClick={methods.handleSubmit(onSubmit)}
          />
          {isUpdateDrawer && (
            <ConfirmDeleteBtnWithDialog
              confirmDeleteTxt={editEscalation?.name ?? "-"}
              onDeleteConfirm={async () => {
                if (editEscalation && editEscalation.id) {
                  deleteEscalation({
                    escalationId: editEscalation.id.toString(),
                    params: {
                      projectId: activeProj?.id ?? 0,
                    },
                  });
                }
              }}
            />
          )}
        </div>
      </div>
    </FormProvider>
  );
}
