import { useSearchParams } from "react-router-dom";
import { useSelectWorkOrderFilterStore } from "../../../../stores/workOrder/workOrderFilterStore";
import AppButton from "../../../AppButton";
import { AppDateRangePicker } from "../../../AppDateRangePicker";
import SelectLocationFilterDialog from "../../../dialogs/SelectLocationFilterDialog/SelectLocationFilterDialog";
import { useAssetTypeControllerGetAssetType } from "../../../../api/asset-type/asset-type";
import { useAppSelector } from "../../../../redux/store";
import { useUserControllerGetUsers } from "../../../../api/users/users";
import { useAuthControllerGetCurrentUser } from "../../../../api/auth/auth";
import AppUncontrolledWithDialog from "../../../dialogs/AppUncontrolledSelectWithDialog/AppUncontrolledSelectWtihDialog";
import { selectAssetTypeColumn } from "../columns/selectAssetTypeColumns";
import { selectUsersColumns } from "../columns/selectUsersColumns";
import WorkOrderTypeFilterField from "./components/WorkOrderTypeFilterField";
import { workTradeColumns } from "../columns/workTradeColumns";
import { workPriorityColumns } from "../columns/workPriorityColumns";
import { trpc } from "@/lib/trpc";
import { useWoPriorityEnabled } from "../../../../hooks/useFeatures";
import { useWoTradeEnabled } from "../../../../hooks/useFeatures";

export default function WorkOrderFilterDrawer() {
  const [, setSearchParams] = useSearchParams();
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const filterLocation = useSelectWorkOrderFilterStore(
    (state) => state.filterLocation
  );
  const setFilterLocation = useSelectWorkOrderFilterStore(
    (state) => state.setFilterLocation
  );
  const setDateRange = useSelectWorkOrderFilterStore(
    (state) => state.setDateRange
  );
  const dateRange = useSelectWorkOrderFilterStore((state) => state.dateRange);
  const clearAll = useSelectWorkOrderFilterStore((state) => state.cleanUp);
  const usersFilter = useSelectWorkOrderFilterStore((state) => state.raisedBy);
  const setUsersFilter = useSelectWorkOrderFilterStore(
    (state) => state.setRaisedBy
  );
  const assetTypesFilter = useSelectWorkOrderFilterStore(
    (state) => state.assetTypes
  );
  const setAssetTypes = useSelectWorkOrderFilterStore(
    (state) => state.setAssetTypes
  );
  const workTrades = useSelectWorkOrderFilterStore((state) => state.workTrades);
  const setWorkTrades = useSelectWorkOrderFilterStore((state) => state.setWorkTrades);
  const priorities = useSelectWorkOrderFilterStore((state) => state.priorities);
  const setPriorities = useSelectWorkOrderFilterStore((state) => state.setPriorities);

  const { data: currentUser } = useAuthControllerGetCurrentUser();

  const { data: assetTypes } = useAssetTypeControllerGetAssetType(
    {
      projectId: activeProj?.id?.toString() ?? "",
      companyId: activeComp?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj && !!activeComp,
      },
    }
  );

  const { data: usersData } = useUserControllerGetUsers(
    {
      projectId: activeProj?.id ?? 0,
      companyId: activeComp?.id ?? 0,
      userId: currentUser?.data?.id ?? 0,
    },
    {
      query: {
        enabled: !!activeProj && !!activeComp && !!currentUser,
      },
    }
  );

  const { data: workTradesData, isLoading: isWorkTradesLoading } = trpc.workOrders.getWorkTrades.useQuery(
    { projectId: Number(activeProj?.id) },
    { enabled: !!activeProj }
  );

  const { data: prioritiesData, isLoading: isPrioritiesLoading } = trpc.workOrders.getWorkOrderPriorities.useQuery(
    { projectId: Number(activeProj?.id) },
    { enabled: !!activeProj }
  );

  const isWorkTradeEnabled = useWoTradeEnabled();
  const isWorkPriorityEnabled = useWoPriorityEnabled();

  return (
    <div className="flex flex-col gap-6">
      <h2 className="font-sans text-2xl font-bold">Filter By</h2>
      <div className="flex flex-col gap-1">
        <p className="text-slate-400">Location</p>
        <SelectLocationFilterDialog
          currentFilter={filterLocation}
          onApplyFilter={(location) => {
            setFilterLocation(location);
          }}
        />
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-slate-400">Date Range</p>
        <AppDateRangePicker
          date={dateRange}
          setDate={setDateRange}
          onClear={() => {
            setDateRange(undefined);
          }}
        />
      </div>
      <AppUncontrolledWithDialog
        placeholder="Select Equipment Type"
        label="Equipment Type"
        items={assetTypes?.data ?? []}
        onResultRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        onOptionsRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        dialogTitle="Select Equipment Type"
        columns={selectAssetTypeColumn}
        multiple
        value={assetTypesFilter}
        onChange={(value) => {
          if (Array.isArray(value)) setAssetTypes(value);
        }}
      />
      <WorkOrderTypeFilterField />
      <AppUncontrolledWithDialog
        placeholder="Select Users..."
        label="Select Created By"
        items={usersData?.data ?? []}
        onResultRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        onOptionsRender={(item, idx) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        dialogTitle="Select Created By"
        columns={selectUsersColumns}
        multiple
        value={usersFilter}
        onChange={(value) => {
          if (Array.isArray(value)) setUsersFilter(value);
        }}
      />
      {isWorkTradeEnabled && (
        <AppUncontrolledWithDialog
          placeholder="Select Work Trade..."
          label="Work Trade"
          items={workTradesData ?? []}
        onResultRender={(item) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        onOptionsRender={(item) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        dialogTitle="Select Work Trade"
        columns={workTradeColumns}
        multiple
        value={workTrades}
        onChange={(value) => {
          if (Array.isArray(value)) setWorkTrades(value);
          }}
        />
      )}
      {isWorkPriorityEnabled && (
        <AppUncontrolledWithDialog
          placeholder="Select Priority..."
          label="Priority"
          items={prioritiesData ?? []}
        onResultRender={(item) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        onOptionsRender={(item) => (
          <div className="font-medium">{item?.name ?? "-"}</div>
        )}
        dialogTitle="Select Priority"
        columns={workPriorityColumns}
        multiple
        value={priorities}
          onChange={(value) => {
            if (Array.isArray(value)) setPriorities(value);
          }}
        />
      )}
      <div className="flex gap-2">
        <AppButton
          label="Confirm"
          onClick={() => {
            setSearchParams(() => {
              return new URLSearchParams();
            });
          }}
        />
        <AppButton
          label="Clear"
          variant="outline"
          onClick={() => {
            clearAll();
          }}
        />
      </div>
    </div>
  );
}
