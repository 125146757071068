import { ColumnDef } from "@tanstack/react-table";
import { RouterOutputs } from "@cerev-cmms/trpc";
import { Checkbox } from "../../../../../components/ui/checkbox";

type WorkOrderStatus =
  RouterOutputs["workOrders"]["getWorkOrderStatus"][number];

export const workOrderStatusColumns: ColumnDef<WorkOrderStatus>[] = [
  {
    accessorKey: "id",
  },
  {
    header: "",
    id: "select",
    cell: ({ row, table }) => {
      return (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            row.toggleSelected(!!value);
          }}
          aria-label="Select row"
        />
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "name",
    header: "Status",
  },
];
