import { ColumnDef } from "@tanstack/react-table";
import { Checkbox } from "../../../../../components/ui/checkbox";

interface WorkRequestStatus {
  id: number;
  name: string;
}

export const workRequestStatusColumns: ColumnDef<WorkRequestStatus>[] = [
  {
    header: "",
    id: "select",
    cell: ({ row, table }) => {
      return (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            row.toggleSelected(!!value);
          }}
          aria-label="Select row"
        />
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "name",
    header: "Status",
  },
];
