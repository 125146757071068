import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import { Attachment, CreateStockDtoInventoryMode } from "../../../api/model";
import { useStockTypesControllerGetStockTypes } from "../../../api/stock-inventory-types/stock-inventory-types";
import {
  useStocksControllerCreateStock,
  useStocksControllerDeleteDoc,
  useStocksControllerDeleteInventory,
  useStocksControllerDeletePhoto,
  useStocksControllerGetOneStockWithLedger,
  useStocksControllerUpdateStock,
} from "../../../api/stocks-inventory/stocks-inventory";
import useAppStorage from "../../../hooks/useAppStorage";
import useIsUpdateDrawerState from "../../../hooks/useDrawerState";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import AppButton from "../../AppButton";
import AppCameraFieldFile from "../../AppCameraFieldFiles";
import AppTextAreaField from "../../AppTextAreaField";
import AppTextField from "../../AppTextField";
import AppSelectWithDialog from "../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { selectInventoryTypeColumns } from "./columns/selectInventoryTypeColumns";
import AppSelect from "@/components/AppSelect";
import InventorySerialsFormSection from "@/components/drawers/inventory/components/InventorySerialsFormSection";
import {
  useInventoryLocationEnabled,
  useSerialiedInventoryEnabled,
} from "../../../hooks/useFeatures";
import { useLocationControllerGetFullLocationList } from "../../../api/location/location";
import { selectLocationColumns } from "./columns/selectLocationColumn";
import { FormDescription, FormItem, FormLabel } from "../../ui/form";
import { Input } from "../../ui/input";
import InventoryLocationGuard from "../../../guards/InventoryLocationGuard";
import AppDocumentUpload from "../../AppDocumentUpload";
import WorkOrderPricingPolicyGuard from "../../../guards/WorkOrderPricingPolicyGuard";
import ConfirmDeleteBtnWithDialog from "../../dialogs/ConfirmDeleteWithTextDialog";
import { toast } from "sonner";
import { INVENTORY_DETAIL_DRAWER } from "../AppDrawer";
import MultipleCurrencyInventoryGuard from "../../../guards/MutipleCurrencyInventoryGuard";

const InventoryFormSchema = z
  .object({
    name: z.string().min(1, "Name is required"),
    startingCount: z.coerce.number().optional(),
    balance: z.coerce.number().optional(),
    description: z.string().optional(),
    inventoryType: z.object(
      {
        id: z.number(),
        name: z.string(),
      },
      { required_error: "Inventory type is required" }
    ),
    location: z
      .object({
        id: z.number(),
        name: z.string(),
      })
      .optional(),
    photos: z.instanceof(File).array().optional(),
    docs: z.instanceof(File).array().optional(),
    mode: z.enum(["create", "update"]).optional().default("create"),
    inventoryMode: z
      .enum([
        CreateStockDtoInventoryMode.QUANTITY,
        CreateStockDtoInventoryMode.SERIALIZED,
      ])
      .default(CreateStockDtoInventoryMode.QUANTITY),
    serials: z
      .object({
        name: z.string().min(1, "Serial number is required"),
      })
      .array(),
    unitPricing: z.coerce.number().optional(),
    usPricing: z.coerce.number().optional(),
    twdPricing: z.coerce.number().optional(),
  })
  .refine(
    // Checking serialized inventory matches with starting count, if its under creation mode.
    (schema) => {
      return !(
        schema.mode === "create" &&
        schema.inventoryMode === CreateStockDtoInventoryMode.SERIALIZED &&
        schema.serials.length !== schema.startingCount
      );
    },
    {
      path: ["serials"],
      message:
        "The number of serials does not match with inventory initial count",
    }
  )
  .refine(
    //   Check serialized inventory serial number matches with balance count, if updating
    (schema) => {
      return !(
        schema.mode === "update" &&
        schema.inventoryMode === CreateStockDtoInventoryMode.SERIALIZED &&
        schema.serials.length !== schema.balance
      );
    },
    {
      path: ["serials"],
      message: "The number of serials does not match with inventory balance",
    }
  );

export type InventoryForm = z.infer<typeof InventoryFormSchema>;

export default function InventoryFormDrawer() {
  const qc = useQueryClient();
  const serialInvEnabled = useSerialiedInventoryEnabled();
  const inventoryLocationEnabled = useInventoryLocationEnabled();
  const [uploadedPhotos, setUploadedPhotos] = useState<Attachment[]>([]);
  const [uploadedDocs, setUploadedDocs] = useState<Attachment[]>([]);
  const [searchParam, setSearchParams] = useSearchParams();
  const isUpdateDrawer = useIsUpdateDrawerState();
  const editInventoryId = searchParam.get("inventoryId");
  const dispatch = useAppDispatch();
  const methods = useForm<InventoryForm>({
    resolver: zodResolver(InventoryFormSchema),
    defaultValues: {
      name: "",
      startingCount: 0,
      description: "",
      inventoryType: undefined,
      photos: [],
      docs: [],
      inventoryMode: CreateStockDtoInventoryMode.QUANTITY,
      serials: [],
      unitPricing: 0,
      usPricing: 0,
      twdPricing: 0,
    },
  });

  const { mutateAsync: deleteInventory } = useStocksControllerDeleteInventory({
    mutation: {
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (query) => (query.queryKey[0] as string).includes("stock"),
        });

        setSearchParams(new URLSearchParams());
        toast.success("Inventory deleted successfully");
      },
    },
  });

  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data: inventoryTypes } = useStockTypesControllerGetStockTypes(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
      },
    }
  );

  const { data: fullLocationList } = useLocationControllerGetFullLocationList(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
      },
    }
  );

  const { useUploadAttachmentMutation } = useAppStorage();

  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      qc.invalidateQueries({
        predicate: (qry) => (qry.queryKey[0] as string).includes("stock"),
      });

      if (isUpdateDrawer) {
        setSearchParams((p) => {
          p.set("drawerType", INVENTORY_DETAIL_DRAWER);
          return p;
        });
      } else {
        setSearchParams((param) => {
          param.delete("drawerType");
          param.delete("drawerState");
          return { ...param };
        });
      }

      toast.success("Inventory Created / Updated Successfully");
    },
  });

  const { mutateAsync: createInventory } = useStocksControllerCreateStock();

  const { mutateAsync: updateInventory } = useStocksControllerUpdateStock();

  const { mutateAsync: deletePhoto } = useStocksControllerDeletePhoto();

  const { mutateAsync: deleteDoc } = useStocksControllerDeleteDoc();

  const { data: editInventory } = useStocksControllerGetOneStockWithLedger(
    editInventoryId as string,
    {
      query: {
        enabled: !!editInventoryId,
      },
    }
  );

  const onDeleteUploadedPhoto = async (att: Attachment) => {
    const newUploadedPhotos = uploadedPhotos.filter((v) => v.id !== att.id);
    if (!editInventory) return;
    deletePhoto({
      attachmentId: att?.id?.toString() ?? "",
      stockId: editInventory?.data?.id?.toString(),
    });
    setUploadedPhotos(newUploadedPhotos);
  };

  const onDeleteUploadedDoc = async (att: Attachment) => {
    const newUploadedDocs = uploadedDocs.filter((v) => v.id !== att.id);
    if (!editInventory) return;
    deleteDoc({
      attachmentId: att?.id?.toString() ?? "",
      inventoryId: editInventory?.data?.id?.toString(),
    });
    setUploadedDocs(newUploadedDocs);
  };

  const onSubmit: SubmitHandler<InventoryForm> = async (data) => {
    mutate({
      files: data.photos ?? [],
      secondFiles: data.docs ?? [],
      mutateAsync: async (atts, docs) => {
        if (isUpdateDrawer) {
          await updateInventory({
            stockId: editInventory?.data?.id?.toString() ?? "",
            data: {
              name: data.name,
              projectId: activeProj?.id?.toString() ?? "-",
              stockTypeId: data.inventoryType.id.toString(),
              description: data.description ?? "-",
              photos: atts,
              inventoryMode: data.inventoryMode,
              serials: data.serials.map((s) => s.name),
              unitPricing: data.unitPricing,
              docs: docs ?? [],
              usPricing: data.usPricing,
              twdPricing: data.twdPricing,
            },
          });
        } else {
          await createInventory({
            data: {
              name: data.name,
              projectId: activeProj?.id?.toString() ?? "-",
              stockTypeId: data.inventoryType.id.toString(),
              initialCount: data.startingCount?.toString() ?? "0",
              description: data.description ?? "-",
              photos: atts,
              inventoryMode: data.inventoryMode,
              serials: data.serials.map((s) => s.name),
              unitPricing: data.unitPricing,
              locationId: data.location?.id,
              docs: docs ?? [],
              usPricing: data.usPricing,
              twdPricing: data.twdPricing,
            },
          });
        }
      },
    });
  };

  // isEdit Mode
  useEffect(() => {
    if (isUpdateDrawer && editInventory) {
      const {
        name,
        description,
        stockType,
        photos,
        inventoryMode,
        serials,
        balance,
        docs,
      } = editInventory.data;
      methods.setValue("name", name);
      methods.setValue("description", description);
      methods.setValue("inventoryType", stockType);
      setUploadedPhotos(photos);
      setUploadedDocs(docs);
      methods.setValue("mode", "update");
      methods.setValue("inventoryMode", inventoryMode);
      methods.setValue("unitPricing", editInventory?.data?.unitPrice ?? 0);
      methods.setValue("usPricing", editInventory?.data?.usPricing ?? 0);
      methods.setValue("twdPricing", editInventory?.data?.twdPricing ?? 0);
      methods.setValue(
        "serials",
        serials?.map((s) => ({ name: s.serial }))
      );
      methods.setValue("balance", balance ?? 0);
    }
  }, [editInventory]);

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {isUpdateDrawer ? "Update" : "Create"} Inventory
        </p>
        <AppTextField name="name" label="Name" />
        {!isUpdateDrawer && (
          <AppTextField
            label="Starting Count"
            type="number"
            name="startingCount"
          />
        )}
        <WorkOrderPricingPolicyGuard>
          <AppTextField name="unitPricing" label="Unit Pricing (RM)" />
          <MultipleCurrencyInventoryGuard>
            <AppTextField name="usPricing" label="US Pricing (USD)" />
            <AppTextField name="twdPricing" label="TWD Pricing (TWD)" />
          </MultipleCurrencyInventoryGuard>
        </WorkOrderPricingPolicyGuard>
        <AppTextAreaField label="Description" name="description" />
        <InventoryLocationGuard>
          {isUpdateDrawer ? (
            <div>
              <FormItem>
                <FormLabel>Location</FormLabel>
                <Input
                  value={editInventory?.data?.ledgers[0]?.location?.name ?? "-"}
                  disabled
                />
                <FormDescription className="font-semibold text-red-400 text-sm my-4">
                  Not able to update location here, please do through stock
                  adjustment feature
                </FormDescription>
              </FormItem>
            </div>
          ) : (
            <AppSelectWithDialog
              label="Location (optional)"
              placeholder="Select Location"
              defaultValue={undefined}
              control={methods.control}
              columns={selectLocationColumns}
              name="location"
              items={fullLocationList?.data ?? []}
              onResultRender={(item, idx) => {
                return <div key={idx}>{item?.name}</div>;
              }}
              onOptionsRender={(item, idx) => <div key={idx}>{item?.name}</div>}
              dialogTitle="Select Location"
              error={!!methods.formState.errors.location?.message}
              helperText={methods.formState.errors.location?.message}
            />
          )}
        </InventoryLocationGuard>
        <AppSelectWithDialog
          label="Inventory Type"
          placeholder="Select Inventory Type"
          defaultValue={undefined}
          control={methods.control}
          columns={selectInventoryTypeColumns}
          name="inventoryType"
          items={inventoryTypes?.data ?? []}
          onResultRender={(item, idx) => {
            return <div key={idx}>{item?.name}</div>;
          }}
          onOptionsRender={(item, idx) => <div key={idx}>{item?.name}</div>}
          dialogTitle="Select Inventory Type"
          error={!!methods.formState.errors.inventoryType?.message}
          helperText={methods.formState.errors.inventoryType?.message}
        />
        {serialInvEnabled && (
          <AppSelect
            className={"my-3"}
            label={"Inventory Mode"}
            name={"inventoryMode"}
            defaultValue={CreateStockDtoInventoryMode.QUANTITY}
            selections={[
              {
                label: "Quantified",
                value: CreateStockDtoInventoryMode.QUANTITY,
              },
              {
                label: "Serialized",
                value: CreateStockDtoInventoryMode.SERIALIZED,
              },
            ]}
          />
        )}
        <InventorySerialsFormSection />
        <Controller
          control={methods.control}
          name="photos"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppCameraFieldFile
                uploadedPhotos={uploadedPhotos}
                onDeleteUploadedPhoto={onDeleteUploadedPhoto}
                label="Photos"
                onChange={onChange}
                onDelete={(url) => {
                  if (!value) return;
                  const newSetPhotos = value.filter((v) => v !== url);
                  onChange(newSetPhotos);
                }}
                photos={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.photos?.message !== ""
                    ? "At least one photo required"
                    : ""
                }
              />
            );
          }}
        />
        <Controller
          control={methods.control}
          name="docs"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppDocumentUpload
                uploadedDocs={uploadedDocs}
                onDeleteUploadedDoc={onDeleteUploadedDoc}
                label="Additional Documents"
                onChange={onChange}
                onDelete={(url) => {
                  if (!value) return;
                  const newFiles = value.filter((v) => v !== url);
                  onChange(newFiles);
                }}
                files={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.docs?.message !== ""
                    ? methods.formState.errors.docs?.message
                    : ""
                }
              />
            );
          }}
        />
        <div className="flex gap-4">
          <AppButton
            isLoading={isPending}
            label={isUpdateDrawer ? "Update" : "Create"}
            onClick={methods.handleSubmit(onSubmit)}
          />
          {isUpdateDrawer && (
            <ConfirmDeleteBtnWithDialog
              confirmDeleteTxt={editInventory?.data?.name ?? ""}
              onDeleteConfirm={async () => {
                await deleteInventory({
                  stockId: editInventory?.data?.id ?? 0,
                });
              }}
            />
          )}
        </div>
      </div>
    </FormProvider>
  );
}
