import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { addMonths, format, parse, parseISO } from "date-fns";
import {
  ArrowDown,
  ArrowUp,
  Hash,
  Image,
  Text,
  ToggleLeft,
  Trash2,
} from "lucide-react";
import { useEffect } from "react";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
  FrequencySettingType,
  PmFormatType,
  type PmFormatTypeEnum,
} from "@cerev-cmms/zod-types";
import { trpc } from "../../../lib/trpc";
import useIsUpdateDrawerState from "../../../hooks/useDrawerState";
import { useAppSelector } from "../../../redux/store";
import AppButton from "../../AppButton";
import AppTextField from "../../AppTextField";
import AppSelectWithDialog from "../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { Button } from "../../ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import { useToast } from "../../ui/use-toast";
import { pmTemplateAssetColumns } from "./pmTemplateAssetColumns";
import AppCronPicker from "./components/AppCronPicker";
import FormatType from "./components/FormatType";
import {
  PmTemplateFormSchema,
  PmTemplateFormType,
} from "./PmTemplateFormSchema";
import AppSelect from "../../AppSelect";
import MultipleEquipmentPmGuard from "../../../guards/MultipleEquipmentPmGuard";

export default function PmTemplateFormDrawer() {
  const qc = useQueryClient();
  const { toast } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const isUpdateDrawer = useIsUpdateDrawerState();
  const pmTemplateId = searchParams.get("pmTemplateId");
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data: user } = trpc.user.me.useQuery();
  const utils = trpc.useUtils();

  const methods = useForm<PmTemplateFormType>({
    resolver: zodResolver(PmTemplateFormSchema),
    defaultValues: {
      name: "",
      pmFormat: [],
      assets: [],
      startDate: format(new Date(), "yyyy-MM-dd"),
      pmTemplateType: "ONE_EQUIPMENT",
      frequencySetting: {
        frequencySettingType: FrequencySettingType.DAILY,
        weeklyRepeatDay: [],
        monthlyRepeatDate: [],
        monthlyRepeatDay: [],
        monthlyEveryStepCount: 1,
        yearlyRepeatDate: [],
        yearlyRepeatMonth: [],
        yearlyEveryStepCount: 1,
      },
    },
  });

  const { fields, append, remove, swap, update } = useFieldArray({
    control: methods.control,
    name: "pmFormat",
  });

  const { data: editTemplate } = trpc.pm.getSinglePmTemplate.useQuery(
    { pmTemplateId: pmTemplateId ?? "" },
    {
      enabled: !!pmTemplateId,
      select: (res) => {
        const sortedTemplates = [...(res.pmFormat ?? [])].sort(
          (a, b) => a.order - b.order
        );
        return { ...res, pmFormat: sortedTemplates };
      },
    }
  );

  const { data: pmFormatTypesData } = trpc.pm.getPmFormatTypes.useQuery();

  const {
    mutate: createPmTemplate,
    isPending: createPmTemplateIsPending,
    isError: createPmTemplateIsError,
    isSuccess: createPmTemplateIsSuccess,
  } = trpc.pm.createPmTemplate.useMutation({
    onSuccess: () => {
      qc.invalidateQueries({
        predicate: (query) => {
          return (
            (query.queryKey[0] as string).includes("pm-template") ||
            (query.queryKey[0] as string).includes("pm-checklist")
          );
        },
      });
      utils.pm.invalidate();

      // Navigate back to the list page
      const newParam = new URLSearchParams();
      setSearchParams(newParam);
    },
  });

  const { data: fullAssetList } = trpc.assets.getFullAssets.useQuery({
    projectId: activeProj?.id ?? 0,
  });

  const {
    mutate: updatePmTemplate,
    isPending: updatePmTemplateIsPending,
    isError: updatePmTemplateIsError,
    isSuccess: updatePmTemplateIsSuccess,
  } = trpc.pm.updatePmTemplate.useMutation({
    onSuccess: () => {
      qc.invalidateQueries({
        predicate: (query) => {
          return (
            (query.queryKey[0] as string).includes("pm-template") ||
            (query.queryKey[0] as string).includes("pm-checklist")
          );
        },
      });

      utils.pm.invalidate();

      // Navigate back to the list page
      const newParam = new URLSearchParams();
      setSearchParams(newParam);
    },
  });

  const onSubmit: SubmitHandler<PmTemplateFormType> = async (data) => {
    if (pmTemplateId) {
      if (!editTemplate)
        return toast({
          title: "Error",
          description: "No template is selected",
          variant: "destructive",
        });
      return updatePmTemplate({
        pmTemplateId,
        name: data.name,
        pmTemplateType: data.pmTemplateType,
        updatedById: user?.id ?? 0,
        companyId: activeComp?.id ?? 0,
        projectId: activeProj?.id ?? 0,
        assetIds: data.assets.map((ast) => ast.id),
        startDate: parse(data.startDate, "yyyy-MM-dd", new Date()),
        pmFormats: data.pmFormat.map((fmt, idx) => ({
          id: Number(fmt.id ?? 0),
          name: fmt.name,
          order: idx,
          pmFormatType: {
            name: fmt.pmFormatType.name,
          },
        })),
        frequencySettingType: data.frequencySetting.frequencySettingType,
        weeklyRepeatDay: data.frequencySetting.weeklyRepeatDay,
        monthlyRepeatDate: data.frequencySetting.monthlyRepeatDate,
        monthlyRepeatDay: data.frequencySetting.monthlyRepeatDay,
        monthlyEveryStepCount: data.frequencySetting.monthlyEveryStepCount,
        yearlyRepeatDate: data.frequencySetting.yearlyRepeatDate,
        yearlyRepeatMonth: data.frequencySetting.yearlyRepeatMonth,
        yearlyEveryStepCount: data.frequencySetting.yearlyEveryStepCount,
        dateStartISO: parse(
          data.startDate,
          "yyyy-MM-dd",
          new Date()
        ).toISOString(),
      });
    }

    createPmTemplate({
      name: data.name,
      createdById: user?.id ?? 0,
      pmTemplateType: data.pmTemplateType,
      projectId: activeProj?.id ?? 0,
      companyId: activeComp?.id ?? 0,
      assetIds: data.assets.map((ast) => ast.id),
      pmFormats: data.pmFormat.map((fmt, idx) => ({
        name: fmt.name,
        order: idx,
        pmFormatType: {
          name: fmt.pmFormatType.name,
        },
      })),
      frequencySettingType: data.frequencySetting.frequencySettingType,
      weeklyRepeatDay: data.frequencySetting.weeklyRepeatDay,
      monthlyRepeatDate: data.frequencySetting.monthlyRepeatDate,
      monthlyRepeatDay: data.frequencySetting.monthlyRepeatDay,
      monthlyEveryStepCount: data.frequencySetting.monthlyEveryStepCount,
      yearlyRepeatDate: data.frequencySetting.yearlyRepeatDate,
      yearlyRepeatMonth: data.frequencySetting.yearlyRepeatMonth,
      yearlyEveryStepCount: data.frequencySetting.yearlyEveryStepCount,
      dateStartISO: parse(
        data.startDate,
        "yyyy-MM-dd",
        new Date()
      ).toISOString(),
    });
  };

  useEffect(() => {
    if (editTemplate) {
      methods.reset({
        name: editTemplate?.name ?? "",
        startDate: editTemplate?.dateStart
          ? format(new Date(editTemplate?.dateStart), "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        assets:
          editTemplate?.asset?.map((a) => ({
            ...a,
            locationId: a.locationId ?? 0,
          })) ?? [],
        pmTemplateType: editTemplate?.pmTemplateType ?? "ONE_EQUIPMENT",
        pmFormat: editTemplate?.pmFormat.map((fmt) => ({
          id: fmt.id.toString(),
          name: fmt.name,
          pmFormatType: {
            name: fmt.pmFormatType.name,
          },
        })),
        frequencySetting: {
          frequencySettingType:
            editTemplate?.frequencySetting?.frequencySetting,
          weeklyRepeatDay:
            editTemplate?.frequencySetting?.weeklyRepeatDay?.split(","),
          monthlyRepeatDay:
            editTemplate?.frequencySetting?.monthlyRepeatDay?.split(","),
          monthlyRepeatDate:
            editTemplate?.frequencySetting?.monthlyRepeatDate?.split(","),
          monthlyEveryStepCount:
            editTemplate?.frequencySetting?.monthlyEveryStepCount ?? undefined,
          yearlyRepeatMonth:
            editTemplate?.frequencySetting?.yearlyRepeatMonth?.split(","),
          yearlyRepeatDate:
            editTemplate?.frequencySetting?.yearlyRepeatDate?.split(","),
          yearlyEveryStepCount:
            editTemplate?.frequencySetting?.yearlyEveryStepCount ?? undefined,
        },
      });
    }
  }, [editTemplate]);

  const pmTemplateTypeSelections = [
    { label: "One Equipment", value: "ONE_EQUIPMENT" },
    { label: "Multiple Equipment", value: "MULTIPLE_EQUIPMENTS" },
  ];

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {isUpdateDrawer ? "Update PM Template" : "Create PM Template"}
        </p>
        <div className="flex flex-col gap-6">
          <AppTextField
            name="name"
            label="Name of the checklist"
            description="Please input the name of your checklist"
          />
          <MultipleEquipmentPmGuard>
            <AppSelect
              label="Template Type"
              name="pmTemplateType"
              placeholder="Select template type"
              selections={pmTemplateTypeSelections}
              defaultValue={pmTemplateTypeSelections[0].value}
            />
          </MultipleEquipmentPmGuard>
          <AppTextField label="Start Date" name="startDate" type="date" />
          {/* <AppTextField label="End Date" name="endDate" type="date" /> */}
          <AppSelectWithDialog
            multiple
            label="Equipment"
            placeholder="Select an Equipment"
            columns={pmTemplateAssetColumns}
            defaultValue={undefined}
            control={methods.control}
            name="assets"
            items={fullAssetList ?? []}
            onResultRender={(item, idx) => (
              <div key={idx} className="flex flex-col">
                <div className="font-medium">{item?.name}</div>
                <div className="font-thin mt-1">
                  {item?.assetType?.name ?? "-"}
                </div>
              </div>
            )}
            onOptionsRender={(item, idx) => (
              <div key={idx} className="flex flex-col">
                <div className="font-medium">{item?.name}</div>
                <div className="font-thin mt-1">
                  {item?.assetType?.name ?? "-"}
                </div>
              </div>
            )}
            dialogTitle="Select an Equipment"
            error={!!methods.formState.errors.assets?.message}
            helperText={methods.formState.errors.assets?.message}
          />
          <AppCronPicker label="Frequency" />
          <p className="font-medium">Fields</p>
          {fields.map((field, index) => {
            return (
              <div
                className="flex flex-col gap-2 p-5 rounded-xl bg-gray-50"
                key={field.id}
                data-testid={`pm-field-${index}`}
              >
                <div className="flex justify-between items-center">
                  <FormatType formatType={field.pmFormatType?.name} />
                  <div className="flex items-center gap-2">
                    <Button
                      data-testid="delete-btn"
                      className="p-2"
                      variant="ghost"
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      <Trash2 className="w-5 h-5 text-red-500" />
                    </Button>
                    {index - 1 >= 0 && (
                      <Button
                        data-testid={`pm-field-up-${index}`}
                        className="p-2"
                        variant="ghost"
                        onClick={() => {
                          swap(index, index - 1);
                        }}
                      >
                        <ArrowUp className="w-5 h-5" />
                      </Button>
                    )}
                    {index + 1 < fields.length && (
                      <Button
                        data-testid={`pm-field-down-${index}`}
                        className="p-2"
                        variant="ghost"
                        onClick={() => {
                          swap(index, index + 1);
                        }}
                      >
                        <ArrowDown className="w-5 h-5" />
                      </Button>
                    )}
                  </div>
                </div>
                <AppTextField
                  name={`pmFormat.${index}.name`}
                  label="Question"
                  key={field.id}
                />
              </div>
            );
          })}
          {methods.formState.errors.pmFormat?.message && (
            <p className="text-red-500">
              {methods.formState.errors.pmFormat?.message}
            </p>
          )}
          <div className="flex">
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  className="text-primary-900 hover:text-primary-900 border-primary-900"
                  variant="outline"
                >
                  + Insert New
                </Button>
              </PopoverTrigger>
              <PopoverContent className="flex gap-4 w-full" side="right">
                <Button
                  className="flex gap-2 items-center"
                  variant="outline"
                  onClick={() => {
                    const idenFormatType = pmFormatTypesData?.find(
                      (fmt) => fmt.name === PmFormatType.BOOL
                    );
                    if (!idenFormatType) return;
                    append({
                      name: "",
                      pmFormatType: idenFormatType,
                    });
                  }}
                >
                  <ToggleLeft className="h-4 w-4 text-primary-900" />
                  Yes / No
                </Button>
                <Button
                  className="flex gap-2 items-center"
                  variant="outline"
                  onClick={() => {
                    const idenFormatType = pmFormatTypesData?.find(
                      (fmt) => fmt.name === PmFormatType.VARCHAR
                    );
                    if (!idenFormatType) return;

                    append({
                      name: "",
                      pmFormatType: idenFormatType,
                    });
                  }}
                >
                  <Text className="h-4 w-4 text-primary-900" />
                  Text
                </Button>
                <Button
                  data-testid="add-image-btn"
                  className="flex gap-2 items-center"
                  variant="outline"
                  onClick={() => {
                    const idenFormatType = pmFormatTypesData?.find(
                      (fmt) => fmt.name === PmFormatType.ATTACHMENT
                    );
                    if (!idenFormatType) return;
                    append({
                      name: "",
                      pmFormatType: idenFormatType,
                    });
                  }}
                >
                  <Image className="h-4 w-4 text-primary-900" />
                  Image
                </Button>
                <Button
                  variant="outline"
                  className="flex gap-2 items-center"
                  onClick={() => {
                    const idenFormatType = pmFormatTypesData?.find(
                      (fmt) => fmt.name === PmFormatType.NUMBER
                    );
                    if (!idenFormatType) return;
                    append({
                      name: "",
                      pmFormatType: idenFormatType,
                    });
                  }}
                >
                  <Hash className="h-4 w-4 text-primary-900" />
                  Number
                </Button>
              </PopoverContent>
            </Popover>
          </div>
        </div>
        <AppButton
          label={isUpdateDrawer ? "Update" : "Submit"}
          isLoading={createPmTemplateIsPending || updatePmTemplateIsPending}
          onClick={methods.handleSubmit(onSubmit)}
        />
      </div>
    </FormProvider>
  );
}
