import { useSearchParams } from "react-router-dom";
import {
  usePmChecklistControllerGetOnePmChecklistQuery,
  usePmChecklistStatusControllerGetAllPmChecklistStatusQuery,
} from "../../../redux/slices/OpenApi/cerevApi";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import { useAppSelector } from "../../../redux/store";
import PmDetailTab from "./tabs/PmDetailTab";
import useAppTabsNavigation from "../../../hooks/useAppTabsNavigation";
import { Tabs, TabsContent, TabsList } from "../../ui/tabs";
import { TabTriggerWithCount } from "../../ui/TabTriggerWithCount";
import UpcomingPmDetailTab from "./tabs/UpcomingPmDetailTab";
import { useEffect } from "react";

export default function PmDetailDrawer() {
  const [searchParams] = useSearchParams();
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const locationId = searchParams.get("locationId");
  const assetId = searchParams.get("assetId");

  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "pmDetailTabId",
    defaultValue: "upcoming",
  });

  const { data: pmStatusList, isLoading: pmStatusIsLoading } =
    usePmChecklistStatusControllerGetAllPmChecklistStatusQuery(
      {
        companyId: activeComp?.id ?? 0,
      },
      {
        skip: !activeComp,
      }
    );

  if (pmStatusIsLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4 relative">
      <p className="font-sans text-2xl font-bold">PM Detail</p>
      <Tabs value={currentTabIdx} onValueChange={onTabChange}>
        <TabsList>
          <TabTriggerWithCount
            value="0"
            label="Upcoming"
            locationId={locationId}
            assetId={assetId}
            isUpcoming
          />
          {pmStatusList?.map((pmStat, idx) => (
            <TabTriggerWithCount
              key={pmStat.id}
              value={(idx + 1).toString()}
              label={pmStat.name}
              statusId={pmStat.id}
              locationId={locationId}
              assetId={assetId}
            />
          ))}
        </TabsList>
        <TabsContent value="0">
          <UpcomingPmDetailTab />
        </TabsContent>
        {pmStatusList?.map((pmStat, idx) => (
          <TabsContent value={(idx + 1).toString()} key={pmStat.id}>
            <PmDetailTab pmStatus={pmStat} />
          </TabsContent>
        ))}
      </Tabs>
    </div>
  );
}
