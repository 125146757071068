import { ColumnDef, Row } from "@tanstack/react-table";
import useDate from "../../../../hooks/useDate";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_VIEW_STATE,
  PM_CHECKLIST_DETAIL_DRAWER,
} from "../../../../components/drawers/AppDrawer";
import { Avatar, AvatarFallback } from "../../../../components/ui/avatar";
import AppFullLocation from "../../../../components/AppFullLocation";
import { RouterOutputs } from "@cerev-cmms/trpc";
import { useState } from "react";
import { AssetListDialog } from "../../../../components/dialogs/AssetListDialog";
import AppButton from "../../../../components/AppButton";

type PmChecklist = RouterOutputs["pm"]["getPmChecklists"]["data"][number];

const PmRefNoNav = ({ pm }: { pm: Row<PmChecklist> }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div
      className="underline decoration-primary-900 text-primary-900 hover:cursor-pointer"
      onClick={() => {
        setSearchParams((p) => {
          p.set("drawerType", PM_CHECKLIST_DETAIL_DRAWER);
          p.set("drawerState", DRAWER_VIEW_STATE);
          p.set("pmChecklistId", pm.getValue("id"));
          return p;
        });
      }}
    >
      {pm.getValue("templateName") ?? "-"}
    </div>
  );
};

const PmDateCell = ({
  row,
}: {
  row: Row<PmChecklist>;
}) => {
  const { formatDateToLocal } = useDate();

  return (
    <div>
      {formatDateToLocal({
        dateStr: row.original.createdOn.toISOString(),
        formatting: "d MMM yyyy",
      })}
    </div>
  );
};

const AssetCell = ({ row }: { row: Row<PmChecklist> }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isMultipleEquipments = row.original.pmTemplate.pmTemplateType === 'MULTIPLE_EQUIPMENTS';

  if (isMultipleEquipments) {
    return (
      <div>
        <AppButton
          variant="outline"
          onClick={() => setIsDialogOpen(true)}
          label={`${row.original.multipleAssets?.length ?? 0} Equipments`}
        />
        <AssetListDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          assets={row.original.multipleAssets ?? []}
        />
      </div>
    );
  }

  return <div>{row.original.asset?.name ?? "-"}</div>;
};

const LocationCell = ({ row }: { row: Row<PmChecklist> }) => {
  const isMultipleEquipments = row.original.pmTemplate.pmTemplateType === 'MULTIPLE_EQUIPMENTS';

  if (isMultipleEquipments) {
    return <div>-</div>;
  }

  return <AppFullLocation locationId={row.getValue("locationId")} />;
};

export const pmChecklistColumns: ColumnDef<PmChecklist>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    id: "templateName",
    accessorKey: "pmTemplate.name",
    header: "Checklist Name",
    cell: ({ row }) => <PmRefNoNav pm={row} />,
  },
  {
    accessorKey: "createdOn",
    header: "Date",
    cell: ({ row }) => <PmDateCell row={row} />,
  },
  {
    id: "asset",
    header: "Equipment",
    cell: ({ row }) => <AssetCell row={row} />,
  },
  {
    id: "locationId",
    accessorKey: "asset.locationId",
    header: "Location",
    cell: ({ row }) => <LocationCell row={row} />,
  },
  {
    id: "updatedByName",
    accessorKey: "updatedBy.name",
    header: "Completed By",
    cell: ({ row }) => (
      <div className="flex gap-2 items-center">
        <Avatar className="bg-primary-900 text-base">
          <AvatarFallback className="bg-transparent text-white">
            {row.original.updatedBy?.name?.substring(0, 2)?.toUpperCase() ?? "-"}
          </AvatarFallback>
        </Avatar>
        <p>{row.original.updatedBy?.name}</p>
      </div>
    ),
  },
  {
    id: "completedOn",
    accessorKey: "completedOn",
    header: "Completed On",
    cell: ({ row }) => {
      const { formatDateToLocal } = useDate();
      return (
        <div>
          {formatDateToLocal({
            dateStr: row.original.completedOn?.toISOString() ?? "",
            formatting: "d MMM yyyy",
          })}
        </div>
      );
    },
  },
];
