import { dynamicFieldSchema } from "@cerev-cmms/zod-types";
import { Control, Controller, FormState } from "react-hook-form";
import { z } from "zod";
import AppTextField from "../AppTextField";
import AppSelectWithDialog from "../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { Checkbox } from "../ui/checkbox";

type DynamicField = z.infer<typeof dynamicFieldSchema>;

interface DynamicFieldProps {
  field: DynamicField;
  control: Control<any>;
  formState: FormState<any>;
}

export default function DynamicField({
  field,
  control,
  formState,
}: DynamicFieldProps) {
  if (field.visibility === "HIDDEN") return null;

  switch (field.type) {
    case "text":
      return (
        <AppTextField
          key={field.id}
          label={`${field.label}${field.required ? " *" : " (Optional)"}`}
          name={`dynamicFields.${field.id}`}
        />
      );

    case "number":
      return (
        <AppTextField
          key={field.id}
          label={`${field.label}${field.required ? " *" : " (Optional)"}`}
          name={`dynamicFields.${field.id}`}
          type="number"
        />
      );

    case "date":
      return (
        <AppTextField
          key={field.id}
          label={`${field.label}${field.required ? " *" : " (Optional)"}`}
          name={`dynamicFields.${field.id}`}
          type="date"
        />
      );

    case "select":
    case "multiselect":
      return (
        <AppSelectWithDialog
          name={`dynamicFields.${field.id}`}
          control={control}
          defaultValue={[]}
          multiple={field.type === "multiselect"}
          label={`${field.label}${field.required ? " *" : " (Optional)"}`}
          placeholder={`Select ${field.label}`}
          columns={[
            {
              header: "",
              id: "select",
              cell: ({ row }) => (
                <Checkbox
                  checked={row.getIsSelected()}
                  onCheckedChange={(value) => {
                    row.toggleSelected(!!value);
                  }}
                  aria-label="Select row"
                />
              ),
              enableSorting: false,
              enableHiding: false,
            },
            {
              id: "name",
              header: "Options",
              accessorKey: "name",
            },
          ]}
          items={
            field.options?.map((opt) => ({
              id: opt,
              name: opt,
            })) || []
          }
          onResultRender={(item) => (
            <div className="font-medium">{item?.name ?? "-"}</div>
          )}
          onOptionsRender={(item) => (
            <div className="font-medium">{item?.name ?? "-"}</div>
          )}
          dialogTitle={`Select ${field.label}`}
          helperText={
            ((formState.errors["dynamicFields"] as any)?.[field.id] as any)?.message
          }
          error={!!((formState.errors["dynamicFields"] as any)?.[field.id] as any)}
        />
      );

    case "checkbox":
      return (
        <Controller
          key={field.id}
          name={`dynamicFields.${field.id}`}
          control={control}
          rules={{ required: field.required }}
          render={({ field: formField }) => (
            <div className="flex items-center gap-2">
              <Checkbox
                checked={formField.value as boolean}
                onCheckedChange={formField.onChange}
              />
              <span>{field.label}</span>
            </div>
          )}
        />
      );

    default:
      return null;
  }
}
