import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { useJkrPataWorkOrder } from "..";

export function InvestigationSection() {
  const workOrder = useJkrPataWorkOrder();

  return (
    <View style={{ border: "1px solid black" }} wrap={false}>
      <View style={{ backgroundColor: "#f0f0f0", padding: 4 }}>
        <Text style={{ fontSize: 9, fontWeight: "bold" }}>
          B. Arahan Siasatan
        </Text>
      </View>
      <View style={{ padding: 8, gap: 6 }}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <Text style={{ fontSize: 9 }}>Diterima Oleh: </Text>
            <View style={{ flex: 1 }}>
              <Text style={{ fontSize: 9, fontWeight: "bold" }}>
                {workOrder.assignedUsers
                  ?.map((user) => user.name)
                  .join(", ")}
              </Text>
            </View>
          </View>
          <View style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <Text style={{ fontSize: 9 }}>Ditugaskan Kepada: </Text>
            <View style={{ flex: 1 }}>
              <Text style={{ fontSize: 9, fontWeight: "bold" }}>
                {workOrder.assignedUsers
                  ?.map((user) => user.name)
                  .join(", ")}
              </Text>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <Text style={{ fontSize: 9 }}>Tarikh: </Text>
            <Text style={{ fontSize: 9, fontWeight: "bold" }}>
              {workOrder.createdOn}
            </Text>
          </View>
        </View>
        <View>
          <Text style={{ fontSize: 9 }}>Ulasan Kerosakan:</Text>
          <View
            style={{
              border: "1px solid black",
              minHeight: 60,
              marginTop: 4,
              padding: 4,
            }}
          >
            <Text style={{ fontSize: 9, fontWeight: "bold" }}>
              {workOrder.rootCause ?? "-"}
            </Text>
          </View>
        </View>
        <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <View>
            <Text style={{ fontSize: 9 }}>Tandatangan:</Text>
            <Text style={{ fontSize: 9, marginTop: 4, fontWeight: "bold" }}>
              Cap Nama & Jawatan
            </Text>
          </View>
          <Text style={{ fontSize: 9, fontWeight: "bold", flex: 1 }}>
            {workOrder.assignedUsers?.map((user) => user.name).join(", ")}
          </Text>
        </View>
      </View>
    </View>
  );
}
