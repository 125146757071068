import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { RouterInputs } from "@cerev-cmms/trpc";
import { Check, Plus, X } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../../components/ui/dialog";
import { Card, CardContent } from "../../../../../components/ui/card";
import AppButton from "../../../../../components/AppButton";
import AppTextField from "../../../../../components/AppTextField";
import AppSelect from "../../../../../components/AppSelect";
import { Switch } from "../../../../../components/ui/switch";
import { useFieldArray } from "react-hook-form";

export type DynamicField =
  NonNullable<NonNullable<RouterInputs["workRequest"]["updateWorkRequestSetting"]["dynamicFields"]>["fields"]>[number];

const fieldTypes = [
  { value: "text", label: "Text" },
  // { value: "number", label: "Number" },
  // { value: "date", label: "Date" },
  { value: "select", label: "Single Select" },
  { value: "multiselect", label: "Multi Select" },
  // { value: "checkbox", label: "Checkbox" },
] as const;

const formSchema = z.object({
  id: z.string(),
  label: z.string().min(1, "Label is required"),
  type: z.enum(["text", "number", "date", "select", "multiselect", "checkbox"]),
  required: z.boolean(),
  order: z.number().min(0),
  options: z.array(z.object({ value: z.string() })).optional(),
  visibility: z.enum(["VISIBLE", "HIDDEN"]),
}).refine((data) => {
  if (data.type === "select" || data.type === "multiselect") {
    return data.options && data.options?.length > 0;
  }
  return true;
}, {
  message: "Options are required for select and multiselect fields",
  path: ["options"],
});

type FormValues = z.infer<typeof formSchema>;

interface DynamicFieldDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  editingField: DynamicField | null;
  onSubmit: (values: DynamicField) => void;
  isPending: boolean;
}

export default function DynamicFieldDialog({
  open,
  onOpenChange,
  editingField,
  onSubmit,
  isPending,
}: DynamicFieldDialogProps) {
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: "",
      label: "",
      type: "text",
      required: false,
      order: 0,
      options: [],
      visibility: "VISIBLE",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "options",
  });

  const selectedType = methods.watch("type");
  const showOptions = selectedType === "select" || selectedType === "multiselect";

  const handleSubmit: SubmitHandler<FormValues> = (values) => {
    onSubmit({
      ...values,
      options: values.options?.map((opt) => opt.value),
    });
  };

  // Reset form when editing field changes
  useEffect(() => {
    if (editingField) {
      methods.reset({
        ...editingField,
        options: editingField.options?.map((opt: string) => ({ value: opt })) || [],
      });
    } else {
      methods.reset({
        id: "",
        label: "",
        type: "text",
        required: false,
        order: 0,
        options: [],
        visibility: "VISIBLE",
      });
    }
  }, [editingField, methods]);

  useEffect(() => {
    if (!open) methods.reset();
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="w-full max-w-2xl overflow-y-scroll max-h-screen flex flex-col justify-start">
        <DialogHeader>
          <DialogTitle>{editingField ? "Edit Field" : "Add New Field"}</DialogTitle>
          <DialogDescription>
            Adjust here for additional fields that you wish for your users to
            fill in as part of the work request
          </DialogDescription>
        </DialogHeader>

        <FormProvider {...methods}>
          <div className="space-y-6">
            <AppTextField name="label" label="Label" />
            
            <AppSelect
              name="type"
              label="Field Type"
              selections={fieldTypes.map(type => ({
                label: type.label,
                value: type.value
              }))}
              defaultValue={fieldTypes[0].value}
            />

            {showOptions && (
              <div className="space-y-2">
                <p className="text-sm font-medium">Options</p>
                {fields.map((field, index) => (
                  <div key={field.id} className="flex gap-2">
                    <AppTextField
                      name={`options.${index}.value`}
                      placeholder="Enter option"
                      className="w-full"
                    />
                    <AppButton
                      variant="ghost"
                      size="icon"
                      onClick={() => remove(index)}
                      icon={<X className="h-4 w-4" />}
                    />
                  </div>
                ))}
                <AppButton
                  variant="outline"
                  size="sm"
                  onClick={() => append({ value: "" })}
                  icon={<Plus className="h-4 w-4" />}
                >
                  Add Option
                </AppButton>
                {
                  methods.formState.errors.options && (
                    <p className="text-sm text-red-500">
                      {methods.formState.errors.options.message}
                    </p>
                  )
                }
              </div>
            )}

            <Card>
              <CardContent className="p-4 flex gap-4 items-center justify-between">
                <div>
                  <p className="text-lg font-medium">Required Field</p>
                  <p className="text-sm leading-relaxed text-slate-500">
                    Make this field mandatory for users to fill in
                  </p>
                </div>
                <Switch
                  className="data-[state=checked]:bg-primary-900"
                  checked={methods.watch("required")}
                  onCheckedChange={(checked) => methods.setValue("required", checked)}
                />
              </CardContent>
            </Card>

            <DialogFooter className="mt-6">
              <AppButton
                variant="ghost"
                onClick={() => onOpenChange(false)}
              >
                Cancel
              </AppButton>
              <AppButton
                onClick={methods.handleSubmit(handleSubmit)}
                isLoading={isPending}
                icon={<Check className="w-4 w-4" />}
              >
                {editingField ? "Update" : "Add"}
              </AppButton>
            </DialogFooter>
          </div>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
