import { useSearchParams } from "react-router-dom";
import StockAdjustmentDialog from "../dialog/StockAdjustmentDialog";
import { useStocksControllerGetOneStockWithLedger } from "../../../../api/stocks-inventory/stocks-inventory";
import BalanceInfo from "../components/BalanceInfo/BalanceInfo";
import AppItemInfo from "../../../AppItemInfo";
import AppMultiImage from "../../../AppMultiImage";
import {
  CreateStockDtoInventoryMode,
  StockEntityInventoryMode,
} from "../../../../api/model";
import {
  AlignLeft,
  Blocks,
  CircleDollarSign,
  FileText,
  Hash,
  Pencil,
  Tag,
} from "lucide-react";
import { Badge } from "../../../ui/badge";
import SerialNumberViewBtn from "../components/SerialNumberViewBtn";
import InventoryPolicyGuard from "../../../../guards/InventoryPolicyGuard";
import { Separator } from "../../../ui/separator";
import AppButton from "../../../AppButton";
import { DRAWER_UPDATE_STATE, INVENTORY_FORM_DRAWER } from "../../AppDrawer";
import ExportInventoryPdfBtn from "../components/ExportInventoryPdfBtn/ExportInventoryPdfBtn";
import { useState } from "react";
import InventoryLocationTab from "./InventoryLocationTab";
import AdjustWarningBalanceSection from "../components/AdjustWarningBalanceSection";
import InventoryLocationGuard from "../../../../guards/InventoryLocationGuard";
import AppDocument from "../../../AppDocument";
import WorkOrderPricingPolicyGuard from "../../../../guards/WorkOrderPricingPolicyGuard";
import MultipleCurrencyInventoryGuard from "../../../../guards/MutipleCurrencyInventoryGuard";

export default function InventoryInfoTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const inventoryId = searchParams.get("inventoryId");
  const [open, setIsOpen] = useState(false);

  const { data: inventory, isLoading } =
    useStocksControllerGetOneStockWithLedger(inventoryId ?? "", {
      query: {
        enabled: !!inventoryId,
      },
    });

  return (
    <div className="flex flex-col gap-4">
      <StockAdjustmentDialog open={open} handleClose={() => setIsOpen(false)} />
      {inventory?.data?.photos && inventory?.data?.photos?.length > 0 && (
        <AppMultiImage
          attachments={inventory?.data?.photos}
          className="w-full h-64"
        />
      )}
      <BalanceInfo />
      <AppItemInfo
        label="Name"
        content={<p className="font-sans">{inventory?.data?.name ?? "-"}</p>}
        icon={<Tag className="text-primary-900" />}
      />
      <AppItemInfo
        label="Description"
        content={
          <p className="font-sans">{inventory?.data?.description ?? "-"}</p>
        }
        icon={<AlignLeft className="text-primary-900" />}
      />
      {inventory?.data?.inventoryMode === StockEntityInventoryMode.QUANTITY && (
        <WorkOrderPricingPolicyGuard>
          <AppItemInfo
            label="Unit Pricing (RM)"
            icon={<CircleDollarSign className="text-primary-900" />}
            content={
              <div className={`flex`}>{inventory?.data?.unitPrice ?? "-"}</div>
            }
          />
          <MultipleCurrencyInventoryGuard>
            <AppItemInfo
              label="Unit Pricing (USD)"
              icon={<CircleDollarSign className="text-primary-900" />}
              content={
                <div className={`flex`}>
                  {inventory?.data?.usPricing ?? "-"}
                </div>
              }
            />
            <AppItemInfo
              label="Unit Pricing (TWD)"
              icon={<CircleDollarSign className="text-primary-900" />}
              content={
                <div className={`flex`}>
                  {inventory?.data?.twdPricing ?? "-"}
                </div>
              }
            />
          </MultipleCurrencyInventoryGuard>
        </WorkOrderPricingPolicyGuard>
      )}
      <AppItemInfo
        label={`Inventory Mode`}
        icon={<Blocks />}
        content={
          <div className={`flex`}>
            <Badge className={`bg-primary-900 mt-1`}>
              {inventory?.data?.inventoryMode}
            </Badge>
          </div>
        }
      />
      {inventory?.data?.inventoryMode ===
        CreateStockDtoInventoryMode.SERIALIZED && (
        <AppItemInfo
          label={`Serial Number`}
          icon={<Hash />}
          content={
            <div className={`mt-1`}>
              <SerialNumberViewBtn />
            </div>
          }
        />
      )}
      <AppItemInfo
        label="Additional Documents"
        icon={<FileText />}
        content={
          inventory?.data.docs && inventory?.data.docs.length > 0 ? (
            <div className="flex flex-col gap-4">
              {inventory?.data?.docs?.map((att) => (
                <AppDocument key={att.id} att={att} />
              ))}
            </div>
          ) : (
            <>-</>
          )
        }
      />
      <InventoryPolicyGuard>
        <div className="flex flex-col gap-4 mt-8">
          <div className="flex gap-4">
            <AppButton
              icon={<Pencil />}
              label="Edit Info"
              onClick={() => {
                setSearchParams((p) => {
                  p.set("drawerType", INVENTORY_FORM_DRAWER);
                  p.set("drawerState", DRAWER_UPDATE_STATE);
                  return p;
                });
              }}
            />
            <AppButton
              label="Stock Adjustment"
              icon={<Pencil />}
              onClick={() => {
                // if(inventory?.data?.inventoryMode === CreateStockDtoInventoryMode.SERIALIZED) return setSearchParams(p => {
                //   p.set("drawerType", ADJUST_SERIALIZED_INVENTORY_FORM_DRAWER);
                //   return p;
                // })
                setIsOpen(true);
              }}
            />
            <ExportInventoryPdfBtn />
          </div>
        </div>
      </InventoryPolicyGuard>
      <Separator className="my-6" />
      <AdjustWarningBalanceSection />
      <InventoryLocationGuard>
        {inventory?.data?.inventoryMode ===
        CreateStockDtoInventoryMode.SERIALIZED ? (
          <>
            <p className="text-sm text-slate-400">
              Location based inventory is not supported for serialized inventory
            </p>
          </>
        ) : (
          <>
            <Separator className="my-6" />
            <InventoryLocationTab />
          </>
        )}
      </InventoryLocationGuard>
    </div>
  );
}
