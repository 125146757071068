import { Bell } from "lucide-react";
import { trpc } from "../../../lib/trpc";
import { useAppSelector } from "../../../redux/store";
import AppButton from "../../../components/AppButton";
import { useSearchParams } from "react-router-dom";
import { UPCOMING_PM_CHECKLIST_DRAWER } from "../../../components/drawers/AppDrawer";
import { useSelectPmFilterStore } from "../../../stores/pm/pmFilterStore";
import useDebounce from "../../../hooks/useDebounce";
import { RouterInputs } from "@cerev-cmms/trpc";

type GetUpcomingPmChecklistInput = RouterInputs["pm"]["getUpcomingPmChecklist"];

export default function PmScreenMenu() {
  const [searchParams, setSearchParams] = useSearchParams();
  const project = useAppSelector((state) => state.root.activeProject);

  // Get filters from the store
  const dateRange = useSelectPmFilterStore((state) => state.dateRange);
  const assetTypesFilter = useSelectPmFilterStore((state) => state.assetTypes);
  const locationFilter = useSelectPmFilterStore(
    (state) => state.filterLocation
  );

  // Create debounced query arguments
  const queryArg = useDebounce<GetUpcomingPmChecklistInput>({
    value: {
      projectId: project?.id?.toString() ?? "",
      locFilterId: locationFilter?.id?.toString(),
      filterEndDateISO: dateRange?.to?.toISOString(),
      filterStartDateISO: dateRange?.from?.toISOString(),
      assetTypeIds: assetTypesFilter.map((at) => at?.id?.toString() ?? "0"),
    },
    delay: 500,
  });

  // Fetch upcoming PM checklists count
  const { data: upcomingPmChecklists, isLoading } =
    trpc.pm.getUpcomingPmChecklist.useQuery(
      queryArg,
      {
        enabled: !!project,
      }
    );

  const upcomingCount = upcomingPmChecklists?.totalCount || "0";

  const navigateToUpcomingPmchecklistDrawer = () => {
    setSearchParams((prev) => {
      prev.set("drawerType", UPCOMING_PM_CHECKLIST_DRAWER);
      return prev;
    });
  };

  return (
    <AppButton
      variant="outline"
      icon={<Bell className="h-5 w-5" />}
      label={`Upcoming (${upcomingCount})`}
      isLoading={isLoading}
      onClick={navigateToUpcomingPmchecklistDrawer}
    />
  );
}
