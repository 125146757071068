import { FormProvider, useForm, useWatch } from "react-hook-form";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
} from "../../../components/ui/sidebar";
import { ScrollArea } from "../../../components/ui/scroll-area";
import { useAppSelector } from "../../../redux/store";
import AppTextField from "../../../components/AppTextField";
import { Inbox, Search, X } from "lucide-react";
import useDebounce from "../../../hooks/useDebounce";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../components/ui/card";
import { useSearchParams } from "react-router-dom";
import { DRAWER_VIEW_STATE, WORK_ORDER_DETAIL_DRAWER } from "../../../components/drawers/AppDrawer";
import AppStatusBadge from "../../../components/badge/AppStatusBadge";
import AppButton from "../../../components/AppButton";
import { useWorkOrderV2ControllerGetWorkOrderBasedOnPlannedTime } from "../../../api/work-order-defect-v2/work-order-defect-v2";
import { WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeWorkOrderPlanType } from "../../../api/model";
import { useWorkOrderScheduleFilterStore } from "../../../stores/workOrderSchedule/workOrderScheduleFilterStore";
import { Draggable } from "@fullcalendar/interaction";
import { useEffect } from "react";
import { EventInput } from "@fullcalendar/core";
import UnplannedWorkOrderCard from "./UnplannedWorkOrderCard";

export default function WOSchedulerSidebar() {
  const [, setSearchParams] = useSearchParams();
  const activeProject = useAppSelector((state) => state.root.activeProject);
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });

  const search = useWatch({ control: methods.control, name: "search" });
  const assetTypeFilterIds = useWorkOrderScheduleFilterStore(
    (state) => state.assetTypes
  );
  const filterLocation = useWorkOrderScheduleFilterStore(
    (state) => state.filterLocation
  );
  const assetFilter = useWorkOrderScheduleFilterStore(
    (state) => state.filterAsset
  );

  const debouncedQuery = useDebounce({
    value: {
      projectId: activeProject?.id ?? 0,
      workOrderPlanType: WorkOrderV2ControllerGetWorkOrderBasedOnPlannedTimeWorkOrderPlanType.UNPLANNED,
      filterAssetTypeIds: assetTypeFilterIds.map((at) => at.id!),
      filterLocationId: filterLocation?.id,
      filterAssetId: assetFilter?.id,
      search,
    },
    delay: 500,
  });

  const { data: workOrders, isLoading } = useWorkOrderV2ControllerGetWorkOrderBasedOnPlannedTime(
    debouncedQuery,
    {
      query: {
        select: (res) =>
          res.data.data.map(
            (wo) =>
              ({
                id: wo.id,
                title: wo.subject,
                start: wo.plannedOn,
                extendedProps: {
                  ...wo,
                },
              } as EventInput)
          ),
        enabled: !!activeProject,
      },
    }
  );

  useEffect(() => {
    let draggable: Draggable;
    if (workOrders && workOrders?.length > 0) {
      let draggableEl = document.getElementById("draggables");
      if (!draggableEl) return;
      draggable = new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          const wo = JSON.parse(eventEl.getAttribute("data-event") as string);
          return {
            title: wo.extendedProps.subject,
            id: wo.extendedProps.id,
            extendedProps: wo.extendedProps,
            startTime: wo.extendedProps.plannedOn,
            endTime: wo.extendedProps.plannedEndOn,
          };
        },
      });
    }

    return () => {
      draggable?.destroy();
    };
  }, [workOrders]);

  return (
    <Sidebar 
      side="right" 
      className="bg-white border-l h-screen fixed top-0 right-0 z-10" 
      collapsible="none"
      style={{ "--sidebar-width": "400px" } as React.CSSProperties}
    >
      <SidebarHeader className="border-b p-4 bg-white">
        <h2 className="text-lg font-semibold text-gray-900">Unplanned Work Orders</h2>
        <FormProvider {...methods}>
          <AppTextField
            name="search"
            className="w-full mt-2 text-slate-900"
            placeholder="Search work orders..."
            leftIcon={<Search className="h-4 w-4 text-gray-500" />}
            rightIcon={
              search ? (
                <X
                  className="h-4 w-4 text-gray-500 hover:text-gray-900 cursor-pointer"
                  onClick={() => methods.reset({ search: "" })}
                />
              ) : undefined
            }
          />
        </FormProvider>
      </SidebarHeader>
      <SidebarContent className="bg-white">
        <ScrollArea className="h-[calc(100vh-120px)]">
          <div id="draggables" className="flex flex-col gap-3 p-4">
            {isLoading ? (
              <div className="flex justify-center items-center h-20">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : workOrders?.length === 0 ? (
              <div className="flex flex-col gap-4 mt-20 items-center">
                <Inbox className="text-gray-400 w-12 h-12" />
                <p className="text-gray-400">No unplanned work orders</p>
              </div>
            ) : (
              workOrders?.map((wo) => (
                <UnplannedWorkOrderCard key={wo.id} workOrder={wo} />
              ))
            )}
          </div>
        </ScrollArea>
      </SidebarContent>
    </Sidebar>
  );
} 