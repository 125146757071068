import { useFormContext, useWatch } from "react-hook-form";
import { DefectStatus } from "../../../redux/slices/OpenApi/cerevApi";
import { useAppSelector } from "../../../redux/store";
import { useSelectWorkOrderFilterStore } from "../../../stores/workOrder/workOrderFilterStore";
import useDebounce from "../../../hooks/useDebounce";
import { RouterInputs } from "@cerev-cmms/trpc";
import { trpc } from "../../../lib/trpc";

type GetWorkOrderCountInput = RouterInputs["workOrders"]["getWorkOrderCount"];

interface WorkOrderTabWithCountProps {
  workOrderStatus: DefectStatus;
}

export default function WorkOrderTabWithCount({
  workOrderStatus,
}: WorkOrderTabWithCountProps) {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const methods = useFormContext();

  const search = useWatch({ control: methods.control, name: "search" });
  const locationFilter = useSelectWorkOrderFilterStore(
    (state) => state.filterLocation
  );
  const workOrderTypeFilter = useSelectWorkOrderFilterStore(
    (state) => state.workOrderType
  );
  const dateRange = useSelectWorkOrderFilterStore((state) => state.dateRange);
  const assetTypesFilter = useSelectWorkOrderFilterStore(
    (state) => state.assetTypes
  );
  const usersFilter = useSelectWorkOrderFilterStore((state) => state.raisedBy);
  const workTrades = useSelectWorkOrderFilterStore((state) => state.workTrades);
  const priorities = useSelectWorkOrderFilterStore((state) => state.priorities);

  const queryArg = useDebounce<GetWorkOrderCountInput>({
    value: {
      projectId: activeProj?.id?.toString() ?? "",
      filterEndDateISO: dateRange?.to?.toISOString(),
      filterStartDateISO: dateRange?.from?.toISOString(),
      locFilterId: locationFilter?.id.toString() ?? "",
      search,
      filterAssetTypeIds: assetTypesFilter.map(
        (at) => at?.id?.toString() ?? "0"
      ),
      filterRaisedByIds: usersFilter.map((u) => u?.id?.toString() ?? "0"),
      filterWorkOrderTypeIds: workOrderTypeFilter.map(
        (wo) => wo?.id?.toString() ?? "0"
      ),
      filterWorkTradeIds: workTrades.map((wt) => wt?.id?.toString() ?? "0"),
      filterPriorityIds: priorities.map((p) => p?.id?.toString() ?? "0"),
      workStatusId: workOrderStatus.id.toString(),
    },
    delay: 500,
  });

  const { data: workOrderCount } = trpc.workOrders.getWorkOrderCount.useQuery(
    queryArg,
    {
      enabled: !!workOrderStatus && !!activeProj,
    }
  );

  return <p>{`${workOrderStatus.name} (${workOrderCount ?? "-"})`}</p>;
}
